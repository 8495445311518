import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import React from "react";
import { getAuthToken } from "../../../../utils/helper";
import config from "../../../../config";

export class UploadFileInput extends React.Component<any, any> {
  state = {
    cardKey: -1,
    fileList: [
      // {
      //   uid: '-1',
      //   name: 'xxx.png',
      //   url: 'http://www.baidu.com/xxx.png',
      // },
    ],
  };

  normFile = (e: any) => {
    console.log("Upload event:", e);
    // if (Array.isArray(e)) {
    //   return e;
    // }
    // return e && e.fileList;

    return this.state.fileList;
  };

  handleChange = (info: any) => {
    let fileList = [...info.fileList];
    let cardKey = this.props.cardKey;

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    // fileList = fileList.slice(-2);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    this.setState((prevState: any) => {
      // Object.assign would also work
      return { ...prevState, ...{ cardKey, fileList } };
    });

    this.props.onSendState({ cardKey, fileList });
  };

  render() {
    const uplprops = {
      action: `${config.baseURI}${config.endpoints.file.uploadFile}?type=${this.props.type}`,
      headers: { Authorization: `Bearer ${getAuthToken()}` },
      onChange: this.handleChange,
      multiple: true,
    };
    return (

      <Upload
        {...uplprops}
        listType="picture"
        defaultFileList={[...this.state.fileList]}
      >
        <Button icon={<UploadOutlined />} style={{ width: 350 }}>
          {this.props.uploadButtonText}
        </Button>
      </Upload>
    );
  }
}
