import { message, notification, Spin } from "antd";
import React from "react";

/**
 * display success message
 *
 * @param message
 * @param description
 */
export function displaySuccessNotification(
  message: string,
  description?: string
) {
  notification.close("loading");
  return notification["success"]({
    message,
    description,
    placement: "topLeft",
    style: {
      marginTop: 50,
    },
  });
}

/**
 * displays error toast
 */
export const displayErrorMessage = (error: string) => {
  notification.close("loading");

  return message.error(error);
  // return notification["error"]({
  //   message: error,
  //   placement: "top",
  //   style: {
  //     marginTop: 50,
  //   },
  // });
};

export const displayLoadingMessage = (description: React.ReactNode) => {
  return notification.open({
    message: "",
    description,
    placement: "top",
    style: {
      marginTop: 50,
      textAlign: "center",
    },
    key: "loading",
    duration: 0,
  });
};
