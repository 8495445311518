import { Carousel, Form, Space } from "antd";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import config from "../../../config";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import * as routes from "../../../constants/routes";
import useEffectOnce from "../../../hooks/useEffectOnce";
import parse from "html-react-parser";
import { UserType } from "../../../enums";
import {
  displayErrorMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import * as ListService from "../../../store/lists/list.action";
import { clearListStatus } from "../../../store/lists/lists.slice";

export const ProductViewPage = () => {
  const [form] = Form.useForm();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const { product } = location.state as any;

  const { user } = useAppSelector((state) => state.auth);
  const { status: listStatus, error: listError } = useAppSelector(
    (state) => state.list
  );

  useEffectOnce(() => {
    form.setFieldsValue({ ProductId: product.id });
  });

  const onFormSubmit = (formData: any) => {
    dispatch(ListService.rejectProduct(formData));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useEffect(() => {
    if (listStatus === "rejectProductResolved") {
      displaySuccessNotification("Product has been rejected");

      dispatch(clearListStatus());
    }
    if (listStatus === "rejectProductRejected") {
      displayErrorMessage(listError);

      dispatch(clearListStatus());
    }
  }, [dispatch, listStatus]);

  useScrollToTop();

  return (
    <>
      <section className="productSec">
        <div className="container-fluid">
          <div className="row row-no-gutters">
            <div className="col-lg-6">
              <div className="row">
                <div className="prodDet">
                  <h4>
                    {product.title} <br />
                    <small>{product.subTitle}</small>
                  </h4>
                  <br />
                  {/* <a className="headerBoldlink" href="View-suppliers.html">
                  Orion Woodshop - Luxury Wood furniture and Designs
                </a> 
                <br />
                <br />*/}
                  {product.description && <p>{parse(product.description)}</p>}
                  <br />

                  {product.features && (
                    <>
                      <h4>Product Features & Benefits:</h4>
                      <br />
                      {<p>{parse(product.features)}</p>}
                      <br />
                    </>
                  )}

                  {product.specification && (
                    <>
                      <h4>Product / Service Specifications</h4>
                      <br />
                      <p>{parse(product.specification)}</p>
                    </>
                  )}

                  {user.userType === UserType.CUSTOMER && (
                    <Link
                      className="button-block"
                      to={`/inquiry/product`}
                      state={{ product: product }}
                    >
                      Send Inquiry
                    </Link>
                  )}
                </div>
              </div>
              <div className="row">
                {[UserType.SUPER_ADMIN, UserType.ADMIN].includes(
                  Number(user.userType)
                ) &&
                  product.status === "PendingApproval" && (
                    <Form
                      form={form}
                      size="small"
                      onFinish={onFormSubmit}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      initialValues={{ ProductId: "", Reason: "" }}
                    >
                      <Space
                        direction="vertical"
                        align="center"
                        style={{ width: "100%", gap: "0" }}
                      >
                        <Form.Item name="ProductId" hidden={true}>
                          <input />
                        </Form.Item>
                        <Form.Item
                          name="Reason"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter reason for the product rejection.",
                            },
                            ({ getFieldValue }) => ({
                              validator(_rule, value) {
                                if (!value || !value.includes("<script>")) {
                                  return Promise.resolve();
                                }
                                return Promise.reject("Invalid input found!");
                              },
                            }),
                          ]}
                        >
                          <textarea
                            maxLength={100}
                            className="local-form input-reg"
                            placeholder="Special reason for product rejection"
                            style={{ width: "480px" }}
                          ></textarea>
                        </Form.Item>
                        <button
                          className="butn butn-block"
                          style={{ backgroundColor: "red" }}
                          type="submit"
                          // onClick={() =>
                          //   navigate(routes.START_DEAL, { state: { product: product } })
                          // }
                        >
                          Reject Product
                        </button>
                      </Space>
                    </Form>
                  )}
              </div>
            </div>
            <div className="col-lg-6">
              <Carousel autoplay>
                {product.images &&
                  product.images.map((image: any, index: number) => (
                    <div className="albery-item" key={index.toString()}>
                      <img
                        src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${image.fileName}&type=media`}
                        alt=""
                      />
                    </div>
                  ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
