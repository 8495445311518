import { createSlice } from "@reduxjs/toolkit";
import { store } from "..";
import { CommonState } from "../../types";
import * as SupplierService from "./supplier.action";
import * as Util from "../../utils/helper";

export const initialState: CommonState = Object.freeze({
  status: "idle",
  entities: { suppliers: [] },
  error: "",
  usersPaging: { pageNumber: 1, pageSize: 10 },
  productPaging: { pageNumber: 1, pageSize: 10 },
  selectedSupplier: { products: [] },
});

const supplierSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearSupplierError: (state) => {
      state.error = "";
    },
    clearSupplierStatus: (state) => {
      state.status = "idle";
    },
    resetSupplierState: (state) => {
      state = {
        status: "idle",
        entities: { suppliers: [] },
        error: "",
        usersPaging: { pageNumber: 1, pageSize: 10 },
        productPaging: { pageNumber: 1, pageSize: 10 },
        selectedSupplier: { products: [] },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(SupplierService.fetchSupplier.pending, (state) => {
      state.status = "fetchSupplierPending";
    });
    builder.addCase(
      SupplierService.fetchSupplier.fulfilled,
      (state, { payload }) => {
        state.entities.suppliers = [];

        for (let index = 0; index < payload.length; index++) {
          state.entities.suppliers.push(payload[index]);
          state.entities.suppliers[index].products = [];
        }

        // state.entities = payload;
        state.status = "fetchSupplierSuccess";
        state.error = "";
      }
    );
    builder.addCase(
      SupplierService.fetchSupplier.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "fetchSupplierFailed";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SupplierService.fetchSupplierProducts.pending, (state) => {
      state.status = "fetchSupplierProductsPending";
    });
    builder.addCase(
      SupplierService.fetchSupplierProducts.fulfilled,
      (state, { payload }) => {
        let selectedSupplier = state.entities.suppliers?.filter(
          (supplier) =>
            supplier.company?.id?.toString() === payload.id.toString()
        );
        if (selectedSupplier && selectedSupplier[0]) {
          state.selectedSupplier = selectedSupplier[0];

          selectedSupplier[0].products = [];

          for (let index = 0; index < payload.result.length; index++) {
            selectedSupplier[0].products.push(payload.result[index]);
          }
        } else {
          state.selectedSupplier = { products: [] };

          for (let index = 0; index < payload.result.length; index++) {
            state.selectedSupplier?.products.push(payload.result[index]);
          }
        }
        // state.entities = payload;
        state.status = "fetchSupplierProductsSuccess";
        state.error = "";
      }
    );
    builder.addCase(
      SupplierService.fetchSupplierProducts.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "fetchSupplierProductsFailed";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SupplierService.createProduct.pending, (state) => {
      state.status = "createProductPending";
    });
    builder.addCase(
      SupplierService.createProduct.fulfilled,
      (state, { payload }) => {
        state.status = "createProductSuccess";
        state.error = "";
      }
    );
    builder.addCase(
      SupplierService.createProduct.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createProductRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SupplierService.updateProduct.pending, (state) => {
      state.status = "updateProductPending";
    });
    builder.addCase(
      SupplierService.updateProduct.fulfilled,
      (state, { payload }) => {
        state.status = "updateProductSuccess";
        state.error = "";
      }
    );
    builder.addCase(
      SupplierService.updateProduct.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "updateProductRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SupplierService.fetchFavoriteSuppliers.pending, (state) => {
      state.status = "fetchSupplierPending";
    });
    builder.addCase(
      SupplierService.fetchFavoriteSuppliers.fulfilled,
      (state, { payload }) => {
        state.entities.suppliers = [];

        for (let index = 0; index < payload.length; index++) {
          state.entities.suppliers.push(payload[index]);
          state.entities.suppliers[index].products = [];
        }

        // state.entities = payload;
        state.status = "fetchSupplierSuccess";
        state.error = "";
      }
    );
    builder.addCase(
      SupplierService.fetchFavoriteSuppliers.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "fetchSupplierFailed";
        state.error = Util.parseErrorMessage(payload);
      }
    );
  },
});

export const { clearSupplierError, resetSupplierState, clearSupplierStatus } =
  supplierSlice.actions;

export default supplierSlice.reducer;
