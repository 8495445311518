import { Form, notification, Spin } from "antd";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import * as QuotationService from "../../../store/quotation/quotation.actions";
import {
  clearQuotationStatus,
  clearQuoteError,
} from "../../../store/quotation/quotation.slice";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import { PreLoader, UploadDragDrop } from "../../Components";

const { Item } = Form;

export const InquiryPurchaseOrderPage = () => {
  const location = useLocation();

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  // const {
  //   status: quotationStatus,
  //   error: quotationError,
  //   quotations,
  // } = useAppSelector((state) => state.quotation);

  const { status: quotationStatus, error: quotationError } = useAppSelector(
    (state) => state.quotation
  );

  const { quotation } = (location.state as any) || {};

  const onFormSubmit = (values: any) => {
    dispatch(QuotationService.createOrder(values));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useEffect(() => {
    if (quotationError) {
      displayErrorMessage(quotationError);
      dispatch(clearQuoteError());
    }
  }, [quotationError]);

  useEffect(() => {
    if (quotationStatus === "createOrderResolved") {
      displaySuccessNotification("Order has been submitted");
    }

    if (quotationStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [dispatch, quotationStatus]);

  useEffect(() => {
    form.setFieldsValue({
      InquiryId: quotation.inquiry.id!,
      InquiryTitle: quotation.inquiry.title!,
      QuotationId: quotation.id!,
      QuotationTitle: quotation.title!,
    });
  }, [form, quotation]);

  useScrollToTop();

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormSubmit}
        onFinishFailed={onFinishFailed}
        size="large"
      >
        <div className="row">
          <div className="col-lg-9">
            <h3>Generate Purchase Order</h3>
            <br />
            <p>
              Please Fill all the field to complete information about the
              Purchase Order for the Inquiry/Quotation you have selected.
            </p>
          </div>
        </div>

        <div className="row formRow">
          <div className="col-lg-12">
            <Item name="InquiryId" label="Inquiry Id" hidden={true}>
              <input className="local-form input-reg" />
            </Item>

            <Item name="InquiryTitle" label="Inquiry Title">
              <input
                readOnly
                className="local-form input-reg"
                placeholder="Inquiry Title"
              />
            </Item>
          </div>
        </div>

        <div className="row formRow">
          <div className="col-lg-12">
            <Item name="QuotationId" label="Quotation Id" hidden={true}>
              <input className="local-form input-reg" />
            </Item>

            <Item name="QuotationTitle" label="Quotation Title">
              <input
                readOnly
                className="local-form input-reg"
                placeholder="Quotation Title"
              />
            </Item>
            {/* <Item
              name="QuotationId"
              label="Select Quotation"
              rules={[
                {
                  required: true,
                  message: "Please enter a Quotation for the purchase order!",
                },
              ]}
            >
              <select className="local-form input-reg">
                <option value="0">Select Quotation</option>
                {quotations
                  .filter((q) => q.status === QuotationStatus.APPROVED)
                  .map((q, index) => (
                    <option value={q.id} key={q.id}>
                      {q.title}
                    </option>
                  ))}
              </select>
            </Item> */}
          </div>
        </div>

        <div className="row formRow">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <UploadDragDrop ItemName="ImageFiles" type="inquiry" />
          </div>
          <div className="col-lg-3"></div>
        </div>

        <div className="row formRow">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <button
              type="submit"
              className="butn butn-block"
              disabled={quotationStatus === "createOrderPending"}
            >
              <span id="button-text">
                {quotationStatus === "createOrderPending" ? (
                  <Spin size="small" />
                ) : (
                  "Submit Order"
                )}
              </span>
            </button>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </Form>
    </>
  );
};
