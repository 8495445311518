import { Input } from "antd";
import { useAppDispatch } from "../../../hooks";
import { CategoryWidget } from "../categoryList";

const { Search } = Input;

interface FilterBarProps {
  productCategoryChangeCallBack: (categoryId: Array<any>) => void;
  serviceCategoryChangeCallBack: (categoryId: Array<any>) => void;
  triggerSearchCallBack: (searchText: string) => void;
}

export const FilterBar = (props: FilterBarProps) => {
  // const [services, setServices] = useState<Array<any>>([]);

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(fetchServices());
  // }, [dispatch]);

  const onProductCategoryChange = (categoryId: Array<any>) => {
    props.productCategoryChangeCallBack(categoryId);
  };

  const onServiceCategoryChange = (categoryId: Array<any>) => {
    props.serviceCategoryChangeCallBack(categoryId);
  };

  const initSearch = (value: string) => {
    props.triggerSearchCallBack(value);
  };

  return (
    <>
      <section className="datafilters">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="filtercontroler">
                <div className="row">
                  <div className="col-lg-3 col-md-3">
                    <span className="bigLabel">Suppliers</span>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <CategoryWidget
                      name="CategoryId"
                      categoryIndex={1}
                      placeHolder="Please select services to filter"
                      onCategoryChange={onServiceCategoryChange}
                      allowMultiple={true}
                      allowChangeOnSelect={true}
                    ></CategoryWidget>
                    {/* <select name="" className="local-form input-reg" onChange={onCategoryChange}>
                      <option value="0">Filter by Category</option>
                      {categories.map((category, index) => (
                        <option key={category.category} value={category.category}>
                          {category.name}
                        </option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <CategoryWidget
                      name="CategoryId"
                      categoryIndex={0}
                      placeHolder="Please select products to filter!"
                      onCategoryChange={onProductCategoryChange}
                      allowMultiple={true}
                      allowChangeOnSelect={true}
                    ></CategoryWidget>
                  </div>
                  <div className="col-lg-3 col-md-3">
                    <div className="searchGroupMini">
                      <Search
                        maxLength={30}
                        onSearch={initSearch}
                        placeholder="input search text"
                        size="large"
                        enterButton
                      />
                      {/* <input
                        type="text"
                        className="sinpmini"
                        placeholder="grabadge text"
                      />
                      <button type="button" className="exesermini">
                        <span className="glyphicon glyphicon-search"></span>
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
