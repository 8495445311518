export enum InquiryStatus {
  ALL = 0,
  POSTED = 1,
  RECEIVED = 2,
  SHORTLISTED = 3,
  APPROVED = 4,
  ORDERED = 5,
  FULFILLED = 6,
  INVOICED = 7,
  COMPLETED = 8,
  EXPIRED = 9,
  CANCELLED = 10
}
