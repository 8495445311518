import React, { Fragment, useEffect, useState } from "react";
import * as routes from "../../../../constants/routes";
import config from "../../../../config";
import { useAppSelector } from "../../../../hooks";

export const PageBanner = () => {
  const { user } = useAppSelector((state) => state.auth);

  const [bannerDefaultImg, setBannerDefaultImg] = useState(
    "https://images.pexels.com/photos/260922/pexels-photo-260922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
  );

  useEffect(() => {
    if (user?.company?.banner) {
      setBannerDefaultImg(
        config.baseURI +
          routes.DOWNLOAD_IMAGE +
          user.company.banner +
          "&type=user"
      );
    } else {
      setBannerDefaultImg(
        "https://images.pexels.com/photos/260922/pexels-photo-260922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
      );
    }
  }, [user]);

  return (
    <Fragment>
      <section className="idHeader">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="bannerHolder">
                <div className="gradientOverlayar">&nbsp;</div>
                <img
                  className="idBannerSetup"
                  // src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${user?.company?.banner}&type=user`}
                  style={{
                    background: `url('${bannerDefaultImg}')`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  alt=""
                />
                {/* <div className="idHeaderContent">
                  <span className="bannerTitle-mini">Suppliers</span>
                  <span className="bannerinfo-mini">
                    Kompra Designs - Classic Restuarant Furnitures and Fixture
                  </span>
                  <Link to="#" className="bannerBtn-reg">
                    Veiw Supplier&emsp;
                    <span className="mdi mdi-arrow-right"></span>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export const SupplierPageBanner = () => {

  const { selectedSupplier } = useAppSelector((state) => state.supplier);

  const [bannerDefaultImg, setBannerDefaultImg] = useState(
    "https://images.pexels.com/photos/260922/pexels-photo-260922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
  );

  useEffect(() => {
    if (selectedSupplier?.company?.banner) {
      setBannerDefaultImg(
        config.baseURI +
          routes.DOWNLOAD_IMAGE +
          selectedSupplier.company.banner +
          "&type=user"
      );
    } else {
      setBannerDefaultImg(
        "https://images.pexels.com/photos/260922/pexels-photo-260922.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
      );
    }
  }, [selectedSupplier]);

  return (
    <Fragment>
      <section className="idHeader">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="bannerHolder">
                <div className="gradientOverlayar">&nbsp;</div>
                <img
                  className="idBannerSetup"
                  // src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${user?.company?.banner}&type=user`}
                  style={{
                    background: `url('${bannerDefaultImg}')`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  alt=""
                />
                {/* <div className="idHeaderContent">
                  <span className="bannerTitle-mini">Suppliers</span>
                  <span className="bannerinfo-mini">
                    Kompra Designs - Classic Restuarant Furnitures and Fixture
                  </span>
                  <Link to="#" className="bannerBtn-reg">
                    Veiw Supplier&emsp;
                    <span className="mdi mdi-arrow-right"></span>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
