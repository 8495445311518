export const INDEX = "/";
export const HOME = "/home";
export const VIEW = "/view";
export const CREATE_ACCOUNT = "/user/signup";
export const CUSTOMER_PROFILE = "/setup/profile";
export const COMPANY_PROFILE = "/setup/profile";
export const USER_PROFILE = "/setup/profile";
export const USER_SIGNUP = "/user/signup";
export const USER_SUBSCRIPTION = "/subscription";
export const LOGIN = "/user/login";
export const LOGOUT = "/logout";
export const FORGET_PWD = '/account/forgetpwd'
export const RESET_PWD = '/account/resetpwd'
export const ADMIN_HOME = "/admin-home";
export const CLIENT_HOME = "/client-home";
export const SUPPLIER_STORE = "/view/company/store";
export const SUPPLIER_LIST_ALL = "/view/supplier/list";
export const SUPPLIER_LIST_ID = "/view/company/list";
export const CUSTOMER_LIST = "/view/customer/list";
export const COMPANY_LISTINGS = "/view/company";
export const SUPP_DASHBOARD = "/navigate/supplier/home";
export const COMP_DASHBOARD = "/navigate/customer/home";
export const ADMIN_DASHBOARD = "/admin/home";
export const DOWNLOAD_IMAGE = "/file/download?fileName=";
export const TO_CHAT_APP = "/navigate/chat/app";
export const TO_PAYMENT_PAGE = "/setup/payment";
