import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import {
  AreaCoverage,
  Category,
  CategoryTypeResponse,
  City,
  Country,
  Service,
  SubscriptionData,
  SubscriptionResponse,
  UserSubscriptionResponse,
} from "../../types";
import http from "../../utils/http";

// export const createCategory = createAsyncThunk(
//   "category/createCategory",
//   async (values: any, thunkAPI) => {
//     try {
//       const url = config.endpoints.invoice.createFeedback;

//       let feedbackData: CreateFeedbackData = {
//         InquiryId: BigInt(0),
//         QuotationId: BigInt(0),
//         Rating: Number(0),
//         Comments: String(),
//       };

//       feedbackData = JSON.parse(JSON.stringify(values));

//       const {
//         data: { feedback },
//       } = await http.post<{ feedback: CreateFeedbackData }>(url, feedbackData);
//       return feedback;
//     } catch (err) {
//       return thunkAPI.rejectWithValue(err);
//     }
//   }
// );

export const createCoverageArea = createAsyncThunk(
  "area/createCoverageArea",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.setups.createCoverageArea;

      let coverageAreaData: AreaCoverage = {
        id: 0,
        area: BigInt(0),
        city: String(""),
        latitude: 0,
        longitude: 0,
      };

      coverageAreaData = JSON.parse(JSON.stringify(values));

      const {
        data: { coverageArea },
      } = await http.post<{ coverageArea: AreaCoverage }>(
        url,
        coverageAreaData
      );
      return coverageArea;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateCoverageArea = createAsyncThunk(
  "area/updateCoverageArea",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.updateCoverageArea}/${values.Id}`;

      let coverageAreaData: AreaCoverage = {
        id: 0,
        area: BigInt(0),
        city: String(""),
        latitude: 0,
        longitude: 0,
      };

      coverageAreaData = JSON.parse(JSON.stringify(values));

      const {
        data: { coverageArea },
      } = await http.put<{ coverageArea: AreaCoverage }>(url, coverageAreaData);
      return coverageArea;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createCountry = createAsyncThunk(
  "country/createCountry",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.setups.createCountry;

      let countryData: Country = {
        id: 0,
        title: String(""),
      };

      countryData = JSON.parse(JSON.stringify(values));

      const {
        data: { country },
      } = await http.post<{ country: Country }>(url, countryData);
      return country;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateCountry = createAsyncThunk(
  "country/updateCountry",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.updateCountry}/${values.Id}`;

      let countryData: Country = {
        id: 0,
        title: String(""),
      };

      countryData = JSON.parse(JSON.stringify(values));

      const {
        data: { country },
      } = await http.put<{ country: Country }>(url, countryData);
      return country;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createCity = createAsyncThunk(
  "city/createCity",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.setups.createCity;

      let cityData: City = {
        id: 0,
        countryId: 0,
        countryTitle: String(""),
        title: String(""),
      };

      cityData = JSON.parse(JSON.stringify(values));

      const {
        data: { city },
      } = await http.post<{ city: City }>(url, cityData);
      return city;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateCity = createAsyncThunk(
  "city/updateCity",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.updateCity}/${values.Id}`;

      let cityData: City = {
        id: 0,
        countryId: 0,
        countryTitle: String(""),
        title: String(""),
      };

      cityData = JSON.parse(JSON.stringify(values));

      const {
        data: { city },
      } = await http.put<{ city: City }>(url, cityData);
      return city;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createCategory = createAsyncThunk(
  "category/createCategory",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.setups.createCategory;

      let categoryData = {
        Id: 0,
        CategoryName: String(""),
        CategoryType: Number(0),
        ParentId: String(""),
      };

      categoryData = JSON.parse(JSON.stringify(values));

      if (Number(categoryData.CategoryType) === 0) {
        categoryData.CategoryType = 1;
      } else if (Number(categoryData.CategoryType) === 1) {
        categoryData.CategoryType = 2;
      }

      if (!categoryData.ParentId) {
        categoryData.ParentId = "0";
      }

      categoryData.ParentId =
        categoryData.ParentId[categoryData.ParentId.length - 1];

      const {
        data: { category },
      } = await http.post<{ category: {} }>(url, categoryData);
      return category;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.updateCategory}/${values.Id}`;

      let categoryData = {
        Id: 0,
        CategoryName: String(""),
        CategoryType: Number(0),
        ParentId: String(""),
      };

      categoryData = JSON.parse(JSON.stringify(values));

      if (Number(categoryData.CategoryType) === 0) {
        categoryData.CategoryType = 1;
      } else if (Number(categoryData.CategoryType) === 1) {
        categoryData.CategoryType = 2;
      }

      if (!categoryData.ParentId) {
        categoryData.ParentId = "0";
      }

      categoryData.ParentId =
        categoryData.ParentId[categoryData.ParentId.length - 1];

      const {
        data: { category },
      } = await http.put<{ category: {} }>(url, categoryData);
      return category;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async (_, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.fetchCategories}`;
      const {
        data: { result },
      } = await http.get<{ result: CategoryTypeResponse[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateUserStatus = createAsyncThunk(
  "user/updateUserStatus",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.updateUserStatus}/${values.id}/${values.status}`;

      const {
        data: { result },
      } = await http.put<{ result: boolean }>(url);
      return { result: result, userid: values.id, status: values.status };
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createSubscription = createAsyncThunk(
  "subscription/createSubscription",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.setups.createSubscription;

      let subscriptionData: SubscriptionData = {
        Id: 0,
        Title: String(""),
        Days: 0,
        Charges: 0,
      };

      subscriptionData = JSON.parse(JSON.stringify(values));

      const {
        data: { subscription },
      } = await http.post<{ subscription: SubscriptionData }>(
        url,
        subscriptionData
      );
      return subscription;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "subscription/updateSubscription",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.updateSubscription}/${values.Id}`;

      let subscriptionData: SubscriptionData = {
        Id: 0,
        Title: String(""),
        Days: 0,
        Charges: 0,
      };

      subscriptionData = JSON.parse(JSON.stringify(values));

      const {
        data: { subscription },
      } = await http.put<{ subscription: SubscriptionData }>(
        url,
        subscriptionData
      );
      return subscription;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSubscriptions = createAsyncThunk(
  "subscription/fetchSubscriptions",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.setups.fetchSubscriptions;

      const {
        data: { result },
      } = await http.get<{ result: SubscriptionResponse[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchActiveSubscriptions = createAsyncThunk(
  "subscription/fetchActiveSubscriptions",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.setups.fetchActiveSubscriptions;

      const {
        data: { result },
      } = await http.get<{ result: SubscriptionResponse[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSubscriptionsForActivation = createAsyncThunk(
  "subscription/fetchSubscriptionsForActivation",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.setups.fetchSubscriptionsForActivation;

      const {
        data: { result },
      } = await http.get<{ result: UserSubscriptionResponse[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const activateUserSubscription = createAsyncThunk(
  "setup/activateUserSubscription",
  async (id: bigint, thunkAPI) => {
    try {
      const url = `${config.endpoints.setups.activateUserSubscription}/${id}`;

      const {
        data: { result },
      } = await http.put<{ result: boolean }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);