import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import {
  CreateFeedbackData,
  CreateInvoiceData,
  CreateOrderData,
  CreateQuotationData,
  PurchaseOrderResponse,
  QuotationResponse,
} from "../../types";
import http from "../../utils/http";

export const fetchQuotationByInquiry = createAsyncThunk(
  "quotation/fetchQuotationByInquiry",
  async (id: number, thunkAPI) => {
    try {
      const url = `${config.endpoints.quotation.fetchQuotationByInquiry}/${id}`;
      const {
        data: { result },
      } = await http.get<{ result: QuotationResponse[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchPurchaseOrderByParams = createAsyncThunk(
  "quotation/fetchQuotationByInquiry",
  async (quotation: QuotationResponse, thunkAPI) => {
    try {
      const url = `${config.endpoints.order.fetchPurchaseOrderByParams}?inquiryId=${quotation.inquiry.id}&quotationId=${quotation.id}`;
      const {
        data: { result },
      } = await http.get<{ result: PurchaseOrderResponse }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

// export const fetchUserQuotations = createAsyncThunk(
//   "quotation/fetchUserQuotations",
//   async (_, thunkAPI) => {
//     try {
//       const url = `${config.endpoints.quotation.fetchUserQuotation}`;
//       const {
//         data: { result },
//       } = await http.get<{ result: QuotationResponse[] }>(url);
//       return result;
//     } catch (err) {
//       var error = JSON.parse(JSON.stringify(err));

//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

export const fetchSupplierQuotations = createAsyncThunk(
  "quotation/fetchSupplierQuotations",
  async (status: number, thunkAPI) => {
    try {
      let url = `${config.endpoints.quotation.fetchSupplierQuotation}`;
      if (Number(status) > 0) {
        url += `?status=${status}`;
      }
      const {
        data: { result },
      } = await http.get<{ result: QuotationResponse[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const approveQuotation = createAsyncThunk(
  "quotation/approveQuotation",
  async (id: number, thunkAPI) => {
    try {
      const url = `${config.endpoints.quotation.approveQuotation}/${id}`;

      const {
        data: { result },
      } = await http.put<{ result: boolean }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const shortListQuotation = createAsyncThunk(
  "quotation/shortListQuotation",
  async (id: number, thunkAPI) => {
    try {
      const url = `${config.endpoints.quotation.shortListQuotation}/${id}`;

      const {
        data: { result },
      } = await http.put<{ result: boolean }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const purchaseOrderFulfill = createAsyncThunk(
  "quotation/purchaseOrderFulfill",
  async (id: number, thunkAPI) => {    
    try {
      const url = `${config.endpoints.order.fulfillOrder}/${id}`;

      const {
        data: { result },
      } = await http.put<{ result: boolean }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createQuotation = createAsyncThunk(
  "quotation/createQuotation",
  async (values: CreateQuotationData, thunkAPI) => {
    try {
      const url = config.endpoints.quotation.createQuotation;

      let quotationData: CreateQuotationData = {};

      quotationData = JSON.parse(JSON.stringify(values));

      if (values?.ImageFiles) {
        let localQuotationImages = values?.ImageFiles["fileList"];

        quotationData.quotationImages = [];
        for (let index = 0; index < localQuotationImages.length; index++) {
          quotationData.quotationImages?.push(
            localQuotationImages[index].response.result
          );
        }
      }

      const {
        data: { quotation },
      } = await http.post<{ quotation: CreateQuotationData }>(url, quotationData);
      return quotation;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createOrder = createAsyncThunk(
  "quotation/createOrder",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.order.createOrder;

      let orderData: CreateOrderData = {
        InquiryId: BigInt(0),
        QuotationId: BigInt(0)
      };

      orderData = JSON.parse(JSON.stringify(values));

      if (values?.ImageFiles) {
        let localOrderImageFiles = values?.ImageFiles["fileList"];

        orderData.FileName = undefined;
        for (let index = 0; index < localOrderImageFiles.length; index++) {
          orderData.FileName = localOrderImageFiles[index].response.result;
        }
      }

      const {
        data: { order },
      } = await http.post<{ order: CreateOrderData }>(url, orderData);
      return order;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createInvoice = createAsyncThunk(
  "quotation/createInvoice",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.invoice.createInvoice;

      let invoiceData: CreateInvoiceData = {
        InquiryId: BigInt(0),
        QuotationId: BigInt(0),
        PurchaseOrderId: BigInt(0)
      };

      invoiceData = JSON.parse(JSON.stringify(values));

      if (values?.ImageFiles) {
        let localOrderImageFiles = values?.ImageFiles["fileList"];

        invoiceData.FileName = undefined;
        for (let index = 0; index < localOrderImageFiles.length; index++) {
          invoiceData.FileName = localOrderImageFiles[index].response.result;
        }
      }

      const {
        data: { invoice },
      } = await http.post<{ invoice: CreateInvoiceData }>(url, invoiceData);
      return invoice;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createFeedback = createAsyncThunk(
  "quotation/createFeedback",
  async (values: any, thunkAPI) => {
    try {
      const url = config.endpoints.invoice.createFeedback;

      let feedbackData: CreateFeedbackData = {
        InquiryId: BigInt(0),
        QuotationId: BigInt(0),
        Rating: Number(0),
        Comments: String()
      };

      feedbackData = JSON.parse(JSON.stringify(values));

      const {
        data: { feedback },
      } = await http.post<{ feedback: CreateFeedbackData }>(url, feedbackData);
      return feedback;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);


export const updateQuotation = createAsyncThunk(
  "quotation/updateQuotation",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.quotation.updateQuotation}/${values.Id}`;

      let quotationData: CreateQuotationData = {};

      quotationData = JSON.parse(JSON.stringify(values));

      if (values?.ImageFiles) {
        let localQuotationImages = values?.ImageFiles["fileList"];

        quotationData.quotationImages = [];
        for (let index = 0; index < localQuotationImages.length; index++) {
          quotationData.quotationImages?.push(
            localQuotationImages[index].response.result
          );
        }
      }

      const {
        data: { quotation },
      } = await http.put<{ quotation: CreateQuotationData }>(url, quotationData);
      return quotation;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);