import config from "../../../config";
import * as routes from "../../../constants/routes";
import parse from "html-react-parser";
import {
  List,
  Modal,
  notification,
  Pagination,
  PaginationProps,
  Space,
  Tag,
} from "antd";

import { EditOutlined, RightOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import { InquiryResponse, QuotationResponse } from "../../../types";
import { store } from "../../../store";
import { UserType } from "../../../enums";
import { Link, useNavigate } from "react-router-dom";
import * as InquiryService from "../../../store/inquiry/inquiry.actions";
import * as QuotationService from "../../../store/quotation/quotation.actions";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import { QuotationList } from "../quotationList";
import { InquiryStatus } from "../../../enums";
import { PreLoader } from "../loader";
import { clearAuthError } from "../../../store/auth/auth.slice";
import { clearInquiryError } from "../../../store/inquiry/inquiry.slice";
import { clearQuoteError } from "../../../store/quotation/quotation.slice";

interface InquiryListProps {
  status: string;
}

export const InquiryList = (props: InquiryListProps) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    status: inquiryStatus,
    error: inquiryError,
    inquiries,
    paging,
  } = useAppSelector((state) => state.inquiry);

  const {
    status: quotationStatus,
    error: quotationError,
    quotations,
  } = useAppSelector((state) => state.quotation);

  const { user } = useAppSelector((state) => state.auth);

  const [filterInquiries, setFilterInquiries] = useState<
    Array<InquiryResponse>
  >([]);

  const [clickedInquiryId, setClickedInquiryId] = useState(-1);

  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange: PaginationProps["onChange"] = (page, pageSize) => {
    // dispatch(
    //   ListService.fetchAllProducts({
    //     ...searchFilter,
    //     pageNo: page,
    //     pageSize: pageSize,
    //   })
    // );

    if (user.userType === UserType.SUPPLIER) {
      dispatch(
        InquiryService.fetchSupplierInquiries({
          pageNo: paging.pageNumber,
          pageSize: paging.pageSize,
          status: props.status,
        })
      ); /*.then(
        (resp) => {
          dispatch(QuotationService.fetchQuotationByInquiry(-1));
        }
      );*/
    } else {
      dispatch(
        InquiryService.fetchUserInquiries({
          pageNo: paging.pageNumber,
          pageSize: paging.pageSize,
          status: props.status,
        })
      ); /*.then((resp) => {
        dispatch(QuotationService.fetchQuotationByInquiry(-1));
      });*/
    }

    setCurrentPage(page);
  };

  useEffect(() => {
    if (user.userType === UserType.SUPPLIER) {
      dispatch(
        InquiryService.fetchSupplierInquiries({
          pageNo: paging.pageNumber,
          pageSize: paging.pageSize,
          status: props.status,
        })
      ); /*.then(
        (resp) => {
          dispatch(QuotationService.fetchQuotationByInquiry(-1));
        }
      );*/
    } else {
      dispatch(
        InquiryService.fetchUserInquiries(props.status)
      ); /*.then((resp) => {
        dispatch(QuotationService.fetchQuotationByInquiry(-1));
      });*/
    }
  }, [dispatch, props.status]);

  useEffect(() => {
    if (
      inquiryStatus === "fetchUserInquiriesRejected" ||
      inquiryStatus === "fetchSupplierInquiriesRejected"
    ) {
      displayErrorMessage(inquiryError);
      dispatch(clearInquiryError());
    }

    if (
      inquiryStatus === "fetchUserInquiriesResolved" ||
      inquiryStatus === "fetchSupplierInquiriesResolved"
    ) {
      setFilterInquiries(inquiries);

      // displaySuccessNotification(`${inquiries.length} records found`);

      setClickedInquiryId(-1);
    }

    if (inquiryStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [dispatch, inquiryStatus]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalInquiry, setModalInquiry] = useState<InquiryResponse>();

  const showModal = (item: InquiryResponse) => {
    setIsModalVisible(true);

    setModalInquiry(item);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getQuotation = (inquiry: InquiryResponse) => {
    setClickedInquiryId(inquiry.id!);
    dispatch(QuotationService.fetchQuotationByInquiry(inquiry.id!));
  };

  const approveQuotationDispatchCallback = (quotation: QuotationResponse) => {
    dispatch(QuotationService.approveQuotation(quotation.id));
  };

  const shortListQuotationDispatchCallback = (quotation: QuotationResponse) => {
    dispatch(QuotationService.shortListQuotation(quotation.id));
  };

  const purchaseOrderFulfillDispatchCallback = (
    quotation: QuotationResponse
  ) => {
    dispatch(QuotationService.purchaseOrderFulfill(quotation.purchaseOrder?.id!));
  };

  useEffect(() => {
    if (quotationStatus === "approveQuotationResolved") {
      displaySuccessNotification("Quotation has been approved");
      dispatch(InquiryService.fetchUserInquiries(inquiryStatus)).then(
        (resp) => {
          dispatch(QuotationService.fetchQuotationByInquiry(clickedInquiryId));
        }
      );
    }
    if (quotationStatus === "shortListQuotationResolved") {
      displaySuccessNotification("Quotation has been short listed");
      dispatch(InquiryService.fetchUserInquiries(inquiryStatus)).then(
        (resp) => {
          dispatch(QuotationService.fetchQuotationByInquiry(clickedInquiryId));
        }
      );
    }
    if (quotationStatus === "purchaseOrderFulfillResolved") {
      displaySuccessNotification("Purchase order has been fulfilled");
      dispatch(InquiryService.fetchUserInquiries(inquiryStatus)).then(
        (resp) => {
          dispatch(QuotationService.fetchQuotationByInquiry(clickedInquiryId));
        }
      );
    }
    if (
      quotationStatus === "approveQuotationRejected" ||
      quotationStatus === "shortListQuotationRejected" ||
      quotationStatus === "purchaseOrderFulfillRejected" ||
      quotationStatus === "fetchQuotationRejected"
    ) {
      displayErrorMessage(quotationError);

      dispatch(clearQuoteError());
    }

    // return () => {
    //   dispatch(clearQuotationStatus());
    //   dispatch(clearQuoteError());
    // };
  }, [dispatch, quotationStatus, quotationError]);

  // useEffect(() => {

  //   setFilterInquiries(
  //     inquiries.filter(
  //       (i) => props.status === InquiryStatus.ALL || i.status === props.status
  //     )
  //   );
  // }, [dispatch, props.status]);

  const getTagByColor = (color: string, status: number) => {
    return (
      <Tag color={color} key={status}>
        {
          Object.keys(InquiryStatus)[
            Object.values(InquiryStatus).indexOf(status as InquiryStatus)
          ]
        }
      </Tag>
    );
  };

  const getControlTemplate = (inquiry: InquiryResponse) => {
    if (inquiry.status === InquiryStatus.POSTED) {
      if (store.getState().auth.user.userType === UserType.SUPPLIER) {
        return (
          <button
            className="button-block"
            // to={`/inquiry/quotation`}
            // state={{ inquiry: inquiry }}
            onClick={() =>
              navigate(`/inquiry/quotation`, { state: { inquiry: inquiry } })
            }
          >
            Send Quotation
          </button>
        );
      } else {
        return getTagByColor("yellow", inquiry.status);
      }
    } else if (
      inquiry.status === InquiryStatus.EXPIRED ||
      inquiry.status === InquiryStatus.CANCELLED
    ) {
      return getTagByColor("red", inquiry.status!);
    } else {
      return (
        <button className="button-block" onClick={() => getQuotation(inquiry)}>
          View Quotation
        </button>
      );
    }
  };

  return (
    <>
      {/* {inquiries && store.getState().auth.user.userType === UserType.SUPPLIER && ( */}
      {filterInquiries && (
        <>
          <section className="profilingSection centraSpacing">
            <div className="container-fluid">
              {/* <div className="row">
                <div className="col-lg-12">
                  <h3>Inquiries</h3>
                  <br />
                </div>
              </div> */}
              <div className="row">
                <div className="col-lg-12">
                  <List
                    itemLayout="horizontal"
                    dataSource={filterInquiries}
                    renderItem={(inquiry) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<RightOutlined />}
                          title={
                            <>
                              <div className="row">
                                <div className="col-lg-9">
                                  <a
                                    href="#"
                                    onClick={() => showModal(inquiry)}
                                  >
                                    {inquiry.title}
                                  </a>
                                </div>
                                {/* <div className="col-lg-3"></div> */}
                                <div className="col-lg-3">
                                  <b>Date of service:</b> {inquiry.lastDate}
                                </div>
                              </div>
                            </>
                          }
                          description={
                            <>
                              <div className="row">
                                <div className="col-lg-9">
                                  {parse("<br/>" + inquiry.scopeOfWork!)}
                                </div>
                                {/* <div className="col-lg-3"></div> */}
                                <div className="col-lg-3">
                                  <br />
                                  {getControlTemplate(inquiry)}
                                  {/* {store.getState().auth.user.userType ===
                                  UserType.SUPPLIER ? (
                                    inquiry.status === InquiryStatus.POSTED ? (
                                      <Link
                                        className="button-block"
                                        to={`/inquiry/quotation`}
                                        state={{ inquiry: inquiry }}
                                      >
                                        Send Quotation
                                      </Link>
                                    ) : (
                                      <Tag
                                        color={
                                          inquiry.status ===
                                          InquiryStatus.APPROVED
                                            ? "green"
                                            : inquiry.status ===
                                              InquiryStatus.SHORTLISTED
                                            ? "yellow"
                                            : "orange"
                                        }
                                        key={inquiry.status}
                                      >
                                        {
                                          Object.keys(InquiryStatus)[
                                            Object.values(
                                              InquiryStatus
                                            ).indexOf(
                                              inquiry.status as InquiryStatus
                                            )
                                          ]
                                        }
                                      </Tag>
                                    )
                                  ) : inquiry.status ===
                                      InquiryStatus.RECEIVED ||
                                    inquiry.status ===
                                      InquiryStatus.SHORTLISTED ||
                                    inquiry.status ===
                                      InquiryStatus.APPROVED ? (
                                    <button
                                      className="button-block"
                                      onClick={() => getQuotation(inquiry)}
                                    >
                                      View Quotation
                                    </button>
                                  ) : (
                                    <Tag
                                      color={
                                        inquiry.status ===
                                        InquiryStatus.APPROVED
                                          ? "green"
                                          : inquiry.status ===
                                            InquiryStatus.SHORTLISTED
                                          ? "yellow"
                                          : "orange"
                                      }
                                      key={inquiry.status}
                                    >
                                      {
                                        Object.keys(InquiryStatus)[
                                          Object.values(InquiryStatus).indexOf(
                                            inquiry.status as InquiryStatus
                                          )
                                        ]
                                      }
                                    </Tag>
                                  )} */}
                                </div>
                              </div>
                              <div className="row">
                                {quotations.length > 0 && (
                                  <div className="col-lg-12">
                                    <QuotationList
                                      inquiryRef={inquiry}
                                      approveQuotationDispatch={
                                        approveQuotationDispatchCallback
                                      }
                                      shortListQuotationDispatch={
                                        shortListQuotationDispatchCallback
                                      }
                                      purchaseOrderFulfillDispatch={
                                        purchaseOrderFulfillDispatchCallback
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                              {/* <div className="row">
                                <Space
                                  direction="vertical"
                                  hidden={
                                    inquiry.status === InquiryStatus.EXPIRED ||
                                    inquiry.status === InquiryStatus.CANCELLED
                                  }
                                >
                                  <ProjectTimeLine />
                                </Space>
                              </div> */}
                            </>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 text-center">
                  <Pagination
                    current={currentPage}
                    showSizeChanger={false}
                    onChange={onPageChange}
                    total={paging?.totalCount}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                    }
                  />
                </div>
              </div>
            </div>
          </section>

          <Modal
            width={1000}
            centered
            title={
              user.userType === UserType.CUSTOMER &&
              modalInquiry?.status === InquiryStatus.POSTED ? (
                <Link
                  to={`/inquiry/edit/${modalInquiry?.id}`}
                  state={{ inquiry: modalInquiry }}
                >
                  <EditOutlined /> {modalInquiry?.title}
                </Link>
              ) : (
                modalInquiry?.title
              )
            }
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            className="bg-light"
          >
            <InquiryViewContent
              userId={modalInquiry?.userId}
              scopeOfWork={modalInquiry?.scopeOfWork}
              minimumBudget={modalInquiry?.minimumBudget}
              maximumBudget={modalInquiry?.maximumBudget}
              status={modalInquiry?.status!}
              images={modalInquiry?.images}
              shortListQuotations={Number(modalInquiry?.shortListQuotations)}
            ></InquiryViewContent>
          </Modal>
        </>
      )}
    </>
  );
};

const PurchaseOrderContent = (props: InquiryResponse) => {
  return (
    <>
      <Space direction="vertical"></Space>
    </>
  );
};

const InquiryViewContent = (props: InquiryResponse) => {
  return (
    <>
      <p>{parse(props.scopeOfWork! || "")}</p>
      <div className="row">
        <div className="col-lg-3">
          <b>Minimum budget:</b> {props.minimumBudget}
        </div>
        <div className="col-lg-6"></div>
        <div className="col-lg-3">
          <b>Maximum budget:</b> {props.maximumBudget}
        </div>
      </div>
      <div className="row">&nbsp;</div>
      <div className="row">
        <div className="col-lg-6">
          {props.images?.map((img: any) => (
            <>
              <a
                key={Math.random()}
                target="_blank"
                rel="noreferrer"
                href={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${img.fileName}&type=inquiry`}
              >
                {img.fileName}
              </a>
              <br />
            </>
          ))}
        </div>
      </div>
    </>
  );
};
