import React, { Fragment } from "react";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { store } from "../../../../store";
import { UserType } from "../../../../enums/usertype.enum";
import { Breadcrumb } from "antd";

interface NameVaulePair {
  [key: string]: string;
}

export const BreadCrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let pages: NameVaulePair = {};

  pages["/view"] = "View";
  pages["/view/supplier"] = "Supplier";
  pages["/view/supplier/list"] = "Supplier List";
  pages["/navigate"] = "Navigate";
  pages["/navigate/supplier"] = "Supplier";
  pages["/navigate/supplier/list"] = "Supplier List";
  pages["/navigate/supplier/store"] = "Supplier Store";
  pages["/navigate/supplier/home"] = "Dashboard";
  pages["/navigate/category"] = "Products";
  pages["/navigate/customer"] = "Customer";
  pages["/navigate/customer/home"] = "Dashboard";
  pages["/admin"] = "Admin";
  pages["/admin/home"] = "Dashboard";
  pages["/admin/area"] = "Area";
  pages["/admin/country"] = "Country";
  pages["/admin/city"] = "City";
  pages["/admin/category"] = "Category";
  pages["/navigate/chat/app"] = "Chat";

  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;

    return (
      <Breadcrumb.Item key={url}>
        {matchPath(location.pathname, url) ? (
          <Link to={url}>{pages[url]}</Link>
        ) : (
          pages[url]
        )}
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  // const breadCrumbPageList: string[] = pages[location.pathname]?.split(" ");

  // const getPath = (pageValue: string) => {
  //   console.log(Object.keys(pages)[Object.values(pages).indexOf(pageValue)]);

  //   return Object.keys(pages)[Object.values(pages).indexOf(pageValue)] && "/";
  // };

  return (
    <div className="crumbles">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9">
            <Breadcrumb>{breadcrumbItems}</Breadcrumb>
            {/* <ul className="crumbleBox">
              {breadCrumbPageList?.map((bc) => (
                <>
                  <li>
                    <Link to={getPath(bc)}>{bc}</Link>
                  </li>
                  {breadCrumbPageList.length > 1 && <li>&ensp;/&ensp;</li>}
                </>
              ))}
            </ul> */}
          </div>
          <div className="col-lg-3">
            <button
              type="button"
              className="button-block"
              onClick={() => navigate("/inquiry/general")}
              hidden={store.getState().auth.user.userType !== UserType.CUSTOMER}
            >
              Send Inquiry
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
