import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useEffectOnce from "../../hooks/useEffectOnce";
import * as SetupService from "../../store/setups/setups.actions";
import * as AuthService from "../../store/auth/auth.actions";
import { displayErrorMessage } from "../../utils/notifications";
import { clearAuthError } from "../../store/auth/auth.slice";
import * as routes from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { Col, Row, Skeleton, Space, Spin, Typography } from "antd";
import { BreadCrumb, MenuNavigator, PageBanner, PageFooter } from "../Layout";
import buysingColorlogo from "../assets/BuySing_Color_Logo.png";

export const SubscriptionPage = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    setups,
    status: setupStatus,
    error: setupError,
  } = useAppSelector((state) => state.setup);

  const {
    error,
    status: authStatus,
    user,
  } = useAppSelector((state) => state.auth);

  useEffectOnce(() => {
    dispatch(
      SetupService.fetchActiveSubscriptions({ pageNo: 1, pageSize: 10 })
    );
  });

  useEffect(() => {
    if (error) {
      displayErrorMessage(error);

      dispatch(clearAuthError());
    }

    if (authStatus === "initPaymentIntentRejected") {
      // setHidePaymentButton(false);
      // setShowPaymentCard(false);
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (authStatus === "initPaymentIntentResolved") {
      navigate(routes.TO_PAYMENT_PAGE);
    }
  }, [dispatch, authStatus]);

  return (
    <>
      <MenuNavigator buysing_logo={buysingColorlogo} fonts_color="black" />
      <PageBanner />
      <BreadCrumb />
      <div className="container text-center">
        <h3 className="subscribe-heading">BUYSING FEATURES</h3>
        <Typography.Text>
          <b>
            Getting started with Buysing is a breeze. Just sign-up and subscribe
            as a Hotel/ Supplier and <br />
            follow the steps mentioned as follows:
          </b>
        </Typography.Text>
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-9">
            <Row gutter={16}>
              <Col>
                <h3 className="subscribe-heading">SUPPLIER FLOW</h3>
                <div className="subscribe-box">
                  <div
                    className="gbp"
                    style={{
                      lineHeight: "50px",
                      padding: "40px",
                      height: "580px",
                      borderTop: "1px solid #d3b35e",
                      borderRadius: "10px 10px 10px 10px",
                    }}
                  >
                    <Space direction="vertical">
                      <label>Account Activate</label>
                      <label>Complete your profile</label>
                      <label>Showcase your offerings</label>
                      <label>Connect with clients</label>
                      <label>Setup the procurement</label>
                      <label>Track business cycle</label>
                      <label>Get feedback</label>
                    </Space>
                  </div>
                </div>
              </Col>
              <Col>
                <h3 className="subscribe-heading">HOTEL FLOW</h3>
                <div className="subscribe-box">
                  <div
                    className="gbp"
                    style={{
                      lineHeight: "50px",
                      padding: "40px",
                      height: "580px",
                      borderTop: "1px solid #d3b35e",
                      borderRadius: "10px 10px 10px 10px",
                    }}
                  >
                    <Space direction="vertical">
                      <label>Account Activate</label>
                      <label>Complete your profile</label>
                      <label>
                        Float inquiries to a pool <br />
                        of suppliers
                      </label>
                      <label>Shortlist quotations</label>
                      <label>Initiate procurement</label>
                      <label>Track business cycle</label>
                      <label>Bookmark your supplier</label>
                      <label>Send supplier feedback</label>
                    </Space>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <h3 className="subscribe-heading">Subscribe Now </h3>
        {setupStatus === "fetchActiveSubscriptionsPending" ? (
          <Skeleton active></Skeleton>
        ) : (
          <div className="row">
            {setups.subscriptions.map((sub) => (
              <div className="col-lg-3" key={sub.id}>
                <div className="subscribe-box">
                  <span>{sub.title}</span>
                  <div className="gbp">{sub.charges} GBP</div>
                  <button
                    className="sub-btn"
                    type="button"
                    onClick={() =>
                      dispatch(AuthService.initPaymentIntent(sub.id))
                    }
                  >
                    {authStatus === "initPaymentIntentPending" ? (
                      <Spin size="small" />
                    ) : (
                      "Select"
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <br />
      <br />
      <br />
      <PageFooter />
    </>
  );
};
