import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import authReducer from "./auth/auth.slice";
import supplierReducer from "./supplier/supplier.slice";
import listReducer from "./lists/lists.slice";
import inquiryReducer from "./inquiry/inquiry.slice";
import quotationReducer from "./quotation/quotation.slice";
import fireStoreReducer from "./firestore/firestore.slice";
import setupReducer from "./setups/setups.slice";
import { reducer as appForm } from "redux-form";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const rootReducer = combineReducers({
  auth: persistReducer({ key: "bsauth", storage }, authReducer),
  supplier: supplierReducer,
  list: persistReducer({ key: "bslist", storage }, listReducer),
  inquiry: inquiryReducer,
  quotation: quotationReducer,
  firestore: fireStoreReducer,
  setup: setupReducer,
  // profile: profileReducer,
  // mentorship: mentorshipReducer,
  // room: roomReducer,
  form: appForm,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => customizedMiddleware,
});

export const persistor = persistStore(store);
