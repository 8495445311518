import { Product, User } from "../../../types";
import * as routes from "../../../constants/routes";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import {
  Avatar,
  Image,
  Input,
  Pagination,
  PaginationProps,
  Skeleton,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";

import useEffectOnce from "../../../hooks/useEffectOnce";
import * as ListService from "../../../store/lists/list.action";
import * as SetupService from "../../../store/setups/setups.actions";
import { useState } from "react";
import config from "../../../config";
import { UserType } from "../../../enums";

const { Search } = Input;

export const UserList = () => {
  const dispatch = useAppDispatch();

  const {
    status: listStatus,
    entities,
    usersPaging,
  } = useAppSelector((state) => state.list);
  const { status: setupStatus } = useAppSelector((state) => state.setup);
  const [userCurrentPage, setUserCurrentPage] = useState<number>(1);

  const biusers = entities.users?.reduce(function (
    accumulator: any[],
    currentValue,
    currentIndex,
    array
  ) {
    if (currentIndex % 2 === 0)
      accumulator.push(array.slice(currentIndex, currentIndex + 2));
    return accumulator;
  },
  []);

  const updateUserStatus = (userId: string, status: boolean) => {
    dispatch(SetupService.updateUserStatus({ id: userId, status: status }));
  };

  const onUserPageChange: PaginationProps["onChange"] = (page, pageSize) => {
    dispatch(
      ListService.fetchAllUsers({
        pageNo: page,
        pageSize: pageSize,
      })
    );

    setUserCurrentPage(page);
  };

  useEffectOnce(() => {
    dispatch(ListService.fetchAllUsers({ pageNo: 1, pageSize: 9999 }));
  });

  return (
    <>
      {listStatus === "fetchAllUsersPending" ? (
        <Skeleton active></Skeleton>
      ) : (
        <div
          style={{ width: "1070px", marginLeft: "auto", marginRight: "auto" }}
        >
          {/* <Pagination
          current={userCurrentPage}
          showSizeChanger={false}
          onChange={onUserPageChange}
          total={usersPaging.totalCount}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          // defaultPageSize={20}
          // defaultCurrent={1}
        /> */}
          <div className="row">
            {biusers?.map((usr, index) => (
              <div className="row" key={Math.random()}>
                <div className="col-lg-6 col-md-6">
                  <div className="interestCard">
                    <div className="categorDp">
                      {usr[0]?.image ? (
                        <Avatar
                          style={{ margin: "50px 20px" }}
                          size={64}
                          src={
                            <Image
                              preview={false}
                              src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${usr[0].image}&type=user`}
                            />
                          }
                          icon={<UserOutlined />}
                          className="mb-1"
                        />
                      ) : (
                        <Avatar
                          style={{ margin: "50px 20px" }}
                          icon={<UserOutlined />}
                          size={64}
                        />
                      )}
                    </div>
                    <div className="categoryDesc">
                      <p className="profileItem">
                        <Tag color="goldenrod">
                          <b>
                            {usr[0].userType === UserType.CUSTOMER ? (
                              <Typography.Text style={{ color: "white" }}>
                                HOTEL
                              </Typography.Text>
                            ) : usr[0].userType === UserType.SUPPLIER ? (
                              <Typography.Text style={{ color: "white" }}>
                                SUPPLIER
                              </Typography.Text>
                            ) : (
                              <Typography.Text style={{ color: "white" }}>
                                ADMIN
                              </Typography.Text>
                            )}
                          </b>
                        </Tag>
                      </p>
                      <p className="itemDate">
                        {moment(usr[0]?.createdOn).format(
                          "MM-DD-YYYY HH:mm:ss"
                        )}
                      </p>
                      <Typography.Text className="itemDesc">
                        {usr[0]?.fullName}
                      </Typography.Text>
                      <Typography.Text className="profileItem">
                        {usr[0]?.userName}
                      </Typography.Text>
                      {usr[0].isActive ? (
                        <button
                          style={{
                            margin: "250px 0px 350px 250px",
                            position: "absolute",
                          }}
                          className="btn btn-danger btn-sm"
                          onClick={() => updateUserStatus(usr[0].id, false)}
                        >
                          <span id="button-text">
                            {setupStatus === "updateUserStatusPending" ? (
                              <Spin size="small" />
                            ) : (
                              "Deactivate"
                            )}
                          </span>
                        </button>
                      ) : (
                        <button
                          style={{
                            margin: "250px 0px 350px 250px",
                            position: "absolute",
                          }}
                          className="btn btn-success btn-sm"
                          onClick={() => updateUserStatus(usr[0].id, true)}
                        >
                          <span id="button-text">
                            {setupStatus === "updateUserStatusPending" ? (
                              <Spin size="small" />
                            ) : (
                              "Activate"
                            )}
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {usr.length > 1 && (
                  <div className="col-lg-6 col-md-6">
                    <div className="interestCard">
                      <div className="categorDp">
                        {usr[1].image ? (
                          <Avatar
                            style={{ margin: "50px 20px" }}
                            size={64}
                            src={
                              <img
                                alt=""
                                src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${usr[1].image}&type=user`}
                              />
                            }
                            icon={<UserOutlined />}
                            className="mb-1"
                          />
                        ) : (
                          <Avatar
                            style={{ margin: "50px 20px" }}
                            icon={<UserOutlined />}
                            size={64}
                          />
                        )}
                      </div>
                      <div className="categoryDesc">
                        <p className="profileItem">
                          <Tag color="goldenrod">
                            <b>
                              {usr[0].userType === UserType.CUSTOMER ? (
                                <Typography.Text style={{ color: "white" }}>
                                  HOTEL
                                </Typography.Text>
                              ) : usr[0].userType === UserType.SUPPLIER ? (
                                <Typography.Text style={{ color: "white" }}>
                                  SUPPLIER
                                </Typography.Text>
                              ) : (
                                <Typography.Text style={{ color: "white" }}>
                                  ADMIN
                                </Typography.Text>
                              )}
                            </b>
                          </Tag>
                        </p>
                        <p className="itemDate">
                          {moment(usr[1]?.createdOn).format(
                            "MM-DD-YYYY HH:mm:ss"
                          )}
                        </p>
                        <Typography.Text className="itemDesc">
                          {usr[1]?.fullName}
                        </Typography.Text>
                        <Typography.Text className="profileItem">
                          {usr[1]?.userName}
                        </Typography.Text>
                        {usr[1].isActive ? (
                          <button
                            style={{
                              margin: "250px 0px 350px 250px",
                              position: "absolute",
                            }}
                            className="btn btn-danger btn-sm"
                            onClick={() => updateUserStatus(usr[1].id, false)}
                          >
                            <span id="button-text">
                              {setupStatus === "updateUserStatusPending" ? (
                                <Spin size="small" />
                              ) : (
                                "Deactivate"
                              )}
                            </span>
                          </button>
                        ) : (
                          <button
                            style={{
                              margin: "250px 0px 350px 250px",
                              position: "absolute",
                            }}
                            className="btn btn-success btn-sm"
                            onClick={() => updateUserStatus(usr[1].id, true)}
                          >
                            <span id="button-text">
                              {setupStatus === "updateUserStatusPending" ? (
                                <Spin size="small" />
                              ) : (
                                "Activate"
                              )}
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
