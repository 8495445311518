import { Table, Tag } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import * as QuotationService from "../../../store/quotation/quotation.actions";
import { QuotationStatus } from "../../../enums";
import { displayErrorMessage } from "../../../utils/notifications";
import { clearQuotationStatus, clearQuoteError } from "../../../store/quotation/quotation.slice";

interface QuotationGridProps {
  status: number;
}

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (text: string, record: any) =>
      record.status === QuotationStatus.SENT ? (
        <Link
          to={`/inquiry/quotation/edit/${record.id}`}
          state={{ quotation: record }}
        >
          {text}
        </Link>
      ) : (
        <span>{text}</span>
      ),
  },
  {
    title: "Date of service",
    dataIndex: "validUpto",
    key: "validUpto",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string) => (
      <Tag
        color={
          ["Expired", "Cancelled"].includes(text)
            ? "red"
            : text === "Approved"
            ? "green"
            : "orange"
        }
        key={text}
      >
        {
          Object.keys(QuotationStatus)[
            Object.values(QuotationStatus).indexOf(
              Number(text) as QuotationStatus
            )
          ]
        }
      </Tag>
    ),
  },
  {
    title: "Inquiry",
    dataIndex: ["inquiry", "title"],
    key: "inquiryTitle",
  },
  {
    title: "Inquiry Validity",
    dataIndex: ["inquiry", "lastDate"],
    key: "inquiryValidity",
  },
  {
    title: "Inquiry Budget",
    dataIndex: ["inquiry", "maximumBudget"],
    key: "inquiryBudge",
  },
];

export const QuotationGrid = (props: QuotationGridProps) => {
  const dispatch = useAppDispatch();

  const { quotations, status, error } = useAppSelector(
    (state) => state.quotation
  );

  useEffect(() => {
    dispatch(QuotationService.fetchSupplierQuotations(props.status));
  }, [dispatch, props.status]);

  useEffect(() => {
    if (status === "fetchSupplierQuotationsRejected") {
      displayErrorMessage(error);

      dispatch(clearQuoteError());
      dispatch(clearQuotationStatus());
    }
  }, [status, error]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={quotations}
        pagination={{ total: quotations.length, pageSize: 10 }}
      />
    </>
  );
};
