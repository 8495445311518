import React from "react";
// import * as routes from "../constants/routes";
// import { Role } from "../enums";
import { Route, Routes } from "react-router-dom";
// import { CreateAccount, Home, Labs, Login, NotFound } from "../sections";
// import { ProfilePage } from "../view/Profile";
// import { PrivateRoute } from "./private-route";
// import { PublicRoute } from "./public-route";
// import { HomePage } from "../view";
import {
  ProductFormContentNavigation,
  FormNavigation,
  InquiryFormContentNavigation,
  ChatApp,
  MaintenancePage,
} from "../view";
import { SignupNavigation, NotFound, HomePage } from "../view";
import {
  ContentNavigation,
  ProtectedAdminContentNavigation,
  ProtectedContentNavigation,
  SupplierContentNavigation,
} from "../view/Layout/ContentLayout";
import { ProtectedAdminRoute, ProtectedRoute } from "./PrivateRoute";
import { User } from "../types";
import { SubscriptionPage } from "../view/Account/Subscription";
/**
 * Top level application router
 *
 * @returns {Component}
 */
export const Router = ({ user }: { user: User }) => {
  // console.log("user"+JSON.stringify(user));

  return (
    <Routes>
      <Route path="/" element={<HomePage />}></Route>
      <Route path="/home" element={<HomePage />}></Route>
      <Route path="/user/*" element={<SignupNavigation />}></Route>
      <Route path="/account/*" element={<SignupNavigation />}></Route>
      <Route path="/subscription" element={<SubscriptionPage />}></Route>
      <Route
        path="/setup/*"
        element={
          <ProtectedRoute>
            <FormNavigation />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/view/*"
        element={
          <ProtectedRoute
          //   // isAllowed={!!user /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <ContentNavigation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/view/company/*"
        element={
          <ProtectedRoute
          //   // isAllowed={!!user /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <SupplierContentNavigation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/navigate/*"
        element={
          <ProtectedRoute
          // isAllowed={!!user.id /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <ProtectedContentNavigation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/*"
        element={
          <ProtectedAdminRoute
          // isAllowed={!!user.id /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <ProtectedAdminContentNavigation />
          </ProtectedAdminRoute>
        }
      />
      <Route
        path="/product/*"
        element={
          <ProtectedRoute
          // isAllowed={!!user.id /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <ProductFormContentNavigation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inquiry/*"
        element={
          <ProtectedRoute
          // isAllowed={!!user.id /*&& !!user.permissions && user.permissions!.includes("view")*/}
          >
            <InquiryFormContentNavigation />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
      <Route path="/404" element={<NotFound />} />

      {/* <Route path="/" element={<HomePage />}></Route>
      <Route path="/user/*" element={<FormNavigation />}></Route>
      <Route path="/view/*" element={<ContentNavigation />}></Route> */}
      {/* <Route path="/login" element={<LoginPage />}></Route>
      <Route path="/signup" element={<SignupPage />}></Route> */}
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
};
