import { useEffect, useState } from "react";
import { Product, Supplier } from "../../../../types";
import * as routes from "../../../../constants/routes";
import prodlogo from "../../../assets/supp-logo.png";
import config from "../../../../config";
import { Link, useNavigate } from "react-router-dom";
import useEffectOnce from "../../../../hooks/useEffectOnce";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import * as SupplierService from "../../../../store/supplier/supplier.action";
import { UserType } from "../../../../enums";

interface SupplierServicesPageProps {
  //   products: Product[];
}

export const SupplierServicesPage = (props: SupplierServicesPageProps) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.auth);

  const { selectedSupplier } = useAppSelector((state) => state.supplier);

  useEffectOnce(() => {
    if (user.userType === UserType.SUPPLIER) {
      dispatch(SupplierService.fetchSupplierProducts(user.company?.id!));
    }
  });

  return (
    <>
      <section id="supProducts" className="profilingSection centraSpacing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 text-center">
              <button
                className="button-block"
                onClick={() => navigate("/product/service/add")}
              >
                Add New Services
              </button>
            </div>
            <div className="col-lg-3"></div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <h3>Services</h3>
              <br />
            </div>
          </div>
          <div className="row">
            {selectedSupplier?.products &&
              selectedSupplier?.products
                ?.filter((product) => product.category.categoryType === 2)
                .map((product, index) => (
                  <div className="col-lg-3 col-md-6 col-sm-6" key={product.id}>
                    <div className="profileCard">
                      <div className="imgDisplay">
                        {product.images && product.images.length > 0 ? (
                          <img
                            src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${product.images[0].fileName}&type=media`}
                            alt="Product Logo"
                          />
                        ) : (
                          <img src={prodlogo} alt="Product Logo" />
                        )}
                      </div>
                      <div className="textDesc">
                        <p>
                          <b>{product.title}</b>
                        </p>
                        <p>{product.subTitle}</p>
                      </div>
                      <div className="controlArea" style={{ height: "50px" }}>
                        <button
                          className="button-block"
                          onClick={() =>
                            navigate("/product/service/edit", {
                              state: { product: product },
                            })
                          }
                          disabled={product.IsApproved}
                        >
                          Edit Service
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </section>
    </>
  );
};
