import { createSlice } from "@reduxjs/toolkit";
import { CommonState, Country } from "../../types";
import {
  fetchCategoryProducts,
  fetchServices,
  fetchCountries,
  fetchCities,
  fetchAreaCoverage,
  fetchAllProducts,
  approveProduct,
  rejectProduct,
  deleteCategory,
  deleteProduct,
  fetchAllUsers,
} from "./list.action";
import * as Util from "../../utils/helper";

export const initialState: CommonState = Object.freeze({
  status: "idle",
  entities: {
    countries: [],
    cities: [],
    categories: [],
    products: [],
    suppliers: [],
    areaCoverages: [],
    users: [],
  },
  productPaging: { pageNumber: 1, pageSize: 10 },
  usersPaging: { pageNumber: 1, pageSize: 10 },
  error: "",
});

const listSlice = createSlice({
  name: "list",
  initialState,
  reducers: {
    clearListError: (state) => {
      state.error = "";
    },
    clearListStatus: (state) => {
      state.status = "idle";
    },
    resetListState: (state) => {
      state = {
        status: "idle",
        entities: {
          countries: [],
          cities: [],
          categories: [],
          products: [],
          suppliers: [],
          areaCoverages: [],
          users: [],
        },
        productPaging: { pageNumber: 1, pageSize: 10 },
        usersPaging: { pageNumber: 1, pageSize: 10 },
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServices.pending, (state) => {
      state.status = "fetchServicesPending";
    });
    builder.addCase(fetchServices.fulfilled, (state, { payload }) => {
      state.entities.categories = [];

      for (let index = 0; index < payload.length; index++) {
        state.entities.categories.push(payload[index]);
      }

      state.status = "fetchServicesSuccess";
      state.error = "";
    });
    builder.addCase(
      fetchServices.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "fetchServicesRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(fetchCategoryProducts.pending, (state) => {
      state.status = "fetchCategoryProductsPending";
    });
    builder.addCase(fetchCategoryProducts.fulfilled, (state, { payload }) => {
      state.entities.productCategories = Util.fill(payload);

      state.status = "fetchCategoryProductsSuccess";

      state.error = "";
    });
    builder.addCase(
      fetchCategoryProducts.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "fetchCategoryProductsRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(fetchAllProducts.pending, (state) => {
      state.status = "fetchAllProductsPending";
    });
    builder.addCase(fetchAllProducts.fulfilled, (state, { payload }) => {
      state.entities.products = Util.fill(payload.result);

      state.productPaging = payload.paging;

      state.status = "fetchAllProductsSuccess";

      state.error = "";
    });
    builder.addCase(
      fetchAllProducts.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "fetchAllProductsRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(fetchCountries.fulfilled, (state, { payload }) => {
      state.entities.countries = Util.fill(payload);

      state.status = "fetchCountriesSuccess";
      state.error = "";
    });

    builder.addCase(fetchCities.fulfilled, (state, { payload }) => {
      state.entities.cities = [];

      for (let index = 0; index < payload.length; index++) {
        const country: Country | undefined = state.entities.countries?.find(
          (c) => c.id === payload[index].countryId
        );

        if (country?.title) {
          payload[index].countryTitle = country.title;
        }

        state.entities.cities.push(payload[index]);
      }

      state.status = "fetchCitiesSuccess";
      state.error = "";
    });

    builder.addCase(fetchAreaCoverage.fulfilled, (state, { payload }) => {
      state.entities.areaCoverages = Util.fill(payload);

      state.status = "fetchAreaCoverageSuccess";

      state.error = "";
    });

    builder.addCase(approveProduct.pending, (state) => {
      state.status = "approveProductPending";

      state.error = "";
    });
    builder.addCase(approveProduct.fulfilled, (state, action) => {
      state.status = "approveProductResolved";

      // state.approvedProductId = BigInt(action.meta.arg);

      state.error = "";
    });
    builder.addCase(
      approveProduct.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "approveProductRejected";
      }
    );

    builder.addCase(rejectProduct.pending, (state) => {
      state.status = "rejectProductPending";

      state.error = "";
    });
    builder.addCase(rejectProduct.fulfilled, (state, { payload }) => {
      state.status = "rejectProductResolved";

      state.error = "";
    });
    builder.addCase(
      rejectProduct.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "rejectProductRejected";
      }
    );

    builder.addCase(deleteCategory.pending, (state) => {
      state.status = "deleteCategoryPending";

      state.error = "";
    });
    builder.addCase(deleteCategory.fulfilled, (state, { payload }) => {
      state.status = "deleteCategoryResolved";

      state.error = "";
    });
    builder.addCase(
      deleteCategory.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "deleteCategoryRejected";
      }
    );

    builder.addCase(deleteProduct.pending, (state) => {
      state.status = "deleteProductPending";

      state.error = "";
    });
    builder.addCase(deleteProduct.fulfilled, (state, { payload }) => {
      state.status = "deleteProductResolved";

      state.error = "";
    });
    builder.addCase(
      deleteProduct.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "deleteProductRejected";
      }
    );

    builder.addCase(fetchAllUsers.pending, (state) => {
      state.status = "fetchAllUsersPending";
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
      state.status = "fetchAllUsersResolved";

      state.entities.users = Util.fill(payload.result);

      state.usersPaging = payload.paging;
    });
    builder.addCase(
      fetchAllUsers.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "fetchAllUsersResolved";
      }
    );
  },
});

export const { clearListError, resetListState, clearListStatus } =
  listSlice.actions;

export default listSlice.reducer;
