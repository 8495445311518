import { Link } from "react-router-dom";
import supplogo from "../../../assets/supp-logo.png";
import { FilterBar } from "../../../Components";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import * as SupplierService from "../../../../store/supplier/supplier.action";
import config from "../../../../config";
import * as routes from "../../../../constants/routes";

interface SupplierListProps {
  showFilterBar: boolean;
  showFavorites: boolean;
  showControls?: boolean;
  // data: Supplier[];
}

export const SupplierList = (props: SupplierListProps) => {
  const dispatch = useAppDispatch();

  const { entities } = useAppSelector((state) => state.supplier);

  const [productCategoryId, setProductCategoryId] = useState<Array<any>>([]);
  const [serviceCategoryId, setServiceCategoryId] = useState<Array<any>>([]);

  // const [data, setData] = useState<Array<Supplier>>([]);
  useEffect(() => {
    if (props.showFavorites) {
      dispatch(SupplierService.fetchFavoriteSuppliers());
    } else {
      dispatch(SupplierService.fetchSupplier(undefined));
    }
  }, [dispatch, props.showFavorites]);

  const triggerSearch = (searchText: string) => {
    let paramString = `?searchText=${searchText}`;
    if (productCategoryId) {
      productCategoryId.forEach(
        (c) => (paramString += `&categoryids=${c[c.length - 1]}`)
      );
    }
    if (serviceCategoryId) {
      serviceCategoryId.forEach(
        (c) => (paramString += `&categoryids=${c[c.length - 1]}`)
      );
    }

    dispatch(SupplierService.fetchSupplier(paramString));
  };

  return (
    <>
      {/* {store.getState().auth.user.userType === UserType.CUSTOMER && ( */}
      <>
        {props.showFilterBar === true && (
          <FilterBar
            serviceCategoryChangeCallBack={setServiceCategoryId}
            productCategoryChangeCallBack={setProductCategoryId}
            triggerSearchCallBack={triggerSearch}
          />
        )}
        <div className="row">
          {/* {categoryId &&
          props.data
            .filter(
              (supplier) =>
                categoryId[categoryId.length - 1] === 0 ||
                supplier.categories?.some(
                  (category) =>
                    category["id"] ===
                    Math.trunc(categoryId[categoryId.length - 1])
                )
            ) */}
          {entities.suppliers?.map((supplier, index) => (
            <div className="col-lg-3 col-md-6 col-sm-6" key={Math.random()}>
              <div className="profileCard">
                <div className="imgDisplay">
                  {supplier.company?.image ? (
                    <img
                      src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${supplier.company?.image}&type=user`}
                      alt="Profile Logo"
                    />
                  ) : (
                    <img src={supplogo} alt="Profile Logo" />
                  )}
                </div>
                <div className="textDesc">
                  <p>
                    <b>{supplier?.company?.companyName!}</b>
                    <br />
                    {(supplier.company?.companyOverview?.length || 0) <= 100
                      ? supplier.company?.companyOverview?.substring(0, 100)
                      : supplier.company?.companyOverview
                          ?.substring(0, 100)
                          .concat(" ...")}
                  </p>
                </div>
                <div className="controlArea">
                  <Link
                    to={`${routes.SUPPLIER_LIST_ID}/${supplier?.company?.id!}`}
                    className="button-block-outlined"
                  >
                    View Company
                  </Link>
                  <Link
                    className="button-block"
                    to={`/inquiry/supplier`}
                    state={{ supplier: supplier }}
                  >
                    Send Inquiry
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
      {/* )} */}
    </>
  );
};
