import {
  AutoComplete,
  Button,
  Form,
  Input,
  Select,
  Space,
  List,
  notification,
  Spin,
} from "antd";
import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import * as AuthService from "../../../store/auth/auth.actions";
// import { fetchServices } from "../../../store/lists/list.action";
import { AreaCoverage, CreateProfileData } from "../../../types";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import { ProfilePage } from "../../../view";
import styles from "./index.module.css";
import { socials } from "../../../data";
import { AiOutlineMinusCircle, AiOutlinePlus } from "react-icons/ai";
import {
  PreLoader,
  SingleUpload,
  UploadPictureWall,
} from "../../../view/Components";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import config from "../../../config";
import * as routes from "../../../constants/routes";
import {
  clearAuthError,
  clearAuthStatus,
} from "../../../store/auth/auth.slice";
import { AreaCoverageDropDown } from "../../Components/areaCoverageList";

const { Item } = Form;
const { Option } = Select;

// var mediaImages = [""];

export const CompanyProfilePage = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const { status, error, user } = useAppSelector((state) => state.auth);

  const {
    status: listStatus,
    error: listError,
    entities: listEntities,
  } = useAppSelector((state) => state.list);

  const [imageMediaList, setImageMediaList] = useState<
    { id: number; media: string; mediaType: string }[]
  >([]);
  const [videoMediaList, setVideoMediaList] = useState<
    { id: number; media: string; mediaType: string }[]
  >([]);

  useEffect(() => {
    if (error) {
      displayErrorMessage(error);
      dispatch(clearAuthError());
    }

    if (status === "userProfileUpdated" && !error) {
      displaySuccessNotification("Profile has been updated");

      dispatch(AuthService.loadCurrentUser());

      dispatch(clearAuthStatus());
    }

    if (status.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [status, error]);

  const onFormSubmit = (values: CreateProfileData) => {
    dispatch(AuthService.createProfile(values));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  const getProfileAreaCoveragesArray = (): number[] => {
    return Array.from(
      user?.company?.areaCoverage?.map((i: AreaCoverage) => i.id) || []
    );
  };

  // const [{ companyCountry, companyCity }, setCountryCityData] = useState({
  //   companyCountry: "",
  //   companyCity: "",
  // });

  // const handleCompanyCountryChange = (event: any) => {
  //   setCountryCityData((data) => ({
  //     companyCity: "",
  //     companyCountry:
  //       event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text,
  //   }));
  // };

  const [autoCompleteResult, setAutoCompleteResult] = useState<string[]>([]);

  const onWebsiteChange = (value: string) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(
        [".com", ".org", ".net"].map((domain) => `${value}${domain}`)
      );
    }
  };

  const websiteOptions = autoCompleteResult.map((website) => ({
    label: website,
    value: website,
  }));

  const [profileLocation, setLocationFromProfile] = useState("");

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        // company: {
        CompanyName: user?.company?.companyName,
        CompanyDetails: user?.company?.companyDetails,
        CompanyOverview: user?.company?.companyOverview,
        GovernmentIdentifier: user?.company?.governmentIdentifier,
        CompanyRegistrationNumber: user?.company?.companyRegistrationNumber,
        LicenseCertificateNumber: user?.company?.licenseCertificateNumber,
        VatRegistrationNumber: user?.company?.vatRegistrationNumber,
        Website: user?.company?.website,
        MainAddress: user?.company?.mainAddress,
        SocialLinks: user?.company?.socialLinks,
        CompanyLogo: user?.company?.image,
        CompanyBanner: user?.company?.banner,
        AreaCoverage: getProfileAreaCoveragesArray(),
        // },
      });

      setImageMediaList(
        user?.company?.companyMedia?.filter((m) => m.mediaType === "IMAGE")!
      );
      setVideoMediaList(
        user?.company?.companyMedia?.filter((m) => m.mediaType === "VIDEO")!
      );
    }
  }, [user]);

  const logoSelectCallback = (file: any) => {
    if (file) form.setFieldsValue({ CompanyLogo: file.response.result });
  };

  const bannerSelectCallback = (file: any) => {
    if (file) form.setFieldsValue({ CompanyBanner: file.response.result });
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormSubmit}
        onFinishFailed={onFinishFailed}
        size="large"
      >
        <div className="row">
          <div className="col-lg-9">
            <h3>Contact Person Detail</h3>
            <br />
            <p>
              Please Fill all the field to complete information about the person
              which will be contacted or informed.
            </p>
          </div>
        </div>
        <ProfilePage
          formRef={form}
          isNested={true}
          setProfileLocation={(location: string) => setLocationFromProfile}
        />
        <div className="row formRow">
          <div className="col-lg-12">
            <h3>Company Details</h3>
            <br />
            <p>
              Please Fill all the field to complete information about the
              company.
            </p>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-lg-12">
                  <InputFile />
                </div> */}
          <div className="col-lg-12">
            <div className="row formRow">
              <div className="col-lg-6" style={{ textAlign: "right" }}>
                <Item hidden={true} name="CompanyLogo">
                  <input />
                </Item>
                <Space>
                  <img
                    alt="Logo"
                    style={{ width: "104px", height: "104px" }}
                    src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${user?.company?.image}&type=user`}
                  />
                  <SingleUpload
                    buttonText={"Logo"}
                    onImageSelect={logoSelectCallback}
                    type="user"
                  />
                </Space>
              </div>
              <div className="col-lg-6">
                <Item hidden={true} name="CompanyBanner">
                  <input />
                </Item>
                <Space>
                  <img
                    alt="Logo"
                    style={{ width: "104px", height: "104px" }}
                    src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${user?.company?.banner}&type=user`}
                  />
                  <SingleUpload
                    buttonText={"Banner"}
                    onImageSelect={bannerSelectCallback}
                    type="user"
                  />
                </Space>
              </div>
            </div>

            <div className="row formRow">
              <div className="col-lg-12">
                <Item
                  name="CompanyName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter what defines your Company Name!",
                    },
                  ]}
                >
                  <input
                    maxLength={30}
                    className="local-form input-reg"
                    placeholder="Company Name *"
                  />
                </Item>
              </div>
            </div>

            <div className="row formRow">
              <div className="col-lg-6">
                <Item
                  noStyle
                  name="CompanyDetails"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your product Company Details!",
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={200}
                    placeholder="CompanyDetails *"
                  />
                </Item>
              </div>
              <div className="col-lg-6">
                <Item
                  noStyle
                  className={[styles.inputReg, styles.localForm].join(" ")}
                  name="CompanyOverview"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your product Company Overview!",
                    },
                  ]}
                >
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    placeholder="CompanyOverview *"
                  />
                </Item>
              </div>
            </div>

            <div className="row formRow">
              <div className="col-lg-6">
                <Item
                  name="GovernmentIdentifier"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your Government Identifier!",
                    },
                  ]}
                >
                  <input
                    maxLength={30}
                    className="local-form input-reg"
                    placeholder="Government Identifier *"
                  />
                </Item>
              </div>
              <div className="col-lg-6">
                <Item
                  name="CompanyRegistrationNumber"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your Company Registration Number!",
                    },
                  ]}
                >
                  <input
                    maxLength={30}
                    className="local-form input-reg"
                    placeholder="Company Registration Number *"
                  />
                </Item>
              </div>
            </div>

            <div className="row formRow">
              <div className="col-lg-6">
                <Item
                  name="LicenseCertificateNumber"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your License Certificate Number!",
                    },
                  ]}
                >
                  <input
                    maxLength={30}
                    className="local-form input-reg"
                    placeholder="Licensing Certificate Number"
                  />
                </Item>
              </div>
              <div className="col-lg-6">
                <Item
                  name="VatRegistrationNumber"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your Vat Registration Number!",
                    },
                  ]}
                >
                  <input
                    maxLength={30}
                    className="local-form input-reg"
                    placeholder="VAT Registration Number"
                  />
                </Item>
              </div>
            </div>
            {/* <div className="row formRow">
                    <div className="col-lg-6">
                      <Item
                        name="services"
                        rules={[
                          {
                            type: "array",
                            required: true,
                            message: "Please select your habitual residence!",
                          },
                        ]}
                      >
                        <Cascader options={services} />
                      </Item>
                    </div>
                  </div> */}
            {/* <div className="row formRow">
                    <div className="col-lg-6">
                      <Item
                        name={["company", "CountryId"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a Country you are from!",
                          },
                        ]}
                      >
                        <select
                          className="local-form input-reg"
                          onChange={handleCompanyCountryChange}
                        >
                          <option value="0">Select Country</option>
                          {countries.map((country, index) => (
                            <option value={index} key={index}>
                              {country}
                            </option>
                          ))}
                        </select>
                      </Item>
                    </div>
                    <div className="col-lg-6">
                      <Item
                        name={["company", "CityId"]}
                        rules={[
                          {
                            required: true,
                            message: "Please enter a City you are from!",
                          },
                        ]}
                      >
                        <select className="local-form input-reg">
                          <option value="0">Select City</option>
                          {cities
                            .filter((city) => city.country === companyCountry)
                            ?.map((city, index) => (
                              <option value={index} key={index}>
                                {city.name}
                              </option>
                            ))}
                        </select>
                      </Item>
                    </div>
                  </div> */}
            {/* <div className="row formRow">
                    <div className="col-lg-12">
                      <ProductServiceWidget categoryIndex={0} />
                    </div>
                  </div>
                  <div className="row formRow">
                    <div className="col-lg-12">
                      <ProductServiceWidget categoryIndex={1} />
                    </div>
                  </div> */}

            <div className="row formRow">
              <div className="col-lg-12">
                <Item
                  name="Website"
                  rules={[{ required: true, message: "Please input website!" }]}
                >
                  <AutoComplete
                    options={websiteOptions}
                    onChange={onWebsiteChange}
                    placeholder="website"
                  >
                    <input className="local-form input-reg" />
                  </AutoComplete>
                </Item>
              </div>
            </div>

            <div className="row formRow">
              <div className="col-lg-12" style={{ textAlign: "center" }}>
                <List 
                  grid={{
                    gutter: 16,
                    column: 4,
                  }}
                  bordered
                  dataSource={imageMediaList}
                  renderItem={(item: any) => (
                    <List.Item>
                      <img
                        alt="Logo"
                        style={{ width: "104px", height: "104px" }}
                        src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${item.media}&type=media`}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </div>

            <div className="row formRow">
              <div className="col-lg-3"></div>
              <div className="col-lg-6" style={{ textAlign: "center" }}>
                {/** upload media end */}
                {/* <Select mode="multiple">{mediaImages}</Select> */}
                <UploadPictureWall
                  buttonText="Upload Gallery"
                  ItemName="Media"
                  type="media"
                  // onImageSelect={mediaImagesCallBack}
                ></UploadPictureWall>
                {/** upload media end */}
              </div>
              <div className="col-lg-3"></div>
            </div>

            <div className="row formRow">
              <div className="col-lg-6">
                {user?.company?.socialLinks && (
                  <List
                    size="small"
                    bordered
                    dataSource={JSON.parse(user.company?.socialLinks!)}
                    renderItem={(item: any) => (
                      <List.Item>{item.link}</List.Item>
                    )}
                  />
                )}
                <Item hidden={true} name="SocialLinks">
                  <input />
                </Item>
                {/* social channels */}
                <Form.List name="SocialLinksList">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Space
                          key={field.key}
                          align="baseline"
                          size="small"
                          style={{ marginRight: "4em" }}
                        >
                          <Item
                            noStyle
                            shouldUpdate={(prevValues, curValues) =>
                              prevValues.channel !== curValues.channel
                            }
                          >
                            {() => (
                              <Item
                                {...field}
                                name={[field.name, "site"]}
                                fieldKey={[field.key, "site"]}
                              >
                                <Select
                                  style={{ width: 130 }}
                                  placeholder="Select site"
                                >
                                  {socials.map((item) => (
                                    <Option key={item.label} value={item.value}>
                                      {item.label}
                                    </Option>
                                  ))}
                                </Select>
                              </Item>
                            )}
                          </Item>
                          <Item
                            {...field}
                            name={[field.name, "link"]}
                            fieldKey={[field.key, "link"]}
                          >
                            <Input />
                          </Item>
                          <AiOutlineMinusCircle
                            onClick={() => remove(field.name)}
                            color="red"
                          />
                        </Space>
                      ))}

                      <Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<AiOutlinePlus />}
                        >
                          Add channels
                        </Button>
                      </Item>
                    </>
                  )}
                </Form.List>
                {/* end of social channels */}
              </div>

              <div className="col-lg-6">
                {/* {user?.company?.companyMedia && ( */}
                <List
                  size="small"
                  bordered
                  dataSource={videoMediaList}
                  renderItem={(item: any) => (
                    <List.Item>{item.media}</List.Item>
                  )}
                />
                {/* )} */}
                <Form.List name="VideoLinks">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="baseline"
                        >
                          <Item
                            style={{ width: "450px" }}
                            {...restField}
                            name={[name, "Link"]}
                          >
                            <Input placeholder="Video Link" />
                          </Item>
                          <AiOutlineMinusCircle
                            onClick={() => remove(name)}
                            color="red"
                          />
                        </Space>
                      ))}
                      <Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Video Links
                        </Button>
                      </Item>
                    </>
                  )}
                </Form.List>
              </div>
            </div>

            {/* start of area of coverage */}
            <div className="row formRow">
              <div className="col-lg-6">
                <Item
                  name="MainAddress"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company Main Address!",
                    },
                  ]}
                >
                  <input
                    maxLength={100}
                    className="local-form input-reg"
                    placeholder="Main Address *"
                  />
                </Item>
              </div>
              <div className="col-lg-6">
                <AreaCoverageDropDown
                  itemName="AreaCoverage"
                  isRequired={false}
                />
              </div>
            </div>
            {/* end area of coverage */}

            <div className="row formRow">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <button
                  type="submit"
                  className="butn butn-block"
                  disabled={status === "userProfilePending"}
                >
                  <span id="button-text">
                    {status === "userProfilePending" ? (
                      <Spin size="small" />
                    ) : (
                      "Update Profile"
                    )}
                  </span>
                </button>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};
