import { createSlice } from "@reduxjs/toolkit";
import { SetupState } from "../../types";
import * as SetupService from "./setups.actions";
import * as Util from "../../utils/helper";

export const initialState: SetupState = Object.freeze({
  status: "idle",
  setups: { categories: [], subscriptions: [], userSubscriptions: [] },
  error: "",
});

const setupSlice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    clearSetupError: (state) => {
      state.error = "";
    },
    clearSetupStatus: (state) => {
      state.status = "idle";
    },
    resetSetupState: (state) => {
      state = {
        status: "idle",
        setups: { categories: [], subscriptions: [], userSubscriptions: [] },
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(SetupService.createCoverageArea.pending, (state) => {
      state.status = "createAreaCoveragePending";
    });
    builder.addCase(
      SetupService.createCoverageArea.fulfilled,
      (state, { payload }) => {
        state.status = "createAreaCoverageResolved";
        state.error = "";
      }
    );
    builder.addCase(
      SetupService.createCoverageArea.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createAreaCoverageRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SetupService.updateCoverageArea.pending, (state) => {
      state.status = "updateAreaCoveragePending";
    });
    builder.addCase(
      SetupService.updateCoverageArea.fulfilled,
      (state, { payload }) => {
        state.status = "updateAreaCoverageResolved";
        state.error = "";
      }
    );
    builder.addCase(
      SetupService.updateCoverageArea.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "updateAreaCoverageRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SetupService.createCountry.pending, (state) => {
      state.status = "createCountryPending";
    });
    builder.addCase(
      SetupService.createCountry.fulfilled,
      (state, { payload }) => {
        state.status = "createCountryResolved";
        state.error = "";
      }
    );
    builder.addCase(
      SetupService.createCountry.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createCountryRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SetupService.updateCountry.pending, (state) => {
      state.status = "updateCountryPending";
    });
    builder.addCase(
      SetupService.updateCountry.fulfilled,
      (state, { payload }) => {
        state.status = "updateCountryResolved";
        state.error = "";
      }
    );
    builder.addCase(
      SetupService.updateCountry.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "updateCountryRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SetupService.createCity.pending, (state) => {
      state.status = "createCityPending";
    });
    builder.addCase(SetupService.createCity.fulfilled, (state, { payload }) => {
      state.status = "createCityResolved";
      state.error = "";
    });
    builder.addCase(
      SetupService.createCity.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createCityRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SetupService.updateCity.pending, (state) => {
      state.status = "updateCityPending";
    });
    builder.addCase(SetupService.updateCity.fulfilled, (state, { payload }) => {
      state.status = "updateCityResolved";
      state.error = "";
    });
    builder.addCase(
      SetupService.updateCity.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "updateCityRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SetupService.createSubscription.pending, (state) => {
      state.status = "createSubscriptionPending";
    });
    builder.addCase(
      SetupService.createSubscription.fulfilled,
      (state, { payload }) => {
        state.status = "createSubscriptionResolved";
        state.error = "";
      }
    );
    builder.addCase(
      SetupService.createSubscription.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createSubscriptionRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SetupService.updateSubscription.pending, (state) => {
      state.status = "updateSubscriptionPending";
    });
    builder.addCase(
      SetupService.updateSubscription.fulfilled,
      (state, { payload }) => {
        state.status = "updateSubscriptionResolved";
        state.error = "";
      }
    );
    builder.addCase(
      SetupService.updateSubscription.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "updateSubscriptionRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SetupService.createCategory.pending, (state) => {
      state.status = "createCategoryPending";
    });
    builder.addCase(
      SetupService.createCategory.fulfilled,
      (state, { payload }) => {
        state.status = "createCategoryResolved";
        state.error = "";
      }
    );
    builder.addCase(
      SetupService.createCategory.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createCategoryRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SetupService.updateCategory.pending, (state) => {
      state.status = "updateCategoryPending";
    });
    builder.addCase(
      SetupService.updateCategory.fulfilled,
      (state, { payload }) => {
        state.status = "updateCategoryResolved";
        state.error = "";
      }
    );
    builder.addCase(
      SetupService.updateCategory.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "updateCategoryRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SetupService.fetchCategories.pending, (state) => {
      state.status = "fetchCategoriesPending";
    });
    builder.addCase(
      SetupService.fetchCategories.fulfilled,
      (state, { payload }) => {
        state.setups.categories = Util.fill(payload);

        state.status = "fetchCategoriesResolved";

        state.error = "";
      }
    );
    builder.addCase(
      SetupService.fetchCategories.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "fetchCategoriesRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(SetupService.updateUserStatus.pending, (state) => {
      state.status = "updateUserStatusPending";

      state.error = "";
    });
    builder.addCase(
      SetupService.updateUserStatus.fulfilled,
      (state, { payload }) => {
        state.status = "updateUserStatusResolved";

        state.error = "";
      }
    );
    builder.addCase(
      SetupService.updateUserStatus.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "updateUserStatusRejected";
      }
    );

    builder.addCase(
      SetupService.fetchSubscriptions.fulfilled,
      (state, { payload }) => {
        state.setups.subscriptions = Util.fill(payload);

        state.error = "";
      }
    );

    builder.addCase(
      SetupService.fetchActiveSubscriptions.pending,
      (state, { payload }) => {
        state.status = "fetchActiveSubscriptionsPending";
      }
    );
    builder.addCase(
      SetupService.fetchActiveSubscriptions.fulfilled,
      (state, { payload }) => {
        state.setups.subscriptions = Util.fill(payload);

        state.status = "fetchActiveSubscriptionsResolved";

        state.error = "";
      }
    );

    builder.addCase(
      SetupService.fetchSubscriptionsForActivation.pending,
      (state, { payload }) => {
        state.status = "fetchSubscriptionsForActivationPending";
      }
    );
    builder.addCase(
      SetupService.fetchSubscriptionsForActivation.fulfilled,
      (state, { payload }) => {
        state.setups.userSubscriptions = Util.fill(payload);

        state.status = "fetchSubscriptionsForActivationResolved";

        state.error = "";
      }
    );

    builder.addCase(SetupService.activateUserSubscription.pending, (state) => {
      state.status = "activateUserSubscriptionPending";

      state.error = "";
    });
    builder.addCase(
      SetupService.activateUserSubscription.fulfilled,
      (state, { payload }) => {
        state.status = "activateUserSubscriptionResolved";

        state.error = "";
      }
    );
    builder.addCase(
      SetupService.activateUserSubscription.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "activateUserSubscriptionRejected";
      }
    );

  },
});

export const { clearSetupError, resetSetupState, clearSetupStatus } =
  setupSlice.actions;

export default setupSlice.reducer;
