import { Elements } from "@stripe/react-stripe-js";
import getStripe from "../../utils/stripe";
import { CheckoutForm } from "../Components";

export const CardPaymentPage = () => {
  return (
    <>
      <div className="centralForm-lg">
        <div className="row">
          <div className="col-lg-12">
            <h3>Payment</h3>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Elements stripe={getStripe()}>
              <CheckoutForm />
            </Elements>
          </div>
        </div>
      </div>
    </>
  );
};
