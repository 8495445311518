export enum QuotationStatus {
  ALL = 0,
  SENT = 1,
  REVISED = 2,
  REJECTED = 3,
  APPROVED = 4,
  EXPIRED = 5,
  SHORTLISTED = 6,
  ORDERED = 7,
  FULFILLED = 8,
  INVOICED = 9,
  COMPLETED = 10,
}
