import { Col, Form, notification, Row, Spin, Table } from "antd";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import * as SetupService from "../../../store/setups/setups.actions";
import { AreaCoverage, City } from "../../../types";
import { useEffect, useState } from "react";
import {
  clearSetupError,
  clearSetupStatus,
} from "../../../store/setups/setups.slice";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as routes from "../../../constants/routes";
import useEffectOnce from "../../../hooks/useEffectOnce";
import * as ListService from "../../../store/lists/list.action";
import { PreLoader } from "../../Components";

const { Item } = Form;

const gridColumns = [
  {
    title: "City",
    dataIndex: "city",
    key: "City",
    render: (text: string, record: any) => (
      <Link
        to={`/admin/area/edit/${record.id}`}
        state={{ areaCoverage: record }}
      >
        {text}
      </Link>
    ),
  },
  {
    title: "Longitude",
    dataIndex: "latitude",
    key: "Longitude",
  },
  {
    title: "Latitude",
    dataIndex: "longitude",
    key: "Latitude",
  },
];

export const CoverageAreaSetupPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const location = useLocation();

  // const [coverageArea, setConverageArea] = useState<AreaCoverage>();

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const onAreaCoverageFormSubmit = (values: AreaCoverage) => {
    if (location.pathname.includes("/area/edit/")) {
      dispatch(SetupService.updateCoverageArea(values));
    } else {
      dispatch(SetupService.createCoverageArea(values));
    }
  };

  const onAreaCoverageFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  const {
    status: listStatus,
    error: listError,
    entities,
  } = useAppSelector((state) => state.list);

  const { status: setupStatus, error: setupError } = useAppSelector(
    (state) => state.setup
  );

  useEffect(() => {
    if (String(id).length > 0) {
      let selectedAreaCoverage: AreaCoverage | undefined =
        entities.areaCoverages?.find((a) => String(a.id) === String(id));

      if (selectedAreaCoverage) {
        // setConverageArea(selectedAreaCoverage);

        form.setFieldsValue({
          Id: selectedAreaCoverage.id,
          City: selectedAreaCoverage.city,
          Longitude: selectedAreaCoverage.longitude,
          Latitude: selectedAreaCoverage.latitude,
        });
      }
    }
  }, [id]);

  useEffect(() => {
    if (setupStatus === "createAreaCoverageResolved") {
      displaySuccessNotification("Area coverage has been created");

      dispatch(ListService.fetchAreaCoverage());
    }
    if (setupStatus === "updateAreaCoverageResolved") {
      displaySuccessNotification("Area coverage has been updated");

      dispatch(ListService.fetchAreaCoverage());
    }

    if (setupStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
    // dispatch(clearSetupStatus());
  }, [dispatch, setupStatus]);

  const navigateAndNew = () => {
    form.setFieldsValue({
      Id: 0,
      City: "",
      Longitude: "",
      Latitude: "",
    });

    navigate(routes.ADMIN_DASHBOARD);
  };

  useEffectOnce(() => {
    dispatch(ListService.fetchAreaCoverage());
  });

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onAreaCoverageFormSubmit}
        onFinishFailed={onAreaCoverageFinishFailed}
        size="large"
      >
        <div className="row">
          <div className="col-lg-12">
            <h3>Area Coverage Setup</h3>
            <br />
            <p>
              Please Fill all the field to complete information to setup areas
              of coverage.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <Row gutter={16}>
              <Col span={12}>
                <Table
                  columns={gridColumns}
                  dataSource={entities.areaCoverages}
                  pagination={{
                    total: entities.areaCoverages?.length,
                    pageSize: 10,
                  }}
                />
              </Col>
              <Col span={12}>
                <div className="row">
                  <div className="col-lg-12">
                    <Item name="Id" hidden={true}>
                      <input />
                    </Item>
                    <Item
                      name="City"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a City for the area coverage!",
                        },
                      ]}
                    >
                      <select className="local-form input-reg">
                        <option value="-1">Select City</option>
                        {entities.cities?.map((city, index) => (
                          <option value={city.title} key={index}>
                            {city.title}
                          </option>
                        ))}
                      </select>
                    </Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <Item
                      name="Longitude"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please enter Longitude threshold for the area coverage!",
                        },
                      ]}
                    >
                      <input
                        maxLength={50}
                        className="local-form input-reg"
                        placeholder="Longitude coverage"
                      ></input>
                    </Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <Item
                      name="Latitude"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please enter Latitude threshold for the area coverage!",
                        },
                      ]}
                    >
                      <input
                        maxLength={50}
                        className="local-form input-reg"
                        placeholder="Latitude coverage"
                      ></input>
                    </Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3">
                    <button
                      type="button"
                      className="butn butn-block"
                      onClick={navigateAndNew}
                    >
                      New
                    </button>
                  </div>
                  <div className="col-lg-3">
                    <button
                      type="submit"
                      className="butn butn-block"
                      disabled={setupStatus === "createAreaCoveragePending"}
                    >
                      <span id="button-text">
                        {setupStatus === "createAreaCoveragePending" ? (
                          <Spin size="small" />
                        ) : (
                          "Save"
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-3"></div>
                  <div className="col-lg-3"></div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </>
  );
};
