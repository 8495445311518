import React, { useEffect, useState } from "react";
import { Form, Layout, Select, Space, Spin } from "antd";
import { SingleUpload, GoogleLocation } from "../../Components";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { CompanyProfilePage } from "../Company";
import { CustomerProfilePage } from "../Customer";
import { useParams } from "react-router-dom";
import * as routes from "../../../constants/routes";
import { UserType } from "../../../enums";
import config from "../../../config";
import * as ListService from "../../../store/lists/list.action";
import { City } from "../../../types";

const { Item } = Form;
const { Option } = Select;

const prefixSelectorPhone = (
  <Item name="prefixPhone" noStyle>
    <Select style={{ width: 70 }}>
      <Option value="86">+86</Option>
      <Option value="87">+87</Option>
    </Select>
  </Item>
);
const prefixSelectorMobile = (
  <Item name="prefixMobile" noStyle>
    <Select style={{ width: 70 }}>
      <Option value="86">+86</Option>
      <Option value="87">+87</Option>
    </Select>
  </Item>
);

interface ProfilePageProps {
  formRef: any;
  isNested: boolean;
  setProfileLocation?: (location: string) => void;
}

export const ProfilePage = (props: ProfilePageProps) => {
  const dispatch = useAppDispatch();

  const { status, error, user } = useAppSelector((state) => state.auth);

  const { status: listStatus, entities } = useAppSelector(
    (state) => state.list
  );

  const [cities, setCities] = useState<Array<City>>([]);

  const { id } = useParams();

  // const { isShown, toggle } = useModal();

  // const [{ lat, lng, pinpoint }, setPinLocation] = useState<any>({
  //   lat: "",
  //   lng: "",
  //   pinpoint: "",
  // });

  const [{ countryId, cityId }, setCountryCityData] = useState({
    countryId: Number(-1),
    cityId: Number(-1),
  });

  const handleCountryChange = (event: any) => {
    setCountryCityData((data) => ({
      cityId: Number(-1),
      countryId: Number(event.target.value),
      // event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text,
    }));
  };
  const handleCountryUseEffect = (eventValue: any) => {
    setCountryCityData((data) => ({
      cityId: Number(-1),
      countryId: Number(eventValue),
      // event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text,
    }));
  };

  useEffect(() => {
    dispatch(ListService.fetchCities(countryId));
  }, [dispatch, countryId]);

  useEffect(() => {
    if (listStatus === "fetchCitiesSuccess") {
      setCities(entities.cities!);
    }
  }, [entities.cities, listStatus]);

  // const notifyLocationChange = (location: any) => {
  //   setPinLocation(location);
  //   if (props.setProfileLocation) {
  //     props.setProfileLocation(location);
  //   }
  // };

  // useEffect(() => {
  //   props.formRef.setFieldsValue({ Address: pinpoint });
  //   props.formRef.setFieldsValue({ Longitude: lng });
  //   props.formRef.setFieldsValue({ Latitude: lat });
  // }, [pinpoint]);

  // useEffect(() => {
  //   dispatch(loadCurrentUser());
  // }, [dispatch]);

  useEffect(() => {
    if (user) {
      props.formRef.setFieldsValue({
        Image: user?.image,
        FullName: user?.fullName,
        PhoneNumber: user?.phoneNumber,
        MobileNumber: user?.mobileNumber,
        ContactPersonFirstName: user?.contactPersonFirstName,
        ContactPersonLastName: user?.contactPersonLastName,
        ContactPersonJobTitle: user?.contactPersonJobTitle,
        Address: user?.address,
        Longitude: user?.longitude,
        Latitude: user?.latitude,
        PostalCode: user?.postalCode,
        CountryId: user?.country?.id!,
        CityId: user?.city?.id!,
      });

      handleCountryUseEffect(user?.country?.id!);
    }
  }, [user]);

  const imageSelectCallback = (file: any) => {
    if (file) props.formRef.setFieldsValue({ Image: file.response.result });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="row formRow">
            <div className="col-lg-3"></div>
            <div className="col-lg-6" style={{ textAlign: "center" }}>
              <Item hidden={true} name="Image">
                <input />
              </Item>
              <Space>
                <img
                  alt="Profile"
                  style={{ width: "104px", height: "104px" }}
                  src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${user?.image}&type=user`}
                />
                <SingleUpload
                  buttonText={"Profile Image"}
                  onImageSelect={imageSelectCallback}
                  type="user"
                />
              </Space>
            </div>
            <div className="col-lg-3"></div>
          </div>

          <div className="row formRow">
            <div className="col-lg-12">
              <Item
                name="FullName"
                rules={[
                  {
                    required: true,
                    message: "Please enter what defines your Full name!",
                  },
                ]}
              >
                <input
                  maxLength={30}
                  className="local-form input-reg"
                  placeholder="Full Name"
                />
              </Item>
            </div>
          </div>

          <div className="row formRow">
            <div className="col-lg-6">
              <Item
                name="PhoneNumber"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Please enter what defines your Phone number!",
                  },
                ]}
              >
                <input
                  maxLength={15}
                  className="local-form input-reg"
                  // addonBefore={prefixSelectorPhone}
                  style={{ width: "100%" }}
                  placeholder="Phone Number"
                />
              </Item>
            </div>
            <div className="col-lg-6">
              <Item
                name="MobileNumber"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Please enter what defines your Mobile number!",
                  },
                ]}
              >
                <input
                  maxLength={15}
                  className="local-form input-reg"
                  // addonBefore={prefixSelectorMobile}
                  style={{ width: "100%" }}
                  placeholder="Mobile Number"
                />
              </Item>
            </div>
          </div>

          <div className="row formRow">
            <div className="col-lg-6">
              <Item
                name="ContactPersonFirstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter what defines your First name!",
                  },
                ]}
              >
                <input
                  maxLength={30}
                  className="local-form input-reg"
                  placeholder="Contact person first name"
                />
              </Item>
            </div>
            <div className="col-lg-6">
              <Item
                name="ContactPersonLastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter what defines your Last name!",
                  },
                ]}
              >
                <input
                  maxLength={30}
                  className="local-form input-reg"
                  placeholder="Contact person last name"
                />
              </Item>
            </div>
          </div>

          <div className="row formRow">
            <div className="col-lg-12">
              <Item
                name="ContactPersonJobTitle"
                rules={[
                  {
                    required: true,
                    message: "Please enter what defines your Job title!",
                  },
                ]}
              >
                <input
                  maxLength={30}
                  className="local-form input-reg"
                  placeholder="Job title"
                />
              </Item>
            </div>
          </div>

          <div className="row formRow">
            <div className="col-lg-12">
              <GoogleLocation formRef={props.formRef} />
            </div>
          </div>

          <div className="row formRow">
            <div className="col-lg-6">
              <Item
                name="CountryId"
                rules={[
                  {
                    required: true,
                    message: "Please enter a Country you are from!",
                  },
                ]}
              >
                <select
                  className="local-form input-reg"
                  onChange={handleCountryChange}
                >
                  <option value="0">Select Country</option>
                  {entities.countries?.map((country, index) => (
                    <option
                      // selected={user.profile?.country?.id === country.id}
                      value={country.id}
                      key={index}
                    >
                      {country.title}
                    </option>
                  ))}
                </select>
              </Item>
            </div>
            <div className="col-lg-6">
              <Item
                name="CityId"
                rules={[
                  {
                    required: true,
                    message: "Please enter a City you are from!",
                  },
                ]}
              >
                <select className="local-form input-reg">
                  <option value="-1">Select City</option>
                  {cities
                    // ?.filter(
                    //   (city) => countryId < -1 || city.countryId === countryId
                    // )
                    ?.map((city, index) => (
                      <option
                        // selected={user.profile?.city?.id === city.id}
                        value={city.id}
                        key={index}
                      >
                        {city.title}
                      </option>
                    ))}
                </select>
              </Item>
            </div>
          </div>

          {/*<div className="row formRow">
            <div className="col-lg-12">
              <Item
                name="PostalCode"
                className="local-form input-reg"
                rules={[
                  {
                    required: true,
                    message: "Please input your Postal Code!",
                  },
                ]}
              >
                <input
                  className="local-form input-reg"
                  placeholder="Postal Code"
                />
              </Item>
            </div>
          </div>
*/}
          {props.isNested === false && (
            <div className="row formRow">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <button
                  type="submit"
                  className="butn butn-block"
                  disabled={status === "userProfilePending"}
                >
                  <span id="button-text">
                    {status === "userProfilePending" ? (
                      <Spin size="small" />
                    ) : (
                      "Update Profile"
                    )}
                  </span>
                </button>
              </div>
              <div className="col-lg-3"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const CustomProfilePage = () => {
  const { user } = useAppSelector((state) => state.auth);

  let RenderProfilePage = undefined;

  if (user?.userType === UserType.CUSTOMER) {
    RenderProfilePage = <CustomerProfilePage />;
  } else if (user?.userType === UserType.SUPPLIER) {
    RenderProfilePage = <CompanyProfilePage />;
  } else {
    RenderProfilePage = <></>;
  }

  return <>{RenderProfilePage}</>;
};
