import React, { useState } from "react";
import { Form, Input, Layout, notification, Radio, Select, Spin } from "antd";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CreateAccountData, LoginData } from "../../types";
import * as AuthService from "../../store/auth/auth.actions";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../utils/notifications";
import { clearAuthError, clearAuthStatus } from "../../store/auth/auth.slice";
import { UserType } from "../../enums";
import { CheckoutForm, GoogleLocation, PreLoader } from "../Components";
import * as routes from "../../constants/routes";
import { store } from "../../store";
import * as ListService from "../../store/lists/list.action";
import * as SetupService from "../../store/setups/setups.actions";
import { goOnline } from "firebase/database";
import * as FirestoreService from "../../view/Components/ChatStore/firebase/fireStoreService";
import { useFireBase } from "../Components/ChatStore/firebase/config";
import getStripe from "../../utils/stripe";
import { Elements } from "@stripe/react-stripe-js";
import activateImg from "../assets/img/account-verification.png";
import { SubscriptionPage } from "../Account/Subscription";

const { Item } = Form;
const { Password } = Input;

export const LoginPage = () => {
  const {
    error,
    status: authStatus,
    user,
  } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auth, fs, db] = useFireBase();

  const [hidePaymentButton, setHidePaymentButton] = useState<boolean>(true);
  // const [showPaymentCard, setShowPaymentCard] = useState<boolean>(false);
  // const [disableLoginButton, setDisableLoginButton] = useState<boolean>(false);

  const onFormSubmit = ({ UserName, Password }: LoginData) => {
    dispatch(AuthService.logIn({ UserName, Password }));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useEffect(() => {
    if (store.getState().auth.isAuthenticated) {
      if (user) {
        if (user.userType === UserType.SUPPLIER) {
          navigate(routes.SUPP_DASHBOARD);
        } else if (user.userType === UserType.CUSTOMER) {
          navigate(routes.COMP_DASHBOARD);
        } else if (user.userType === UserType.ADMIN) {
          navigate(routes.ADMIN_DASHBOARD);
        }
      }
    }
  }, [navigate, user]);

  useEffect(() => {
    if (error) {
      displayErrorMessage(error);

      dispatch(clearAuthError());
    }

    // if (authStatus === "initPaymentIntentRejected") {
    //   setHidePaymentButton(false);

    //   dispatch(clearAuthStatus());
    //   // setShowPaymentCard(false);
    // }
  }, [dispatch, error]);

  useEffect(() => {
    if (authStatus === "registrationFeeNotPaid") {
      sessionStorage.setItem("user.auth", JSON.stringify(user));

      displayErrorMessage("Please pay your registration fee before signing in");

      setHidePaymentButton(false);

      dispatch(clearAuthStatus());
    }

    // if (authStatus === "initPaymentIntentResolved") {
    //   // setShowPaymentCard(true);

    //   navigate(routes.TO_PAYMENT_PAGE);
    // }

    if (authStatus === "loggedIn") {
      sessionStorage.setItem("user.auth", JSON.stringify(user));

      dispatch(AuthService.loadCurrentUser());
      dispatch(ListService.fetchCountries());
      dispatch(ListService.fetchCities(Number(0)));
      dispatch(ListService.fetchAreaCoverage());
      dispatch(SetupService.fetchCategories());

      // if (user.userType === UserType.SUPPLIER) {
      //   navigate(routes.SUPP_DASHBOARD);
      // } else if (user.userType === UserType.CUSTOMER) {
      //   navigate(routes.COMP_DASHBOARD);
      // }

      const manageUserPresence = async () => {
        const userId =
          user.userType === UserType.CUSTOMER
            ? user?.fullName
            : user.company?.companyName;

        await goOnline(db);

        await FirestoreService.manageUserPresence(auth, fs, db, String(userId));
      };

      manageUserPresence();

      displaySuccessNotification("Logged-In successfully");
    }

    if (authStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [dispatch, authStatus]);

  // const initPaymentIntent = () => {
  //   // setDisableLoginButton(true);

  //   // setHidePaymentButton(true);

  //   dispatch(AuthService.initPaymentIntent());
  // };

  // const confirmPaymentIntent = () => {
  //   dispatch(AuthService.confirmPaymentIntent());
  // };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <h3>Log In</h3>
          <br />
          <span>
            Need an Account?{" "}
            <Link to={routes.USER_SIGNUP} className="linkColored">
              SignUp Now
            </Link>
            <br />
            or View our {" "}
            <Link to={routes.USER_SUBSCRIPTION} className="linkColored">
              Subscription plans
            </Link>
          </span>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Form onFinish={onFormSubmit} onFinishFailed={onFinishFailed}>
        <div className="row">
          <div className="col-lg-12">
            <div className="row formRow">
              <div className="col-lg-12">
                <Item
                  name="UserName"
                  className="local-form input-reg"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    maxLength={50}
                    className="local-form input-reg"
                    placeholder="Email Address"
                  />
                </Item>
              </div>
            </div>
            <div className="row formRow">
              <div className="col-lg-12">
                <Item
                  name="Password"
                  className="local-form input-reg"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      min: 4,
                      message: "Password must be minimum 4 characters long.",
                    },
                  ]}
                  hasFeedback
                >
                  <Password
                    maxLength={30}
                    className="local-form input-reg"
                    placeholder="Password"
                  />
                </Item>
              </div>
            </div>
            <div className="row formRow">
              <div className="col-lg-6">
                <div className="checkbox">
                  <label>
                    <input type="checkbox" /> Remember me
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <Link
                  to={routes.FORGET_PWD}
                  className="linkColored pull-right"
                  style={{ marginTop: "8px" }}
                >
                  Lost Password
                </Link>
              </div>
            </div>
            <div className="row formRow">
              <div className="col-lg-12">
                <button
                  type="submit"
                  className="butn butn-block"
                  disabled={authStatus === "loggedInPending"}
                >
                  <span id="button-text">
                    {authStatus === "loggedInPending" ? (
                      <Spin size="small" />
                    ) : (
                      "Login"
                    )}
                  </span>
                </button>
              </div>
            </div>
            <div className="row formRow">
              <div className="col-lg-12">
                {/* <StripeCheckoutButton
                          price={totalPrice}
                          whenToken={confirmPaymentIntent}
                        >
                          <button
                            type="button"
                            className="butn butn-block"
                            onClick={initPaymentIntent}
                            disabled={status === "userSignedUpPending"}
                            hidden={showPaymentButton}
                          >
                            Pay now
                          </button>
                        </StripeCheckoutButton> */}
                <button
                  type="button"
                  className="butn butn-block"
                  onClick={() => navigate(routes.USER_SUBSCRIPTION)}
                  // disabled={authStatus === "initPaymentIntentPending"}
                  hidden={hidePaymentButton}
                >
                  Pay now
                </button>
                {/* {showPaymentCard && (
                          <Elements stripe={getStripe()}>
                            <CheckoutForm />
                          </Elements>
                        )} */}
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export const SignupPage = () => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const { error, status } = useAppSelector((state) => state.auth);

  const [userType, setUserType] = useState(0);
  // const [formValues, setFormValues] = useState<CreateAccountData>({});

  const navigate = useNavigate();

  const onFormSubmit = (formData: CreateAccountData) => {
    // dispatch(AuthService.initPaymentIntent());

    // setFormValues(formData);

    dispatch(AuthService.createAccount(formData));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useEffect(() => {
    if (error) {
      displayErrorMessage(error);

      dispatch(clearAuthError());
    }
  }, [error]);

  useEffect(() => {
    if (status === "userSignedUp" && !error) {
      displaySuccessNotification(
        "Account has been created, Please check your email for Activation."
      );

      navigate(routes.LOGIN);

      dispatch(clearAuthStatus());
    }

    // if (status === "confirmPaymentIntentResolved") {
    //   dispatch(AuthService.createAccount(formValues));
    // }

    if (status.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    }
  }, [dispatch, status]);
  
  return (
    <>
      <Form form={form} onFinish={onFormSubmit} onFinishFailed={onFinishFailed}>
        <div className="row">
          <div className="col-lg-12">
            <h3>Join BuySing</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="row formRow">
              <div className="col-lg-12">
                <Item
                  name="UserType"
                  rules={[
                    {
                      required: true,
                      message: "Please pick account type!",
                    },
                  ]}
                >
                  <Radio.Group onChange={(e) => setUserType(e.target.value)}>
                    <Radio.Button value={UserType.CUSTOMER}>HOTEL</Radio.Button>
                    <Radio.Button value={UserType.SUPPLIER}>
                      SUPPLIER
                    </Radio.Button>
                  </Radio.Group>
                </Item>
              </div>
            </div>

            <div className="row formRow">
              <div className="col-lg-12">
                {userType === UserType.SUPPLIER ? (
                  <Item
                    name="CompanyName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your company name",
                      },
                      {
                        min: 4,
                        message: "Company name must be min 4 characters.",
                      },
                    ]}
                  >
                    <Input
                      maxLength={30}
                      className="local-form input-reg"
                      placeholder="Company Name"
                    />
                  </Item>
                ) : (
                  <Item
                    name="FullName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name",
                      },
                      {
                        min: 4,
                        message: "Name must be min 4 characters.",
                      },
                    ]}
                  >
                    <Input
                      maxLength={30}
                      className="local-form input-reg"
                      placeholder="Full Name"
                    />
                  </Item>
                )}
              </div>
            </div>
            <div className="row formRow">
              <div className="col-lg-12">
                <Item
                  name="UserName"
                  className="local-form input-reg"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    maxLength={50}
                    className="local-form input-reg"
                    placeholder="Email Address"
                  />
                </Item>
              </div>
            </div>
            <div className="row formRow">
              <div className="col-lg-12">
                <Item
                  name="Password"
                  className="local-form input-reg"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      pattern:
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
                      message:
                        "Password must be between 8 to 15 characters which should contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character",
                    },
                  ]}
                  hasFeedback
                >
                  <Password
                    maxLength={30}
                    className="local-form input-reg"
                    placeholder="Password"
                  />
                </Item>
              </div>
            </div>

            <div className="row formRow">
              <div className="col-lg-12">
                <Item
                  name="Confirm"
                  dependencies={["Password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_rule, value) {
                        if (!value || getFieldValue("Password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                >
                  <input
                    maxLength={30}
                    type="password"
                    className="local-form input-reg"
                    placeholder="Confirm Password"
                    autoComplete="off"
                  />
                </Item>
              </div>
            </div>

            {userType === UserType.SUPPLIER && (
              <div className="row formRow">
                <div className="col-lg-12">
                  <GoogleLocation formRef={form} />
                </div>
              </div>
            )}

            {/* <div className="row formRow">
                    <div className="col-lg-12" style={{ textAlign: "center" }}>
                      {userType === UserType.SUPPLIER ? (
                        <StripeCheckoutButton
                          price={totalPrice}
                          whenToken={confirmPaymentIntent}
                        >
                          <button
                            type="button"
                            className="butn butn-block"
                            // onClick={initPaymentIntent}
                            disabled={status === "userSignedUpPending"}
                          >
                            Sign Up
                          </button>
                        </StripeCheckoutButton>
                      ) : (
                        <button
                          type="submit"
                          className="butn butn-block"
                          disabled={status === "userSignedUpPending"}
                        >
                          Sign Up
                        </button>
                      )}
                    </div>
                  </div> */}

            <div className="row formRow">
              <div className="col-lg-12">
                <button
                  type="submit"
                  className="butn butn-block"
                  disabled={status === "loggedInPending"}
                >
                  <span id="button-text">
                    {status === "loggedInPending" ? (
                      <Spin size="small" />
                    ) : (
                      "Sign Up"
                    )}
                  </span>
                </button>
              </div>
            </div>

            <div className="row formRow">
              <div className="col-lg-12" style={{ textAlign: "center" }}>
                <span>
                  <br />
                  Already have an account?{" "}
                  <Link to="#" className="linkColored">
                    Log In Now
                  </Link>
                  <br />
                  <br />
                  By clicking Sign Up,
                  <br />
                  you agree to our User Agreement and Privacy Policy.
                </span>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export const AccountActivatePage = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { status, error } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const token = searchParams.get("token")?.replaceAll(" ", "+");
    const email = searchParams.get("email");

    if (token && email) {
      dispatch(AuthService.activateAccount({ token, email }));
    }
  }, [dispatch]);

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    if (status === "activateAccountRejected") {
      displayErrorMessage(error);

      setErrorMessage(true);

      dispatch(clearAuthStatus());
    }

    if (status === "activateAccountResolved") {
      setSuccessMessage(true);

      dispatch(clearAuthStatus());
    }
  }, [dispatch, status]);

  return (
    <>
      {successMessage ? (
        <>
          <br />
          <br />
          <br />
          <img src={activateImg} />
          <br />
          <br />
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <button
                type="button"
                className="button-block"
                onClick={() => navigate(routes.LOGIN, { replace: true })}
              >
                Sign In
              </button>
              {/* <LoginPage /> */}
            </div>
            <div className="col-lg-3"></div>
          </div>
        </>
      ) : (
        <>
          {errorMessage ? (
            <>
              <br />
              <br />
              <br />
              <br />
              <br />
              <h3>Error has been occured, Contact help desk.</h3>
              <br />
              <br />
              <br />
              <br />
              <br />
            </>
          ) : (
            <></>
          )}
          {errorMessage ? console.log(error) : ""}
        </>
      )}
    </>
  );
};
