import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import prodlogo from "../../../assets/supp-logo.png";
import * as ListService from "../../../../store/lists/list.action";
import * as routes from "../../../../constants/routes";

export const CategoryProductPage = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const { entities } = useAppSelector((state) => state.list);

  useEffect(() => {
    dispatch(ListService.fetchCategoryProducts(String(id)));
  }, [id]);

  return (
    <>
      <section className="supOverall">
        <div className="container-fluid centraSpacing">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabBar">
                <button className="bar-item bar-item-active">Products</button>
                <button className="bar-item">Supplier</button>
              </div>
              <div className="tabContent">
                <div id="Overview" className="tcontent">
                  <section className="datafilters">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="filtercontroler">
                            <div className="row">
                              <div className="col-lg-3 col-md-3">
                                <span className="bigLabel">Products</span>
                              </div>
                              <div className="col-lg-3 col-md-3"></div>
                              <div className="col-lg-3 col-md-3">
                                <select
                                  name=""
                                  className="local-form input-reg"
                                >
                                  <option value="0">Filter By letter</option>
                                </select>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <div className="searchGroupMini">
                                  <input
                                    maxLength={30}
                                    type="text"
                                    className="sinpmini"
                                    placeholder="Search by Keyword"
                                  />
                                  <button type="button" className="exesermini">
                                    <span className="glyphicon glyphicon-search"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="container-fluid">
                    <div className="row">
                      {entities.productCategories?.map((product, index) => (
                        <div
                          className="col-lg-3 col-md-6 col-sm-6"
                          key={index.toString()}
                        >
                          <div className="profileCard">
                            <div className="imgDisplay">
                              <img src={prodlogo} alt="Profile Logo" />
                            </div>
                            <div className="textDesc">
                              <p>
                                <b>{product.categoryName}</b>
                              </p>
                            </div>
                            <div className="controlArea">
                              <Link
                                to={routes.SUPPLIER_STORE}
                                state={{ product: product }}
                                className="button-block-outlined"
                              >
                                View Product
                              </Link>
                              <Link
                                className="button-block"
                                to={`/inquiry/product`}
                                state={{ product: product }}
                              >
                                Send Inquiry
                              </Link>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div
                  id="Detail"
                  className="tcontent"
                  style={{ display: "none" }}
                >
                  <section className="datafilters">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="filtercontroler">
                            <div className="row">
                              <div className="col-lg-3 col-md-3">
                                <span className="bigLabel">Suppliers</span>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <select
                                  name=""
                                  className="local-form input-reg"
                                >
                                  <option value="0">Filter by Category</option>
                                </select>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <select
                                  name=""
                                  className="local-form input-reg"
                                >
                                  <option value="0">Supply to...</option>
                                </select>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <div className="searchGroupMini">
                                  <input
                                    maxLength={30}
                                    type="text"
                                    className="sinpmini"
                                    placeholder="Search By Keyword"
                                  />
                                  <button type="button" className="exesermini">
                                    <span className="glyphicon glyphicon-search"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="profileCard">
                          <div className="imgDisplay">
                            <img
                              src="https://mir-s3-cdn-cf.behance.net/projects/404/e0881184053161.Y3JvcCwxMzY5LDEwNzEsMTU1LDU3.png"
                              alt="Profile Logo"
                            />
                          </div>
                          <div className="textDesc">
                            <p>
                              <b>
                                THE SUPPLIER - Some Introductory description
                                about the supplier services/ product here
                              </b>
                            </p>
                          </div>
                          <div className="controlArea">
                            <a
                              href="View-suppliers.html"
                              className="button-block-outlined"
                            >
                              View Supplier
                            </a>
                            <button type="button" className="button-block">
                              Send Enquiry
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="profileCard">
                          <div className="imgDisplay">
                            <img
                              src="https://mir-s3-cdn-cf.behance.net/projects/404/e0881184053161.Y3JvcCwxMzY5LDEwNzEsMTU1LDU3.png"
                              alt="Profile Logo"
                            />
                          </div>
                          <div className="textDesc">
                            <p>
                              <b>
                                THE SUPPLIER - Some Introductory description
                                about the supplier services/ product here
                              </b>
                            </p>
                          </div>
                          <div className="controlArea">
                            <a
                              href="View-suppliers.html"
                              className="button-block-outlined"
                            >
                              View Supplier
                            </a>
                            <button type="button" className="button-block">
                              Send Enquiry
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="profileCard">
                          <div className="imgDisplay">
                            <img
                              src="https://mir-s3-cdn-cf.behance.net/projects/404/e0881184053161.Y3JvcCwxMzY5LDEwNzEsMTU1LDU3.png"
                              alt="Profile Logo"
                            />
                          </div>
                          <div className="textDesc">
                            <p>
                              <b>
                                THE SUPPLIER - Some Introductory description
                                about the supplier services/ product here
                              </b>
                            </p>
                          </div>
                          <div className="controlArea">
                            <a
                              href="View-suppliers.html"
                              className="button-block-outlined"
                            >
                              View Supplier
                            </a>
                            <button type="button" className="button-block">
                              Send Enquiry
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="profileCard">
                          <div className="imgDisplay">
                            <img
                              src="https://mir-s3-cdn-cf.behance.net/projects/404/e0881184053161.Y3JvcCwxMzY5LDEwNzEsMTU1LDU3.png"
                              alt="Profile Logo"
                            />
                          </div>
                          <div className="textDesc">
                            <p>
                              <b>
                                THE SUPPLIER - Some Introductory description
                                about the supplier services/ product here
                              </b>
                            </p>
                          </div>
                          <div className="controlArea">
                            <a
                              href="View-suppliers.html"
                              className="button-block-outlined"
                            >
                              View Supplier
                            </a>
                            <button type="button" className="button-block">
                              Send Enquiry
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="profileCard">
                          <div className="imgDisplay">
                            <img
                              src="https://mir-s3-cdn-cf.behance.net/projects/404/e0881184053161.Y3JvcCwxMzY5LDEwNzEsMTU1LDU3.png"
                              alt="Profile Logo"
                            />
                          </div>
                          <div className="textDesc">
                            <p>
                              <b>
                                THE SUPPLIER - Some Introductory description
                                about the supplier services/ product here
                              </b>
                            </p>
                          </div>
                          <div className="controlArea">
                            <a
                              href="View-suppliers.html"
                              className="button-block-outlined"
                            >
                              View Supplier
                            </a>
                            <button type="button" className="button-block">
                              Send Enquiry
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="profileCard">
                          <div className="imgDisplay">
                            <img
                              src="https://mir-s3-cdn-cf.behance.net/projects/404/e0881184053161.Y3JvcCwxMzY5LDEwNzEsMTU1LDU3.png"
                              alt="Profile Logo"
                            />
                          </div>
                          <div className="textDesc">
                            <p>
                              <b>
                                THE SUPPLIER - Some Introductory description
                                about the supplier services/ product here
                              </b>
                            </p>
                          </div>
                          <div className="controlArea">
                            <a
                              href="View-suppliers.html"
                              className="button-block-outlined"
                            >
                              View Supplier
                            </a>
                            <button type="button" className="button-block">
                              Send Enquiry
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="profileCard">
                          <div className="imgDisplay">
                            <img
                              src="https://mir-s3-cdn-cf.behance.net/projects/404/e0881184053161.Y3JvcCwxMzY5LDEwNzEsMTU1LDU3.png"
                              alt="Profile Logo"
                            />
                          </div>
                          <div className="textDesc">
                            <p>
                              <b>
                                THE SUPPLIER - Some Introductory description
                                about the supplier services/ product here
                              </b>
                            </p>
                          </div>
                          <div className="controlArea">
                            <a
                              href="View-suppliers.html"
                              className="button-block-outlined"
                            >
                              View Supplier
                            </a>
                            <button type="button" className="button-block">
                              Send Enquiry
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="profileCard">
                          <div className="imgDisplay">
                            <img
                              src="https://mir-s3-cdn-cf.behance.net/projects/404/e0881184053161.Y3JvcCwxMzY5LDEwNzEsMTU1LDU3.png"
                              alt="Profile Logo"
                            />
                          </div>
                          <div className="textDesc">
                            <p>
                              <b>
                                THE SUPPLIER - Some Introductory description
                                about the supplier services/ product here
                              </b>
                            </p>
                          </div>
                          <div className="controlArea">
                            <a
                              href="View-suppliers.html"
                              className="button-block-outlined"
                            >
                              View Supplier
                            </a>
                            <button type="button" className="button-block">
                              Send Enquiry
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4">&nbsp;</div>
                      <div className="col-lg-4">
                        <br />
                        <br />
                        <button className="button-block">
                          Send Enquiry to all Suppliers
                        </button>
                        <br />
                        <br />
                      </div>
                      <div className="col-lg-4">&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
