import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "../../types";
import * as AuthService from "./auth.actions";
import * as Util from "../../utils/helper";
import { UserType } from "../../enums";

export const initialState: AuthState = Object.freeze({
  isAuthenticated: false,
  error: "",
  status: "idle",
  user: {},
  userIsOnline: "offline",
  paymentId: "",
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuthError: (state) => {
      state.error = "";
    },
    clearAuthStatus: (state) => {
      state.status = "idle";
    },
    resetAuthState: (state) => {
      state = {
        isAuthenticated: false,
        error: "",
        status: "idle",
        user: {},
        userIsOnline: "offline",
        paymentId: "",
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(AuthService.loadCurrentUser.pending, (state) => {
      state.status = "loadCurrentUserPending";
    });

    // handles auth/loadCurrentUser
    builder.addCase(
      AuthService.loadCurrentUser.fulfilled,
      (state, { payload }) => {
        state.status = "loadCurrentUserResolved";

        var userAuth = JSON.parse(sessionStorage.getItem("user.auth")!);

        state.user = payload;
        if (state.user.company) {
          state.user.company = payload.company;
        }

        state.user.id = userAuth.id;
        state.user.userType = userAuth.userType;
        state.user.userName = userAuth.userName;
        state.user.authToken = userAuth.authToken;
        state.user.refreshToken = userAuth.refreshToken;

        // payload.company?.companyMedia?.forEach((c) => {
        //   state.user?.company?.companyMedia?.push(c);
        // });
      }
    );

    builder.addCase(
      AuthService.loadCurrentUser.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "loadCurrentUserRejected";
      }
    );

    builder.addCase(AuthService.createAccount.pending, (state) => {
      state.status = "userSignedUpPending";
    });

    builder.addCase(
      AuthService.createAccount.fulfilled,
      (state, { payload }) => {
        state.status = "userSignedUp";
        // state.profile = payload;
        state.error = "";
      }
    );

    builder.addCase(
      AuthService.createAccount.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "userSignedUpRejected";
      }
    );

    builder.addCase(AuthService.logIn.pending, (state) => {
      state.status = "loggedInPending";
    });

    builder.addCase(AuthService.logIn.fulfilled, (state, { payload }) => {
      state.isAuthenticated = true;

      if (
        payload.userType === UserType.SUPPLIER &&
        (!payload.userSubscriptionLogId ||
          payload.userSubscriptionLogId?.length === 0)
      ) {
        state.status = "registrationFeeNotPaid";

        state.user.authToken = payload.authToken;
      } else {
        state.status = "loggedIn";

        state.user = payload;

        state.error = "";
      }
    });

    builder.addCase(
      AuthService.logIn.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.isAuthenticated = false;

        state.status = "loggedInRejected";
      }
    );

    builder.addCase(AuthService.logOut.pending, (state) => {
      // state.status = "loggedOut";
    });

    builder.addCase(AuthService.logOut.fulfilled, (state) => {
      state.isAuthenticated = false;
      state.status = "loggedOut";
      state.user = {};
    });

    builder.addCase(AuthService.logOut.rejected, (state) => {
      state.isAuthenticated = false;
      state.status = "loggedInRejected";
      state.user = {};
    });

    builder.addCase(AuthService.activateAccount.pending, (state) => {
      state.status = "activateAccountPending";
    });
    builder.addCase(
      AuthService.activateAccount.fulfilled,
      (state, { payload }) => {
        state.status = "activateAccountResolved";
        // state.profile = payload;
        state.error = "";
      }
    );
    builder.addCase(
      AuthService.activateAccount.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        // state.isAuthenticated = false;
        state.status = "activateAccountRejected";
      }
    );

    builder.addCase(AuthService.createProfile.pending, (state) => {
      state.status = "userProfilePending";
    });
    builder.addCase(
      AuthService.createProfile.fulfilled,
      (state, { payload }) => {
        // state.user = {}
        // state.user = payload;
        state.status = "userProfileUpdated";
        state.error = "";
      }
    );
    builder.addCase(
      AuthService.createProfile.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "userProfileRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(AuthService.sendNotification.pending, (state) => {
      state.status = "sendEmailPending";
    });
    builder.addCase(
      AuthService.sendNotification.fulfilled,
      (state, { payload }) => {
        state.status = "sendEmailResolved";
        state.error = "";
      }
    );
    builder.addCase(
      AuthService.sendNotification.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "sendEmailRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(AuthService.initPaymentIntent.pending, (state) => {
      state.status = "initPaymentIntentPending";
    });
    builder.addCase(
      AuthService.initPaymentIntent.fulfilled,
      (state, { payload }) => {
        state.status = "initPaymentIntentResolved";
        state.paymentId = payload.clientSecret;
        state.error = "";
      }
    );
    builder.addCase(
      AuthService.initPaymentIntent.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "initPaymentIntentRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(AuthService.confirmPaymentIntent.pending, (state) => {
      state.status = "confirmPaymentIntentPending";
    });
    builder.addCase(
      AuthService.confirmPaymentIntent.fulfilled,
      (state, { payload }) => {
        state.status = "confirmPaymentIntentResolved";
        state.error = "";
      }
    );
    builder.addCase(
      AuthService.confirmPaymentIntent.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "confirmPaymentIntentRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(AuthService.forgetPassword.pending, (state) => {
      state.status = "forgetPasswordPending";
    });
    builder.addCase(AuthService.forgetPassword.fulfilled, (state) => {
      state.status = "forgetPasswordResolved";
    });
    builder.addCase(
      AuthService.forgetPassword.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "forgetPasswordRejected";
      }
    );

    builder.addCase(AuthService.resetPassword.pending, (state) => {
      state.status = "resetPasswordPending";
    });
    builder.addCase(AuthService.resetPassword.fulfilled, (state) => {
      state.status = "resetPasswordResolved";
    });
    builder.addCase(
      AuthService.resetPassword.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);

        state.status = "resetPasswordRejected";
      }
    );
  },
});

// actions from slice
export const { clearAuthError, resetAuthState, clearAuthStatus } =
  authSlice.actions;

// The reducer
export default authSlice.reducer;
