import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import { CreateSupplierProductData, Product, Supplier } from "../../types";
import http from "../../utils/http";

export const fetchSupplier = createAsyncThunk(
  "supplier/fetchSupplier",
  async (Id: string | undefined, thunkAPI) => {
    try {
      let url = `${config.endpoints.supplier.fetchSupplier}`;
      if (Id) {
        url = `${config.endpoints.supplier.fetchSupplier}/${Id}`;
      }
      const {
        data: { result },
      } = await http.get<{ result: Supplier[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchFavoriteSuppliers = createAsyncThunk(
  "user/fetchFavoriteSuppliers",
  async (_, thunkAPI) => {
    try {
      let url = `${config.endpoints.profile.fetchFavoriteSuppliers}`;

      const {
        data: { result },
      } = await http.get<{ result: Supplier[] }>(url);
      return result;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const fetchSupplierProducts = createAsyncThunk(
  "supplier/fetchSupplierProducts",
  async (id: string, thunkAPI) => {
    try {
      const url = `${config.endpoints.supplier.fetchSupplierProducts}/${id}`;
      const {
        data: { result },
      } = await http.get<{ result: Product[] }>(url);
      return { id, result };
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createProduct = createAsyncThunk(
  "supplier/createProduct",
  async (values: CreateSupplierProductData, thunkAPI) => {
    try {
      const url = config.endpoints.supplier.createProduct;

      let productData: CreateSupplierProductData = {};

      if (values?.CategoryId) {
        productData.CategoryId =
          values?.CategoryId[values.CategoryId?.length - 1];
        productData.Title = values?.Title;
        productData.SubTitle = values?.SubTitle;
        productData.Description = values?.Description;
        productData.Specification = values?.Specification;
        productData.Features = values?.Features;
        productData.ProductImages = [];

        if (values?.ImageFiles) {
          let localProductImages = values?.ImageFiles["fileList"];

          for (let index = 0; index < localProductImages.length; index++) {
            productData.ProductImages?.push(
              localProductImages[index].response.result
            );
          }
        }
      }

      const {
        data: { product },
      } = await http.post<{ product: CreateSupplierProductData }>(
        url,
        productData
      );
      return product;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "supplier/updateProduct",
  async (values: any, thunkAPI) => {
    try {
      const url = `${config.endpoints.supplier.updateProduct}/${values.Id}`;

      let productData: CreateSupplierProductData = {};

      productData.CategoryId = values.EditCategoryId;
      productData.Title = values?.Title;
      productData.SubTitle = values?.SubTitle;
      productData.Description = values?.Description;
      productData.Specification = values?.Specification;
      productData.Features = values?.Features;

      productData.ProductImages = [];
      if (values?.ImageFiles) {
        let localProductImages = values?.ImageFiles["fileList"];

        for (let index = 0; index < localProductImages.length; index++) {
          productData.ProductImages?.push(
            localProductImages[index].response.result
          );
        }
      }

      const {
        data: { product },
      } = await http.post<{ product: CreateSupplierProductData }>(
        url,
        productData
      );
      return product;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

// export const createSupplier = createAsyncThunk('profile/createSupplier', async (values: CreateSupplierData, thunkAPI) => {
//   try {
//     const url = config.endpoints.profile.createSupplier;
//     const {
//       data: { profile },
//     } = await http.post<{ profile: Supplier }>(url, values);
//     return profile;
//   } catch (err) {
//     var error = JSON.parse(JSON.stringify(err));

//     return thunkAPI.rejectWithValue(error.message);
//   }
// });
