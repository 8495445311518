import Messages from "./Messages";
// import Members from "./Members";
import "./Chat.css";
import { useAuthListener } from "./firebase/useAuthListener";
import { doc, addDoc, collection, updateDoc, getDoc } from "firebase/firestore";
import Textbox from "./TextBox";
import { ChatList } from "./ChatList";
import { useState } from "react";
import { useScrollToTop } from "../../../hooks/useScrollToTop";
import { useLocation } from "react-router-dom";
import { SendEmailNotification } from "./SendEmailNotification";

interface UserChatRoomProps {
  checkUserIsOnline: boolean;
}

export default function UserChatRoom(props: UserChatRoomProps) {
  // const current = useAuthListener();
  const location = useLocation();

  const [chatRoomID, setChatRoomID] = useState<string>();
  const [supplierId, setSupplierId] = useState<string>();

  document.title = "Chat Console";

  const { quotation } = (location.state as any) || {};

  useScrollToTop();

  return (
    <div className="homepage">
      <aside>
        <div className="msg-block" style={{ padding: "10px", height: "620px" }}>
          <details>
            {props.checkUserIsOnline && (
              <>
                <summary>Chat Group</summary>
                <ChatList
                  setRoomIdFromChatList={setChatRoomID}
                  setSupplierIdFromChatList={setSupplierId}
                />
              </>
            )}
          </details>
        </div>
      </aside>
      <main>
        <div className="row text-center">
          <div className="col-lg-12">
            <h4>{quotation.title}</h4>
          </div>
        </div>

        {props.checkUserIsOnline ? (
          <>
            <Messages chatRoomID={chatRoomID!} />
            <Textbox chatRoomID={chatRoomID!} />
          </>
        ) : (
          <SendEmailNotification />
        )}
      </main>
      <aside></aside>
    </div>
  );
}
