import { Col, Form, notification, Row, Spin, Table } from "antd";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import * as SetupService from "../../../store/setups/setups.actions";
import { Country, City } from "../../../types";
import { useEffect, useState } from "react";
import {
  clearSetupError,
  clearSetupStatus,
} from "../../../store/setups/setups.slice";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as routes from "../../../constants/routes";
import useEffectOnce from "../../../hooks/useEffectOnce";
import * as ListService from "../../../store/lists/list.action";
import { PreLoader } from "../../Components";

const { Item } = Form;

const gridColumns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "Id",
    render: (text: string, record: any) => (
      <Link to={`/admin/city/edit/${record.id}`} state={{ city: record }}>
        {text}
      </Link>
    ),
  },
  {
    title: "Country",
    dataIndex: "countryTitle",
    key: "Country",
  },
  {
    title: "City",
    dataIndex: "title",
    key: "City",
  },
];

export const CitySetupPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const onCityFormSubmit = (values: City) => {
    if (location.pathname.includes("/city/edit/")) {
      dispatch(SetupService.updateCity(values));
    } else {
      dispatch(SetupService.createCity(values));
    }
  };

  const onCityFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  const {
    status: listStatus,
    error: listError,
    entities,
  } = useAppSelector((state) => state.list);

  const { status: setupStatus, error: setupError } = useAppSelector(
    (state) => state.setup
  );

  useEffect(() => {
    if (String(id).length > 0) {
      let selectedCity: City | undefined = entities.cities?.find(
        (a) => String(a.id) === String(id)
      );

      if (selectedCity) {
        // setConverageArea(selectedCity);

        form.setFieldsValue({
          Id: selectedCity.id,
          CountryId: selectedCity.countryId,
          Title: selectedCity.title,
        });
      }
    }
  }, [id]);

  useEffect(() => {
    if (setupStatus === "createCityResolved") {
      displaySuccessNotification("City has been created");

      dispatch(ListService.fetchCities(Number(0)));
    }
    if (setupStatus === "updateCityResolved") {
      displaySuccessNotification("City has been updated");

      dispatch(ListService.fetchCities(Number(0)));
    }

    if (setupStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
    // dispatch(clearSetupStatus());
  }, [dispatch, setupStatus]);

  const navigateAndNew = () => {
    form.setFieldsValue({
      Id: 0,
      CountryId: 0,
      Title: "",
    });

    navigate(routes.ADMIN_DASHBOARD);
  };

  useEffectOnce(() => {
    dispatch(ListService.fetchCities(0));
  });

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onCityFormSubmit}
        onFinishFailed={onCityFinishFailed}
        size="large"
      >
        <div className="row">
          <div className="col-lg-12">
            <h3>City Setup</h3>
            <br />
            <p>
              Please Fill all the field to complete information to setup cities
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <Row gutter={16}>
              <Col span={12}>
                <Table
                  columns={gridColumns}
                  dataSource={entities.cities}
                  pagination={{ total: entities.cities?.length, pageSize: 10 }}
                />
              </Col>
              <Col span={12}>
                <div className="row">
                  <div className="col-lg-12">
                    <Item name="Id" hidden={true}>
                      <input />
                    </Item>
                    <Item
                      name="CountryId"
                      rules={[
                        {
                          required: true,
                          message: "Please select a Country!",
                        },
                      ]}
                    >
                      <select className="local-form input-reg">
                        <option value="-1">Select Country</option>
                        {entities.countries?.map((country, index) => (
                          <option value={country.id} key={index}>
                            {country.title}
                          </option>
                        ))}
                      </select>
                    </Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <Item
                      name="Title"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the title/name of the city!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_rule, value) {
                            if (
                              !entities.cities
                                ?.map((c) => c.title.toLocaleLowerCase())
                                .includes(value.trim().toLocaleLowerCase())
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "Title already exists, Pls specify some different title!"
                            );
                          },
                        }),
                      ]}
                    >
                      <input
                        maxLength={30}
                        className="local-form input-reg"
                        placeholder="City title"
                      ></input>
                    </Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3">
                    <button
                      type="button"
                      className="butn butn-block"
                      onClick={navigateAndNew}
                    >
                      New
                    </button>
                  </div>
                  <div className="col-lg-3">
                    <button
                      type="submit"
                      className="butn butn-block"
                      disabled={setupStatus === "createCityPending"}
                    >
                      <span id="button-text">
                        {setupStatus === "createCityPending" ? (
                          <Spin size="small" />
                        ) : (
                          "Save"
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-3"></div>
                  <div className="col-lg-3"></div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </>
  );
};
