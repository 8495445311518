import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import * as routes from "../../../constants/routes";
import { Link, useLocation } from "react-router-dom";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { Form, Pagination, Skeleton, Spin, Tag } from "antd";
import type { PaginationProps } from "antd";
import { SearchData } from "../../../types";
import * as ListService from "../../../store/lists/list.action";
import prodlogo from "../../assets/supp-logo.png";
import config from "../../../config";
import { listenerCancelled } from "@reduxjs/toolkit/dist/listenerMiddleware/exceptions";
import {
  displayErrorMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import listsSlice, {
  clearListError,
  clearListStatus,
} from "../../../store/lists/lists.slice";

export const ProductListPage = () => {
  const [form] = Form.useForm();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const {
    entities,
    status: listStatus,
    error: listError,
    productPaging: prdListPaging,
  } = useAppSelector((state) => state.list);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState<SearchData>();

  useEffectOnce(() => {
    dispatch(ListService.fetchAllProducts({ pageNo: 1, pageSize: 10 }));
    
    setCurrentPage(1);
  });

  const onFormSubmit = (formData: SearchData) => {
    setSearchFilter(formData);

    setCurrentPage(1);

    dispatch(
      ListService.fetchAllProducts({
        ...formData,
        pageNo: 1,
        pageSize: 10,
      })
    );
  };

  const onPageChange: PaginationProps["onChange"] = (page, pageSize) => {
    dispatch(
      ListService.fetchAllProducts({
        ...searchFilter,
        pageNo: page,
        pageSize: pageSize,
      })
    );

    setCurrentPage(page);
  };

  useEffect(() => {
    if (listError && listError.length > 0) {
      displayErrorMessage(listError);
      dispatch(clearListError());
    }
  }, [dispatch, listError]);

  useEffect(() => {
    if (listStatus === "approveProductResolved") {
      displaySuccessNotification("Product has been approved");
      dispatch(
        ListService.fetchAllProducts({ pageNo: currentPage, pageSize: 10 })
      );
      dispatch(clearListStatus());
    }
    if (listStatus === "rejectProductResolved") {
      displaySuccessNotification("Product has been rejected");
      dispatch(
        ListService.fetchAllProducts({ pageNo: currentPage, pageSize: 10 })
      );
      dispatch(clearListStatus());
    }
  }, [dispatch, listStatus]);

  return (
    <>
      <div className="row">
        <div className="col-lg-12 text-center">
          <Pagination
            current={currentPage}
            showSizeChanger={false}
            onChange={onPageChange}
            total={prdListPaging?.totalCount}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            // defaultPageSize={20}
            // defaultCurrent={1}
          />
        </div>
      </div>
      {listStatus === "fetchAllProductsPending" ? (
        <Skeleton active></Skeleton>
      ) : (
        <div className="container-fluid">
          <div className="row">
            {entities.products?.map((product, index) => (
              <div
                className="col-lg-3 col-md-6 col-sm-6"
                key={index.toString()}
              >
                <div className="profileCard">
                  <div className="imgDisplay">
                    {product.images && product.images.length > 0 ? (
                      <img
                        src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${product.images[0].fileName}&type=media`}
                        alt="Product Logo"
                      />
                    ) : (
                      <img src={prodlogo} alt="Product Logo" />
                    )}
                  </div>
                  <div className="textDesc">
                    <p>
                      <b>{product?.title}</b>
                    </p>
                    <p>
                      <b>{product?.company?.companyName}</b>
                    </p>
                    <p>
                      {product.status === "Rejected" && (
                        <Tag color="red">REJECTED</Tag>
                      )}
                    </p>
                  </div>
                  <div className="controlArea">
                    <Link
                      to={routes.SUPPLIER_STORE}
                      state={{ product: product }}
                      className="button-block-outlined"
                    >
                      View
                    </Link>
                    {!product.status || product.status === "PendingApproval" ? (
                      <>
                        <button
                          className="button-block"
                          onClick={() =>
                            dispatch(
                              ListService.approveProduct(BigInt(product.id!)!)
                            )
                          }
                          disabled={listStatus === "approveProductPending"}
                        >
                          <span id="button-text">
                            {listStatus === "approveProductPending" ? (
                              <Spin size="small" />
                            ) : (
                              "Approve"
                            )}
                          </span>
                        </button>
                        {/* <button
                          className="button-block"
                          onClick={() =>
                            dispatch(
                              ListService.rejectProduct(BigInt(product.id!)!)
                            )
                          }
                          disabled={listStatus === "rejectProductPending"}
                        >
                          <span id="button-text">
                            {listStatus === "rejectProductPending" ? (
                              <Spin size="small" />
                            ) : (
                              "Reject"
                            )}
                          </span>
                        </button> */}
                      </>
                    ) : (
                      <button
                        className="button-block"
                        onClick={() =>
                          dispatch(
                            ListService.deleteProduct(BigInt(product.id!)!)
                          )
                        }
                        disabled={listStatus === "deleteProductPending"}
                      >
                        <span id="button-text">
                          {listStatus === "deleteProductPending" ? (
                            <Spin size="small" />
                          ) : (
                            "Delete"
                          )}
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
