import { Table, Tag } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { InquiryStatus } from "../../../enums";
import * as InquiryService from "../../../store/inquiry/inquiry.actions";

interface InquiryGridProps {}

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (text: string, record: any) =>
      record.status === InquiryStatus.POSTED ? (
        <Link to={`/inquiry/edit/${record.id}`} state={{ inquiry: record }}>
          {text}
        </Link>
      ) : (
        <span>{text}</span>
      ),
  },
  {
    title: "Date of service",
    dataIndex: "lastDate",
    key: "lastDate",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string) => (
      <Tag
        color={
          ["Expired", "Cancelled"].includes(text)
            ? "red"
            : text === "Approved"
            ? "green"
            : "orange"
        }
        key={text}
      >
        {
          Object.keys(InquiryStatus)[
            Object.values(InquiryStatus).indexOf(Number(text) as InquiryStatus)
          ]
        }
      </Tag>
    ),
  },
  {
    title: "Budget",
    dataIndex: "maximumBudget",
    key: "maximumBudget",
  },
  {
    title: "Company",
    dataIndex: ["supplier", "companyName"],
    key: "companyName",
  },
];

export const InquiryGrid = (props: InquiryGridProps) => {
  const dispatch = useAppDispatch();

  const { inquiries } = useAppSelector((state) => state.inquiry);

  useEffect(() => {
    dispatch(
      InquiryService.fetchUserInquiries({
        status: InquiryStatus.ALL.toString(),
      })
    );
  }, [dispatch]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={inquiries}
        pagination={{ total: inquiries.length, pageSize: 10 }}
      />
    </>
  );
};
