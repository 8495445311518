import React from "react";
import { Avatar, Button, Menu, Space } from "antd";
import * as routes from "../../../../constants/routes";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  AiOutlineCustomerService,
  AiOutlineLogout,
  AiOutlineShoppingCart,
  AiOutlineUser,
} from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import * as AuthService from "../../../../store/auth/auth.actions";
import { displaySuccessNotification } from "../../../../utils/notifications";
import { DashboardOutlined, UserOutlined } from "@ant-design/icons";
import { resetAuthState } from "../../../../store/auth/auth.slice";
import { resetSupplierState } from "../../../../store/supplier/supplier.slice";
import { resetInquiryState } from "../../../../store/inquiry/inquiry.slice";
import { resetQuoteState } from "../../../../store/quotation/quotation.slice";
import { resetListState } from "../../../../store/lists/lists.slice";
import { store } from "../../../../store";
import { useFireBase } from "../../../Components/ChatStore/firebase/config";
import { UserType } from "../../../../enums";
import config from "../../../../config";

const { Item, SubMenu } = Menu;

export const MenuItems = () => {
  const { user } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onLogOut = () => {
    dispatch(AuthService.logOut());
    dispatch(resetAuthState());
    dispatch(resetSupplierState());
    dispatch(resetInquiryState());
    dispatch(resetQuoteState());
    dispatch(resetListState());
    displaySuccessNotification("You've successfully logged out!");
  };

  // useEffect(() => {
  //   if (status === "loggedOut") {
  //     sessionStorage.removeItem("user.auth");

  //     goOffline(db);

  //     navigate(routes.HOME, { replace: true });
  //     // window.location.replace(routes.HOME);
  //     dispatch(clearAuthStatus());
  //   }
  // }, [dispatch, status]);

  // if (status === "pending" || status === "idle") return <p>Loading nav.</p>;

  const toUserDashboard = () => {
    let dashboard = "/home";

    if (user && user.userType === UserType.CUSTOMER) {
      dashboard = routes.COMP_DASHBOARD;
    } else if (user && user.userType === UserType.SUPPLIER) {
      dashboard = routes.SUPP_DASHBOARD;
    } else if (user && user.userType === UserType.ADMIN) {
      dashboard = routes.ADMIN_DASHBOARD;
    }

    return dashboard;
  };

  const publicLinks = (
    <>
      <div className="userIn">
        <Space direction="horizontal">
          <button
            type="button"
            className="btn-emrald"
            onClick={() => navigate(routes.USER_SUBSCRIPTION)}
          >
            Pricing
          </button>
          <button
            type="button"
            className="btn-emrald"
            onClick={() => navigate(routes.LOGIN)}
          >
            Login
          </button>
          <button
            type="button"
            onClick={() => navigate(routes.CREATE_ACCOUNT)}
            className="btn-emrald-text"
          >
            Sign Up
          </button>
        </Space>
      </div>
    </>
  );

  const privateLinks = (
    <>
      <Space>
        <span style={{ marginTop: "5px" }}>
          Welcome, {store.getState().auth.user?.fullName}
        </span>
        <Menu
          mode="horizontal"
          selectable={false}
          style={{ backgroundColor: "transparent" }}
          className="app-header__menu--private"
        >
          <SubMenu
            title={
              <Avatar
                size="large"
                src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${user.image}&type=user`}
                className="app-header__menu--avatar"
                icon={<UserOutlined />}
              />
            }
            className="app-header__sub-menu--private"
            key="submenu"
          >
            <Item key="/dashboard">
              <NavLink to={toUserDashboard()}>
                <DashboardOutlined></DashboardOutlined> Dashboard
              </NavLink>
            </Item>
            <Item key="/user">
              <NavLink to={routes.USER_PROFILE}>
                <AiOutlineUser></AiOutlineUser> Profile
              </NavLink>
            </Item>
            {store.getState().auth.user.userType === UserType.SUPPLIER ? (
              <>
                <Item key="/product">
                  <NavLink to="/view/company/products">
                    <AiOutlineShoppingCart /> Products
                  </NavLink>
                </Item>
                <Item key="/service">
                  <NavLink to="/view/company/services">
                    <AiOutlineCustomerService /> Services
                  </NavLink>
                </Item>
              </>
            ) : (
              <></>
            )}
            <Item key={routes.LOGOUT} onClick={onLogOut}>
              <AiOutlineLogout></AiOutlineLogout> Log out
            </Item>
          </SubMenu>
        </Menu>
      </Space>
    </>
  );

  return user && user.userName ? privateLinks : publicLinks;
};
