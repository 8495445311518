import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import useEffectOnce from "../../../hooks/useEffectOnce";
import * as SetupService from "../../../store/setups/setups.actions";
import * as AuthService from "../../../store/auth/auth.actions";
import {
  displayErrorMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import { clearAuthError } from "../../../store/auth/auth.slice";
import * as routes from "../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { Skeleton, Spin, Typography } from "antd";
import {
  BreadCrumb,
  MenuNavigator,
  PageBanner,
  PageFooter,
} from "../../Layout";
import buysingColorlogo from "../assets/BuySing_Color_Logo.png";
import {
  clearSetupError,
  clearSetupStatus,
} from "../../../store/setups/setups.slice";

export const ActivateSubscriptionPage = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    setups,
    status: setupStatus,
    error: setupError,
  } = useAppSelector((state) => state.setup);

  //   useEffectOnce(() => {
  //     dispatch(
  //       SetupService.fetchSubscriptionsForActivation({ pageNo: 1, pageSize: 10 })
  //     );
  //   });

  useEffect(() => {
    if (setupError) {
      displayErrorMessage(setupError);

      dispatch(clearSetupError());
    }
    if (setupStatus === "activateUserSubscriptionResolved") {
      displaySuccessNotification("User subscription has been activated");

      dispatch(clearSetupStatus());
    }
  }, [dispatch, setupStatus, setupError]);

  return (
    <>
      <div className="container">
        <h3 className="subscribe-heading">Subscribe Now </h3>
        {setupStatus === "fetchSubscriptionsForActivationPending" ? (
          <Skeleton active></Skeleton>
        ) : (
          <div className="row">
            {setups.userSubscriptions.map((sub) => (
              <div className="col-lg-3" key={sub.id.toString()}>
                <div className="subscribe-box">
                  <span>{sub.subscription.title}</span>
                  <div className="gbp">
                    {sub.subscription.charges} GBP
                    <p>{sub.applicationUser.fullName}</p>
                  </div>
                  <button
                    className="sub-btn"
                    type="button"
                    onClick={() =>
                      dispatch(SetupService.activateUserSubscription(sub.id))
                    }
                  >
                    {setupStatus === "activateUserSubscriptionPending" ? (
                      <Spin size="small" />
                    ) : (
                      "Activate"
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
