import config from "../../../config";
import * as routes from "../../../constants/routes";
import parse from "html-react-parser";
import { Button, List, Modal, Space, Tag, Tooltip } from "antd";

import {
  DoubleRightOutlined,
  EditOutlined,
  WechatOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useState } from "react";
import { InquiryResponse, QuotationResponse } from "../../../types";
import { store } from "../../../store";
import { UserType } from "../../../enums";
import { QuotationStatus } from "../../../enums";
import { QuotationTimeLine } from "../timeLine";
import { Link } from "react-router-dom";

interface QuotationListProps {
  inquiryRef: InquiryResponse;
  approveQuotationDispatch: (quotation: QuotationResponse) => void;
  shortListQuotationDispatch: (quotation: QuotationResponse) => void;
  purchaseOrderFulfillDispatch: (quotation: QuotationResponse) => void;
}

export const QuotationList = (props: QuotationListProps) => {
  const dispatch = useAppDispatch();

  const {
    status: quotationStatus,
    error: quotationError,
    quotations,
  } = useAppSelector((state) => state.quotation);

  const { user } = useAppSelector((state) => state.auth);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalQuotation, setModalQuotation] = useState<QuotationResponse>();

  const showModal = (item: QuotationResponse) => {
    setIsModalVisible(true);

    setModalQuotation(item);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getTagByColor = (color: string, status: number) => {
    return (
      <Tag color={color} key={status}>
        {
          Object.keys(QuotationStatus)[
            Object.values(QuotationStatus).indexOf(status as QuotationStatus)
          ]
        }
      </Tag>
    );
  };

  const getControlTemplate = (quotation: QuotationResponse) => {
    if (user.userType === UserType.CUSTOMER) {
      if (quotation.status === QuotationStatus.SHORTLISTED) {
        return (
          <Button
            className="button-block"
            onClick={() => props.approveQuotationDispatch(quotation)}
          >
            Approve
          </Button>
        );
      } else if (quotation.status === QuotationStatus.SENT) {
        return (
          <Button
            className="button-block"
            onClick={() => props.shortListQuotationDispatch(quotation)}
          >
            Short List
          </Button>
        );
      } else if (quotation.status === QuotationStatus.ORDERED) {
        return (
          <Button
            className="button-block"
            onClick={() => props.purchaseOrderFulfillDispatch(quotation)}
          >
            Fulfill Order
          </Button>
        );
      }
    }

    if (quotation.status === QuotationStatus.COMPLETED) {
      return getTagByColor("green", quotation.status!);
    } else if (
      quotation.status === QuotationStatus.EXPIRED ||
      quotation.status === QuotationStatus.REJECTED
    ) {
      return getTagByColor("red", quotation.status!);
    } else {
      return (
        <>
          <Link to={routes.TO_CHAT_APP} state={{ quotation: quotation }}>
            <Tooltip title="Start Chat">
              <WechatOutlined style={{ fontSize: "32px" }} />
            </Tooltip>
          </Link>
        </>
      );
    }
  };

  // const approveQuotationDispatch = (quotation: QuotationResponse) => {
  //   dispatch(approveQuotation(quotation.id));
  // };

  return (
    <>
      {quotations &&
        quotations.some((q) => q.inquiry.id === props.inquiryRef.id) && (
          /*store.getState().auth.user.userType === UserType.CUSTOMER &&*/ <>
            {/* <section className="profilingSection centraSpacing">
              <div className="container-fluid"> */}
            {/* <div className="row">
                <div className="col-lg-12">
                  <h3>Quotations</h3>
                  <br />
                </div>
              </div> */}
            {/* <div className="row">
                  <div className="col-lg-12"> */}
            <List
              itemLayout="horizontal"
              dataSource={quotations}
              renderItem={(quotation) => (
                <List.Item
                // actions={[
                //   <Button
                //     className="button-block"
                //     onClick={approveQuotationDispatch}
                //   >
                //     Approve
                //   </Button>,
                // ]}
                >
                  <List.Item.Meta
                    avatar={<DoubleRightOutlined />}
                    title={
                      <>
                        <div className="row">
                          <div className="col-lg-6">
                            <a href="#" onClick={() => showModal(quotation)}>
                              {quotation.title}
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <b>Budget:</b> {quotation.amount}
                          </div>
                          <div className="col-lg-3">
                            <b>Date of delivery: </b>
                            {
                              quotation.validUpto
                              /* {quotation.validUpto?.substring(
                              0,
                              quotation.validUpto.indexOf("T")
                            )} */
                            }
                          </div>
                        </div>
                      </>
                    }
                    description={
                      <>
                        <div className="row">
                          <div className="col-lg-9">
                            {parse("<br/>" + quotation.description!)}
                          </div>
                          {/* <div className="col-lg-3"></div> */}
                          <div
                            className="col-lg-3"
                            style={{ textAlign: "center" }}
                          >
                            <br />
                            {getControlTemplate(quotation)}
                            {/* {quotation.inquiry.status ===
                          InquiryStatus.SHORTLISTED ? (
                            <Button
                              className="button-block"
                              onClick={() =>
                                props.approveQuotationDispatch(quotation)
                              }
                            >
                              Approve
                            </Button>
                          ) : quotation.status === QuotationStatus.SENT &&
                            quotation.inquiry.status ===
                              InquiryStatus.RECEIVED ? (
                            <Button
                              className="button-block"
                              onClick={() =>
                                props.shortListQuotationDispatch(quotation)
                              }
                            >
                              Short List
                            </Button>
                          ) : (
                            <Tag
                              color={
                                quotation.status === QuotationStatus.APPROVED
                                  ? "green"
                                  : "orange"
                              }
                              key={quotation.status}
                            >
                              {
                                Object.keys(QuotationStatus)[
                                  Object.values(QuotationStatus).indexOf(
                                    quotation.status as QuotationStatus
                                  )
                                ]
                              }
                            </Tag>
                          )} */}
                          </div>
                        </div>
                        <div className="row">
                          <Space
                            direction="vertical"
                            hidden={
                              quotation.status === QuotationStatus.EXPIRED ||
                              quotation.status === QuotationStatus.REJECTED
                            }
                          >
                            <QuotationTimeLine quotation={quotation} />
                          </Space>
                        </div>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
            {/* </div>
                </div>
              </div>
            </section> */}

            <Modal
              width={1000}
              centered
              title={
                user.userType === UserType.SUPPLIER &&
                (modalQuotation?.status === QuotationStatus.SENT ||
                  modalQuotation?.status === QuotationStatus.REVISED) ? (
                  <Link
                    to={`/inquiry/quotation/edit/${modalQuotation?.id}`}
                    state={{ quotation: modalQuotation }}
                  >
                    <EditOutlined /> {modalQuotation?.title}
                  </Link>
                ) : (
                  modalQuotation?.title
                )
              }
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <p>{parse(modalQuotation?.description! || "")}</p>
              <div className="row">
                <div className="col-lg-12">
                  <b>From Company:</b> {modalQuotation?.company.companyName}
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-3">
                  <b>Minimum budget:</b> {modalQuotation?.inquiry.minimumBudget}
                </div>
                <div className="col-lg-6"></div>
                <div className="col-lg-3">
                  <b>Maximum budget:</b> {modalQuotation?.inquiry.maximumBudget}
                </div>
              </div>
              <div className="row">&nbsp;</div>
              <div className="row">
                <div className="col-lg-6">
                  {modalQuotation?.images.map((img, index) => (
                    <>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${img.fileName}&type=quotation`}
                      >
                        {`Click Quotation ${index + 1} to view`}
                      </a>
                      <br />
                    </>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  {
                    <>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${modalQuotation?.purchaseOrder?.fileName}&type=inquiry`}
                      >
                        Click Purchse Order to view
                      </a>
                      <br />
                    </>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  {
                    <>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${modalQuotation?.invoice?.fileName}&type=inquiry`}
                      >
                        Click Invoice to view
                      </a>
                      <br />
                    </>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  {
                    <>
                      Feedback:{" "}
                      {parse(modalQuotation?.feedback?.comments || "")}
                    </>
                  }
                </div>
              </div>
            </Modal>
          </>
        )}
    </>
  );
};
