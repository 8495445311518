import * as ListService from "../../../store/lists/list.action";
import { Form, Layout, Select, Cascader } from "antd";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import useEffectOnce from "../../../hooks/useEffectOnce";
import { fetchPurchaseOrderByParams } from "../../../store/quotation/quotation.actions";
import { Rule } from "antd/lib/form";

interface ProductServiceWidgetProps {
  name: string;
  placeHolder: string;
  categoryIndex: number;
  allowMultiple: boolean;
  allowChangeOnSelect: boolean;
  mandatory?: boolean;
  // defaultValue?: any;
  onCategoryChange?: (categoriId: Array<any>) => void;
}

export const CategoryWidget = (props: ProductServiceWidgetProps) => {
  const dispatch = useAppDispatch();

  const [selectedCategoryDefaultValue, setSelectedCategoryDefaultValue] =
    useState<any[]>([]);

  const {
    status: listStatus,
    error: listError,
    entities,
  } = useAppSelector((state) => state.list);

  const [services, setServices] = useState<Array<any>>([]);

  useEffect(() => {
    // if (!localStorage.getItem("CAT" + props.categoryIndex.toString())) {
    dispatch(ListService.fetchServices());
    // }
  }, [dispatch, props.categoryIndex]);

  const transformServices = (categories: any) => {
    return categories?.map((m: any) => {
      return {
        value: m.id,
        label: m.categoryName,
        children: m.children && transformServices(m.children),
      };
    });
  };

  useEffect(() => {
    if (listStatus === "fetchServicesSuccess") {
      if (entities.categories) {
        setServices(
          transformServices(
            entities?.categories[props.categoryIndex]?.categories
          )
        );
      }
    }
  }, [listStatus]);

  const onCategoryChange = (categoryId: Array<any>) => {
    if (props.onCategoryChange) props.onCategoryChange(categoryId);
  };

  let validationRules: Rule[] | undefined;

  if (props.mandatory) {
    if (props.mandatory === true) {
      validationRules = [
        {
          type: "array",
          required: true,
          message: "Please select product category you offer!",
        },
      ];
    }
  }

  // useEffectOnce(() => {
  //   let myval: any[] = [];
  //   // for (let index = 0; index < props.defaultValue.split(",").length; index++) {
  //   //   myval.push(props.defaultValue.split(",")[index]);
  //   // }
  //   myval.push("Food");
  //   setSelectedCategoryDefaultValue(myval);
  //   console.log(myval);
  // });

  return (
    <>
      <Form.Item name={props.name} rules={validationRules}>
        <Cascader
          size="large"
          onChange={onCategoryChange}
          options={services}
          changeOnSelect={props.allowChangeOnSelect}
          placeholder={props.placeHolder}
          multiple={props.allowMultiple}
          // defaultValue={selectedCategoryDefaultValue}
        />
      </Form.Item>
    </>
  );
};
