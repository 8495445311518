import {
  DatePicker,
  Form,
  InputNumber,
  notification,
  Select,
  Spin,
} from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import * as QuotationService from "../../../store/quotation/quotation.actions";
import {
  clearQuoteError,
  clearQuotationStatus,
} from "../../../store/quotation/quotation.slice";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import { PreLoader, UploadDragDrop } from "../../Components";
import TextEditor from "../../Components/textEditor";

const { Item } = Form;
const { Option } = Select;

interface SendQuotationPageProps {
  mode?: string;
}

export const SendQuotationPage = (props: SendQuotationPageProps) => {
  const location = useLocation();

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const { inquiry } = (location.state as any) || {};

  const { quotation } = (location.state as any) || {};

  const { status: quotationStatus, error: quotationError } = useAppSelector(
    (state) => state.quotation
  );

  const { id } = useParams();

  const onFormSubmit = (values: any) => {
    if (location.pathname.includes("/quotation/edit/")) {
      dispatch(QuotationService.updateQuotation(values));
    } else {
      dispatch(QuotationService.createQuotation(values));
    }
  };

  useEffect(() => {
    if (quotationError) {
      displayErrorMessage(quotationError);
      dispatch(clearQuoteError());
    }
  }, [quotationError]);

  useEffect(() => {
    if (quotationStatus === "createQuotationResolved") {
      displaySuccessNotification("Quotation has been submitted");
    }

    if (quotationStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [quotationStatus]);

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useEffect(() => {
    if (inquiry) {
      form.setFieldsValue({
        InquiryId: inquiry.id!,
      });
    }
    if (quotation) {
      form.setFieldsValue({
        Id: id,
        InquiryId: quotation.inquiry.id,
        Title: quotation.title,
        Description: quotation.description,
        ValidUpto: moment(quotation.validUpTo),
        Amount: quotation.amount,
      });
    }
  }, [location, inquiry, quotation]);

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="USD">$</Option>
        <Option value="CNY">¥</Option>
      </Select>
    </Form.Item>
  );

  useScrollToTop();

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormSubmit}
        onFinishFailed={onFinishFailed}
        size="large"
      >
        <div className="row">
          <div className="col-lg-9">
            <h3>Send Quotation</h3>
            <br />
            <p>
              Please Fill all the field to complete information about the
              Quotation for the Inquires you are looking for.
            </p>
          </div>
        </div>

        <div className="row formRow">
          <div className="col-lg-12">
            <Item name="Id" hidden={true}>
              <input />
            </Item>
            <Item name="InquiryId" hidden={true}>
              <input />
            </Item>
            <Item
              name="Title"
              label="Quotation Title"
              rules={[
                {
                  required: true,
                  message: "Please enter what defines your Quotation Title!",
                },
              ]}
            >
              <input
                maxLength={50}
                className="local-form input-reg"
                placeholder="Quotation Title"
              />
            </Item>
          </div>
        </div>

        <div className="row formRow">
          <div className="col-lg-6">
            <Item
              name="Amount"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: "Please enter what defines your Quotation Amount!",
                },
              ]}
            >
              <InputNumber
                // addonAfter={suffixSelector}
                style={{ width: "100%" }}
              />
            </Item>
          </div>
          <div className="col-lg-6">
            <div className="col-lg-6">
              <Item
                name="ValidUpto"
                label="Date of delivery"
                rules={[
                  {
                    required: true,
                    message:
                      "Please enter Date of delivery for your Quotation!",
                  },
                ]}
              >
                {/* @ts-ignore */}
                <DatePicker />
              </Item>
            </div>
          </div>
        </div>

        <div className="row formRow">
          <div className="col-lg-12">
            <Item
              name="Description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please enter what defines your Quotation!",
                },
                ({ getFieldValue }) => ({
                  validator(_rule, value) {
                    if (!value || !value.includes("<script>")) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Invalid input found!");
                  },
                }),
              ]}
            >
              {/* @ts-ignore */}
              <TextEditor placeholder="Description" />
            </Item>
          </div>
        </div>

        <div className="row formRow">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <UploadDragDrop ItemName="ImageFiles" type="quotation" />
          </div>
          <div className="col-lg-3"></div>
        </div>

        <div className="row formRow">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <button
              type="submit"
              className="butn butn-block"
              disabled={quotationStatus === "createQuotationPending"}
            >
              <span id="button-text">
                {quotationStatus === "createQuotationPending" ? (
                  <Spin size="small" />
                ) : (
                  "Submit Quotation"
                )}
              </span>
            </button>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </Form>
    </>
  );
};
