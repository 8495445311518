import { Form, notification, Spin } from "antd";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import * as AuthService from "../../../store/auth/auth.actions";
import { clearAuthError } from "../../../store/auth/auth.slice";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import userNotOnlineImg from "../../assets/img/user-not-online.jpg";
import { PreLoader } from "../loader";

const { Item } = Form;

export const SendEmailNotification = () => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const { status: authStatus, error: authError } = useAppSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (authError) {
      displayErrorMessage(authError);
      dispatch(clearAuthError());
    }
  }, [authError]);

  useEffect(() => {
    if (authStatus === "sendEmailResolved") {
      displaySuccessNotification("Email has been sent");
    }

    if (authStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [authStatus]);

  const onFormSubmit = (formData: any) => {
    dispatch(AuthService.sendNotification(formData));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  return (
    <>
      <div className="text-center">
        <div className="row">&nbsp;</div>
        <div className="row">
          <img src={userNotOnlineImg} alt="" />
        </div>
        <div className="row">&nbsp;</div>

        <div className="row">
          <h2>
            This user is not online at the moment, please send an email to
            notify about this product.
          </h2>
        </div>
        <div className="row">
          <Form
            form={form}
            onFinish={onFormSubmit}
            onFinishFailed={onFinishFailed}
          >
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2 col-md-12 col-sm-12 col-xs-12">
                <Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter details to notify user about the product",
                    },
                  ]}
                >
                  <textarea
                    className="formControl"
                    placeholder="Special Description for the item"
                  ></textarea>
                </Item>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2 col-md-12 col-sm-12 col-xs-12">
                <button
                  className="butn butn-block"
                  type="submit"
                  disabled={authStatus === "sendEmailPending"}
                >
                  <span id="button-text">
                    {authStatus === "sendEmailPending" ? (
                      <Spin size="small" />
                    ) : (
                      "Send Notification"
                    )}
                  </span>
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
