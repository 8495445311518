import { Col, Form, notification, Row, Spin, Table, Tree } from "antd";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import * as SetupService from "../../../store/setups/setups.actions";
import {
  Country,
  Category,
  Service,
  CategoryTypeResponse,
  CategoryResponse,
} from "../../../types";
import { FormEvent, useEffect, useState } from "react";
import {
  clearSetupError,
  clearSetupStatus,
} from "../../../store/setups/setups.slice";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as routes from "../../../constants/routes";
import useEffectOnce from "../../../hooks/useEffectOnce";
import * as ListService from "../../../store/lists/list.action";
import { CategoryWidget, PreLoader, SingleUpload } from "../../Components";
import { DataNode, TreeProps } from "antd/lib/tree";
import config from "../../../config";
import { clearListStatus } from "../../../store/lists/lists.slice";

const { Item } = Form;

// const gridColumns = [
//   {
//     title: "Id",
//     dataIndex: "id",
//     key: "Id",
//     render: (text: string, record: any) => (
//       <Link
//         to={`/admin/category/edit/${record.id}`}
//         state={{ category: record }}
//       >
//         {text}
//       </Link>
//     ),
//   },
//   {
//     title: "ParentCategory",
//     dataIndex: "parentCategory",
//     key: "ParentCategory",
//   },
//   {
//     title: "Category",
//     dataIndex: "title",
//     key: "Category",
//   }
// ];

export const CategorySetupPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const location = useLocation();

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const [treeData, setTreeData] = useState<DataNode[]>();

  const onCategoryFormSubmit = (values: Category) => {
    if (location.pathname.includes("/category/edit/")) {
      dispatch(SetupService.updateCategory(values));
    } else {
      dispatch(SetupService.createCategory(values));
    }
  };

  const onCategoryFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  const {
    status: listStatus,
    error: listError,
    entities,
  } = useAppSelector((state) => state.list);

  const {
    status: setupStatus,
    error: setupError,
    setups,
  } = useAppSelector((state) => state.setup);

  const getCategoryTitle = (key: string, tree: DataNode[]): string => {
    let categoryTitle: string = "";
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        const childNode: DataNode = node.children.find(
          (item) =>
            eval(String("[".concat(String(item.key)).concat("]"))).slice(
              -1
            )[0] === key
        )!;
        if (childNode) {
          categoryTitle = String(childNode.title);
        } else if (getCategoryTitle(key, node.children)) {
          categoryTitle = getCategoryTitle(key, node.children);
        }
      }
    }
    return categoryTitle;
  };

  const getCategoryValue = (
    key: string,
    prop: string,
    tree: CategoryResponse[]
  ): string => {
    let categoryValue: string = "";

    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        const childNode: CategoryResponse = node.children.find(
          (item) => String(item.id) === key
        )!;
        if (childNode) {
          categoryValue =
            Object.entries(childNode)[Object.keys(childNode).indexOf(prop)][1];
          break;
        } else if (getCategoryValue(key, prop, node.children)) {
          categoryValue = getCategoryValue(key, prop, node.children);
        }
      }
    }
    return categoryValue;
  };

  const transformCategories = (
    categoryType: number,
    categories: any,
    parent?: any,
    categoryId?: string
  ) => {
    return categories?.map((m: any) => {
      categoryId = categoryId ? categoryId : "";
      if (parent) {
        const categoryIdArr = eval("[".concat(categoryId).concat("]"));
        if (
          categoryIdArr.length > 1 &&
          categoryIdArr[categoryIdArr.length - 2] === parent.id
        ) {
          categoryId = categoryIdArr
            .slice(0, categoryIdArr.indexOf(parent.id) + 1)
            .join(",")
            .concat(",")
            .concat(m.id);
        } else {
          categoryId = categoryId.concat(",").concat(m.id);
        }
      } else {
        categoryId = `${categoryType},${m.id}`;
      }
      return {
        key: categoryId,
        title: m.categoryName,
        children:
          m.children &&
          transformCategories(categoryType, m.children, m, categoryId),
      };
    });
  };

  useEffect(() => {
    if (id && String(id).length > 0) {
      const paramCategoryArr: string[] = eval("[".concat(id).concat("]"));
      let selectedCategoryId = paramCategoryArr.slice(-1)[0];
      let selectedParentCategoryId = paramCategoryArr.slice(
        1,
        paramCategoryArr.length - 1
      );

      setSelectedCategoryType(Number(paramCategoryArr[0]));
      setSelectedCategoryId(BigInt(selectedCategoryId));

      form.setFieldsValue({
        Id: selectedCategoryId,
        CategoryType: paramCategoryArr[0],
        ParentId: selectedParentCategoryId,
        CategoryName: getCategoryTitle(selectedCategoryId, treeData!),
      });
    }
  }, [id]);

  useEffect(() => {
    if (setupStatus === "createCategoryResolved") {
      displaySuccessNotification("Category has been created");

      dispatch(ListService.fetchServices());
      dispatch(SetupService.fetchCategories());
    }
    if (setupStatus === "updateCategoryResolved") {
      displaySuccessNotification("Category has been updated");

      dispatch(ListService.fetchServices());
      dispatch(SetupService.fetchCategories());
    }
    if (setupStatus === "fetchCategoriesResolved") {
      const treeData: DataNode[] = [
        {
          key: "[0]",
          title: "Products",
          children: transformCategories(0, setups.categories[0].categories!),
        },
        {
          key: "[1]",
          title: "Services",
          children: transformCategories(1, setups.categories[1].categories!),
        },
      ];
      setTreeData(treeData);
    }

    if (setupStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
    // dispatch(clearSetupStatus());
  }, [dispatch, setupStatus]);

  const navigateAndNew = () => {
    form.setFieldsValue({
      CategoryType: -1,
      ParentId: 0,
      CategoryName: "",
    });

    navigate(routes.ADMIN_DASHBOARD);
  };

  const [selectedCategoryType, setSelectedCategoryType] = useState(-1);
  const [selectedCategoryId, setSelectedCategoryId] = useState(BigInt(-1));

  const selectCategoryType = (event: any) => {
    setSelectedCategoryType(Number(event.target.value));
  };

  useEffect(() => {
    dispatch(SetupService.fetchCategories());
  }, [dispatch]);

  const onTreeNodeSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
    navigate(`/admin/category/edit/${selectedKeys[0]}`);
  };

  const imageSelectCallback = (file: any) => {
    if (file) form.setFieldsValue({ Image: file.response.result });
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onCategoryFormSubmit}
        onFinishFailed={onCategoryFinishFailed}
        size="large"
      >
        <div className="row">
          <div className="col-lg-12">
            <h3>Category Setup</h3>
            <br />
            <p>
              Please Fill all the field to complete information to setup
              categories
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <Row gutter={16}>
              <Col span={12}>
                <Tree
                  defaultExpandAll
                  treeData={treeData}
                  onSelect={onTreeNodeSelect}
                ></Tree>
              </Col>
              <Col span={12}>
                <div className="row">
                  <div className="col-lg-12" style={{ textAlign: "center" }}>
                    <Item hidden={true} name="Image">
                      <input />
                    </Item>
                    {location.pathname.includes("/category/edit/") ? (
                      // <DragUpload name="Image" onImagSelect={imageSelectCallback}>
                      <img
                        alt="category"
                        style={{ width: "104px", height: "104px" }}
                        src={`${config.baseURI}${
                          routes.DOWNLOAD_IMAGE
                        }${getCategoryValue(
                          String(selectedCategoryId),
                          "image",
                          setups.categories.flatMap((c) => c.categories)!
                        )}&type=category`}
                      />
                    ) : (
                      <SingleUpload
                        buttonText={"Category Image"}
                        onImageSelect={imageSelectCallback}
                        type="category"
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <Item name="Id" hidden={true}>
                      <input />
                    </Item>
                    <Item
                      name="CategoryType"
                      rules={[
                        {
                          required: true,
                          message: "Please select a Category!",
                        },
                      ]}
                    >
                      <select
                        className="local-form input-reg"
                        onChange={selectCategoryType}
                      >
                        <option value="-1">Select Category Type</option>
                        <option value={0} key={1}>
                          Products
                        </option>
                        <option value={1} key={2}>
                          Services
                        </option>
                      </select>
                    </Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <CategoryWidget
                      name="ParentId"
                      categoryIndex={selectedCategoryType}
                      placeHolder="Select category"
                      allowMultiple={false}
                      allowChangeOnSelect={true}
                      mandatory={false}
                      // defaultValue={id}
                    ></CategoryWidget>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <Item name="Id" hidden={true}>
                      <input />
                    </Item>
                    <Item
                      name="CategoryName"
                      rules={[
                        {
                          required: true,
                          message:
                            "Please enter the title/name of the category!",
                        },
                      ]}
                    >
                      <input
                        maxLength={30}
                        className="local-form input-reg"
                        placeholder="Category title"
                      ></input>
                    </Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-3">
                    <button
                      type="button"
                      className="butn butn-block"
                      onClick={navigateAndNew}
                    >
                      New
                    </button>
                  </div>
                  <div className="col-lg-3">
                    <button
                      type="submit"
                      className="butn butn-block"
                      disabled={setupStatus === "createCategoryPending"}
                    >
                      <span id="button-text">
                        {setupStatus === "createCategoryPending" ? (
                          <Spin size="small" />
                        ) : (
                          "Save"
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-3">
                    <button
                      type="button"
                      className="butn butn-block"
                      disabled={listStatus === "deleteCategoryPending"}
                      onClick={() =>
                        dispatch(ListService.deleteCategory(selectedCategoryId))
                      }
                    >
                      <span id="button-text">
                        {listStatus === "deleteCategoryPending" ? (
                          <Spin size="small" />
                        ) : (
                          "Delete"
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="col-lg-3"></div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </>
  );
};
