import React, { Fragment } from "react";
import { Link } from "react-router-dom";

export const PageFooter = () => {
  return (
    <Fragment>
      <div className="footer-lg">
        <div className="footer-content">
          <div className="footer-details">
            <img src="./libs/style/images/BuySing_Color_Logo.png" alt="" />
            <p>
              BuySing is the first choice used by Hotel Owners/Operators,
              Architects/Designers, Procurement/Purchasing, Management, Catering
              Distributors, Spa & Wellness, System Integrators and Contractors.
              The platform is used to source new products and receive the latest
              news from this exciting industry.
            </p>
            <h3>Contact Details</h3>
            <p>
              <strong>Call Us:</strong>
              <br />
              +44 (0) 1392 576 940
              <br />
              <br />
              <strong>Address:</strong>
              <br />
              408 West Green Road,
              <br />
              London,
              <br />
              N15 3PX. UNITED KINGDOM
            </p>
            <br />
            <div className="socialbar">
              <a
                href="https://www.facebook.com/buysing.co.uk"
                target="_blank"
                rel="noreferrer"
              >
                <span className="mdi mdi-facebook"></span>
              </a>
              <a
                href="https://www.instagram.com/BUYSING.CO.UK"
                target="_blank"
                rel="noreferrer"
              >
                <span className="mdi mdi-instagram"></span>
              </a>
              <a
                href="https://www.linkedin.com/company/buysing/"
                rel="noreferrer"
              >
                <span className="mdi mdi-linkedin"></span>
              </a>
              <a
                href="https://twitter.com/BuysingUK"
                target="_blank"
                rel="noreferrer"
              >
                <span className="mdi mdi-twitter"></span>
              </a>
            </div>
          </div>
          <div className="contactForm">
            <h3>Contact BuySing</h3>
            <form action="" className="contact-vert-form">
              <div className="row">
                <div className="col-lg-12">
                  <input
                    maxLength={30}
                    type="text"
                    className="formControl"
                    placeholder="Full Name*"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <input
                    maxLength={15}
                    type="text"
                    className="formControl"
                    placeholder="Phone"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <input
                    maxLength={50}
                    type="text"
                    className="formControl"
                    placeholder="Email Address*"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <textarea
                    rows={5}
                    className="formControl"
                    placeholder="Your Message"
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <button className="button-block">Send Message</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="copyrights">
          All rights reserved 2021 BUYSING - Exclusive Sourcing{" "}
          <a href="/pdf/privacy-policy.pdf" target="_blank">
            <u>
              <b>Privacy Policy</b>
            </u>
          </a>
        </div>
      </div>
    </Fragment>
  );
};
