import { Steps, Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { QuotationStatus, UserType } from "../../../enums";
import { store } from "../../../store";
import { QuotationResponse } from "../../../types";

interface QuotationTimeLineProps {
  quotation: QuotationResponse;
}

// interface QuotationTimeLineProps {
//   inquiry: InquiryResponse;
// }

export const QuotationTimeLine = (props: QuotationTimeLineProps) => {
  const getCurrentStatus = () => {
    if (props.quotation.status === QuotationStatus.SENT) {
      return 0;
    } /*else if (props.quotation.status === QuotationStatus.RECEIVED) {
      return 1;
    }*/ else if (props.quotation.status === QuotationStatus.SHORTLISTED) {
      return 1;
    } else if (props.quotation.status === QuotationStatus.APPROVED) {
      return 1;
    } else if (props.quotation.status === QuotationStatus.ORDERED) {
      return 2;
    } else if (props.quotation.status === QuotationStatus.FULFILLED) {
      return 2;
    } else if (props.quotation.status === QuotationStatus.INVOICED) {
      return 3;
    } else if (props.quotation.status === QuotationStatus.COMPLETED) {
      return 4;
    }
    return 0;
  };

  const getActiveStatus = () => {
    if (props.quotation.status === QuotationStatus.SENT) {
      return 1;
    } /*else if (props.quotation.status === QuotationStatus.RECEIVED) {
      return 2;
    }*/ else if (props.quotation.status === QuotationStatus.SHORTLISTED) {
      return 2;
    } else if (props.quotation.status === QuotationStatus.APPROVED) {
      return 2;
    } else if (props.quotation.status === QuotationStatus.ORDERED) {
      return 3;
    } else if (props.quotation.status === QuotationStatus.FULFILLED) {
      return 3;
    } else if (props.quotation.status === QuotationStatus.INVOICED) {
      return 4;
    } else if (props.quotation.status === QuotationStatus.COMPLETED) {
      return -1;
    }
    return 0;
  };

  const getDisableStatus = (tabIndex: number) => {
    return Number(getActiveStatus()) >= tabIndex;
  };

  const dynamicTag = (tabIndex: number) => {
    if (getCurrentStatus() >= tabIndex) {
      return <Tag color="green">Finished</Tag>;
    } else {
      if (getActiveStatus() === tabIndex) {
        let navUrl: string = "#";
        if (props.quotation.status === QuotationStatus.SENT) {
          return (
            <Tooltip title="Waiting for quotation approval">
              <Tag color="red">Pending</Tag>
            </Tooltip>
          );
        } /*else if (props.quotation.status === QuotationStatus.RECEIVED) {
          return (
            <Tooltip title="Waiting for quotation to be shortlisted">
              <Tag color="red">Pending</Tag>
            </Tooltip>
          );
        }*/ else if (props.quotation.status === QuotationStatus.SHORTLISTED) {
          return (
            <Tooltip title="Waiting for quotation to be approved">
              <Tag color="red">Pending</Tag>
            </Tooltip>
          );
        } else if (props.quotation.status === QuotationStatus.APPROVED) {
          navUrl = "/inquiry/order";
          if (store.getState().auth.user.userType === UserType.CUSTOMER) {
            return (
              <Link to={navUrl} state={{ quotation: props.quotation }}>
                <Tag color="red">Pending</Tag>
              </Link>
            );
          } else {
            return (
              <Tooltip title="Waiting for customer to generate purchase order">
                <Tag color="red">Pending</Tag>
              </Tooltip>
            );
          }
        } else if (props.quotation.status === QuotationStatus.ORDERED) {
          return (
            <Tooltip title="Waiting for purchse order to be fulfilled">
              <Tag color="red">Pending</Tag>
            </Tooltip>
          );
        } else if (props.quotation.status === QuotationStatus.FULFILLED) {
          navUrl = "/inquiry/invoice";
          if (store.getState().auth.user.userType === UserType.SUPPLIER) {
            return (
              <Link to={navUrl} state={{ quotation: props.quotation }}>
                <Tag color="red">Pending</Tag>
              </Link>
            );
          } else {
            return (
              <Tooltip title="Waiting for supplier to generate invoice">
                <Tag color="red">Pending</Tag>
              </Tooltip>
            );
          }
        } else if (props.quotation.status === QuotationStatus.INVOICED) {
          navUrl = "/inquiry/receipt";
          if (store.getState().auth.user.userType === UserType.CUSTOMER) {
            return (
              <Link to={navUrl} state={{ quotation: props.quotation }}>
                <Tag color="red">Pending</Tag>
              </Link>
            );
          } else {
            return (
              <Tooltip title="Waiting for customer to acknowledge receipt">
                <Tag color="red">Pending</Tag>
              </Tooltip>
            );
          }
        }
        // if (store.getState().auth.user.userType === UserType.SUPPLIER) {
        //   return <Tag color="red">Pending</Tag>;
        // } else {
        //   return (
        //     <Link to={navUrl} state={{ quotation: props.quotation }}>
        //       <Tag color="red">Pending</Tag>
        //     </Link>
        //   );
        // }
        // if (navUrl === "#") {
        //   return (
        //     <Tooltip title="Approve quotation required">
        //       <Tag color="red">Pending</Tag>
        //     </Tooltip>
        //   );
        // } else {
        //   return (
        //     <Link to={navUrl} state={{ inquiry: inquiry }}>
        //       <Tag color="red">Pending</Tag>
        //     </Link>
        //   );
        // }
      } else {
        return <Tag color="red">Pending</Tag>;
      }
    }
  };
  return (
    <>
      <Steps progressDot current={getActiveStatus()}>
        <Steps.Step
          disabled={getDisableStatus(1)}
          description="Quotation Created"
          title={dynamicTag(0)}
        />
        <Steps.Step
          disabled={getDisableStatus(2)}
          description="Quotation Shortlisted"
          title={dynamicTag(1)}
        />
        <Steps.Step
          disabled={getDisableStatus(3)}
          description="Purchase Order Generated"
          title={dynamicTag(2)}
        />
        <Steps.Step
          disabled={getDisableStatus(4)}
          description="Invoice Created"
          title={dynamicTag(3)}
        />
        <Steps.Step
          disabled={getDisableStatus(5)}
          description="Receipt Acknowledged"
          title={dynamicTag(4)}
        />
      </Steps>
    </>
  );
};

// export const QuotationTimeLine = (props: QuotationTimeLineProps) => {
//   const getCurrentStatus = () => {
//     if (props.quotation.status === QuotationStatus.POSTED) {
//       return 0;
//     } else if (props.quotation.status === QuotationStatus.RECEIVED) {
//       return 1;
//     } else if (props.quotation.status === QuotationStatus.SHORTLISTED) {
//       return 1;
//     } else if (props.quotation.status === QuotationStatus.APPROVED) {
//       return 1;
//     } else if (props.quotation.status === QuotationStatus.ORDERED) {
//       return 2;
//     } else if (props.quotation.status === QuotationStatus.FULFILLED) {
//       return 2;
//     } else if (props.quotation.status === QuotationStatus.INVOICED) {
//       return 3;
//     } else if (props.quotation.status === QuotationStatus.COMPLETED) {
//       return 4;
//     }
//     return 0;
//   };

//   const getActiveStatus = () => {
//     if (props.quotation.status === QuotationStatus.POSTED) {
//       return 1;
//     } else if (props.quotation.status === QuotationStatus.RECEIVED) {
//       return 2;
//     } else if (props.quotation.status === QuotationStatus.SHORTLISTED) {
//       return 2;
//     } else if (props.quotation.status === QuotationStatus.APPROVED) {
//       return 2;
//     } else if (props.quotation.status === QuotationStatus.ORDERED) {
//       return 3;
//     } else if (props.quotation.status === QuotationStatus.FULFILLED) {
//       return 3;
//     } else if (props.quotation.status === QuotationStatus.INVOICED) {
//       return 4;
//     } else if (props.quotation.status === QuotationStatus.COMPLETED) {
//       return -1;
//     }
//     return 0;
//   };

//   const getDisableStatus = (tabIndex: number) => {
//     return Number(getActiveStatus()) >= tabIndex;
//   };

//   const dynamicTag = (tabIndex: number) => {
//     if (getCurrentStatus() >= tabIndex) {
//       return <Tag color="green">Finished</Tag>;
//     } else {
//       if (getActiveStatus() === tabIndex) {
//         let navUrl: string = "#";
//         if (props.quotation.status === QuotationStatus.POSTED) {
//           return (
//             <Tooltip title="Waiting for quotation">
//               <Tag color="red">Pending</Tag>
//             </Tooltip>
//           );
//         } else if (props.quotation.status === QuotationStatus.RECEIVED) {
//           return (
//             <Tooltip title="Waiting for quotation to be shortlisted">
//               <Tag color="red">Pending</Tag>
//             </Tooltip>
//           );
//         } else if (props.quotation.status === QuotationStatus.SHORTLISTED) {
//           return (
//             <Tooltip title="Waiting for quotation to be approved">
//               <Tag color="red">Pending</Tag>
//             </Tooltip>
//           );
//         } else if (props.quotation.status === QuotationStatus.APPROVED) {
//           navUrl = "/inquiry/order";
//         } else if (props.quotation.status === QuotationStatus.ORDERED) {
//           return (
//             <Tooltip title="Waiting for purchse order to be fulfilled">
//               <Tag color="red">Pending</Tag>
//             </Tooltip>
//           );
//         } else if (props.quotation.status === QuotationStatus.FULFILLED) {
//           navUrl = "/inquiry/invoice";
//         } else if (props.quotation.status === QuotationStatus.INVOICED) {
//           navUrl = "/inquiry/receipt";
//         }
//         if (store.getState().auth.user.userType === UserType.SUPPLIER) {
//           return <Tag color="red">Pending</Tag>;
//         } else {
//           return (
//             <Link to={navUrl} state={{ inquiry: props.inquiry }}>
//               <Tag color="red">Pending</Tag>
//             </Link>
//           );
//         }
//         // if (navUrl === "#") {
//         //   return (
//         //     <Tooltip title="Approve quotation required">
//         //       <Tag color="red">Pending</Tag>
//         //     </Tooltip>
//         //   );
//         // } else {
//         //   return (
//         //     <Link to={navUrl} state={{ inquiry: inquiry }}>
//         //       <Tag color="red">Pending</Tag>
//         //     </Link>
//         //   );
//         // }
//       } else {
//         return <Tag color="red">Pending</Tag>;
//       }
//     }
//   };
//   return (
//     <>
//       <Steps progressDot current={getActiveStatus()}>
//         <Steps.Step
//           disabled={getDisableStatus(1)}
//           description="Inquiry Created"
//           title={dynamicTag(0)}
//         />
//         <Steps.Step
//           disabled={getDisableStatus(2)}
//           description="Quotation Received"
//           title={dynamicTag(1)}
//         />
//         <Steps.Step
//           disabled={getDisableStatus(3)}
//           description="Purchase Order Generated"
//           title={dynamicTag(2)}
//         />
//         <Steps.Step
//           disabled={getDisableStatus(4)}
//           description="Invoice Created"
//           title={dynamicTag(3)}
//         />
//         <Steps.Step
//           disabled={getDisableStatus(5)}
//           description="Receipt Acknowledged"
//           title={dynamicTag(4)}
//         />
//       </Steps>
//     </>
//   );
// };
