import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, User } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { useState } from "react";
import { envConfig } from "../../../../envConfig";

export const useFireBase = () => {
  const app = initializeApp(envConfig.firebase);

  const [currentUser, setCurrentUser] = useState<User>();

  signInAnonymously(getAuth(app)).then((userCredential) => {
    setCurrentUser(userCredential.user);
  });

  return [getAuth(app), getFirestore(app), getDatabase(app)] as const;
};
