import { InquiryList, QuotationGrid } from "../../Components";
import { useState } from "react";
import { useAppSelector, useScrollToTop } from "../../../hooks";
import { Avatar, Col, Form, Rate, Row, Space, Statistic, Tabs } from "antd";
import config from "../../../config";
import * as routes from "../../../constants/routes";
import { store } from "../../../store";
import { InquiryStatus, QuotationStatus } from "../../../enums";
import { CustomerProfilePage } from "../../Profile";
import { EnvironmentOutlined, UserOutlined } from "@ant-design/icons";
import { SupplierList, SuppliersListPage } from "../../Supplier";
import { InquiryGrid } from "../../Components/inquiriesGrid";

const { TabPane } = Tabs;

export const CustomerHomePage = () => {
  const [listInquiryStatus, setListInquiryStatus] = useState("");

  const notifyInquiryStatusChanged = (key: any) => {
    setListInquiryStatus(key);
  };

  const { user } = useAppSelector((state) => state.auth);

  const { inquiries } = useAppSelector((state) => state.inquiry);

  const onFormSubmit = (formData: any) => {
    setListInquiryStatus(formData.status);
  };

  useScrollToTop();

  return (
    <>
      <div className="row">
        <div className="col-lg-3">
          <div className="row">
            <div className="col-lg-12" style={{ textAlign: "center" }}>
              {user.image ? (
                <Avatar
                  size={150}
                  src={
                    <img
                      alt="Logo"
                      src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${user?.image}&type=user`}
                    />
                  }
                  icon={<UserOutlined />}
                  className="mb-1"
                />
              ) : (
                <Avatar size={150} icon={<UserOutlined />} className="mb-1" />
              )}
            </div>
          </div>
          <div className="row">
            <br />
          </div>
          <div className="row">
            <Row gutter={16}>
              <Col span={12} style={{ textAlign: "center" }}>
                <Statistic
                  title="Active Quotations"
                  value={
                    inquiries.filter((i) => i.status === InquiryStatus.RECEIVED)
                      .length
                  }
                />
              </Col>
              <Col span={12} style={{ textAlign: "center" }}>
                <Statistic
                  title="Active Inquires"
                  value={
                    inquiries.filter((i) => i.status === InquiryStatus.POSTED)
                      .length
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="row">
            <div className="col-lg-12" style={{ textAlign: "center" }}>
              {/* <Rate disabled value={2} /> */}
            </div>
          </div>
          <div className="row">
            <span>&nbsp;</span>
          </div>
          <div className="row">
            <div className="col-lg-12" style={{ textAlign: "center" }}>
              <Space>
                <EnvironmentOutlined /> {user?.address}
              </Space>
            </div>
          </div>
        </div>

        <div className="col-lg-9">
          <Tabs type="card">
            <TabPane tab="Inquiries" key="1">
              <Form onFinish={onFormSubmit}>
                <div className="searchBlock">
                  <div className="searchingHeader">
                    <div className="container-fluid support-flex">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div
                          style={{
                            color: "rgb(250, 173, 20)",
                            fontSize: "25px",
                            fontWeight: "900",
                          }}
                        >
                          <div className="row">Use Search</div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="row">
                          <div className="col-lg-5 col-md-5 col-sm-5">
                            <Form.Item name="Search">
                              <input
                                maxLength={30}
                                type="search"
                                className="form-control input-sm my-search"
                              />
                            </Form.Item>
                          </div>
                          <div className="col-lg-5 col-md-5 col-sm-5">
                            <Form.Item name="status">
                              <select className="form-control input-sm">
                                {Object.values(InquiryStatus)
                                  .filter((s) => isNaN(Number(s)))
                                  .map((text, index) => (
                                    <option
                                      value={Object.keys(InquiryStatus).indexOf(
                                        String(index)
                                      )}
                                      key={index}
                                    >
                                      {text}
                                    </option>
                                  ))}
                              </select>
                            </Form.Item>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-2">
                            <button
                              type="submit"
                              className="btn btn-block btn-sm"
                              style={{ backgroundColor: "rgb(250, 173, 20)" }}
                              // disabled={
                              //   productStatus === "fetchAllProductsPending"
                              // }
                            >
                              <span id="button-text">
                                {/* {productStatus === "fetchAllProductsPending" ? (
                                  <Spin size="small" />
                                ) : ( */}
                                <i className="mdi mdi-magnify"></i>
                                {/* )} */}
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
              <InquiryList status={listInquiryStatus} />
              {/* <Row>
                <Col>
                  <Tabs
                    tabPosition="left"
                    onChange={notifyInquiryStatusChanged}
                  >
                    {Object.values(InquiryStatus)
                      .filter((s) => isNaN(Number(s)))
                      .map((text, index) => (
                        <TabPane
                          tab={String(text)}
                          key={Object.keys(InquiryStatus).indexOf(
                            String(index)
                          )}
                        ></TabPane>
                      ))}
                  </Tabs>
                </Col>
                <Col flex="auto">
                  <InquiryList status={listInquiryStatus} />
                </Col>
              </Row> */}
            </TabPane>
            <TabPane tab="My Inquiries" key="2">
              <InquiryGrid />
            </TabPane>
            <TabPane tab="Settings" key="3">
              <CustomerProfilePage />
            </TabPane>
            <TabPane tab="Qualified Suppliers" key="4">
              <SupplierList showFilterBar={false} showFavorites={true} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};
