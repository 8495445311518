import React from "react";
import { Layout } from "antd";
import { ErrorBoundary } from "./view";
import { store } from "./store";
import { Router } from "./router";
import ScrollToTop from "react-scroll-to-top";

const App = () => {
  // const [user, setUser] = useState<User>();
  /**
   * during application first load or refresh
   */


  return (
    <ErrorBoundary>
      <Layout id="app">
        <ScrollToTop smooth />
        <Router user={store.getState().auth.user!} />
      </Layout>
    </ErrorBoundary>
  );
};

export default App;
