import { envConfig } from "./envConfig";

/**
 * Application wide configuration.
 * The object are nested on basis of redux store
 */
const config = {
  env: process.env.NODE_ENV,
  baseURI: envConfig.url.API_URL,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  endpoints: {
    auth: {
      login: "/auth/login",
      createAccount: "/auth/register",
      activateAccount: "/auth/activate",
      logout: "/auth/logout",
      profile: "/auth/profile",
      sendNotify: "/auth/email",
      initPay: "/user/getPaymentIntent",
      updPay: "/user/updatePaymentStatus",
      forgotpassword: "/auth/forgotpassword",
      resetPassword: "/auth/resetpassword",
    },
    profile: {
      fetchProfile: "/users",
      fetchFavoriteSuppliers: "/user/getFavoriteSuppliers",
      createProfile: "/auth/editprofile",
    },
    supplier: {
      fetchSupplier: "/user/getSuppliers",
      fetchSupplierProducts: "/product/getSupplierProducts",
      createProduct: "/product/add",
      updateProduct: "/product/update",
    },
    services: {
      fetchServices: "/category/getall",
    },
    products: {
      fetchProducts: "/product/categoryProducts",
      fetchAllProducts: "/product/getall",
    },
    country: {
      fetchCountries: "/country/getall",
    },
    city: {
      fetchCities: "/city/getall",
    },
    setups: {
      createCoverageArea: "/areacoverage/add",
      updateCoverageArea: "/areacoverage/update",
      deleteCoverageArea: "/areacoverage/delete",
      createCountry: "/country/add",
      updateCountry: "/country/update",
      deleteCountry: "/country/delete",
      createCity: "/city/add",
      updateCity: "/city/update",
      deleteCity: "/city/delete",
      createCategory: "/category/add",
      updateCategory: "/category/update",
      deleteCategory: "/category/delete",
      deleteProduct: "/product/delete",
      fetchSubscriptions: "/setup/getAllSubscriptions",
      fetchActiveSubscriptions: "/setup/getActiveSubscriptions",
      fetchSubscriptionsForActivation: "/setup/getAllUserSubscriptions",
      activateUserSubscription: "/setup/activateUserSubscription",
      createSubscription: "/setup/addSubscription",
      updateSubscription: "/setup/updateSubscription",
      fetchCategories: "/category/getall",
      approveProduct: "/product/approve",
      rejectProduct: "/product/reject",
      fetchAllUsers: "/user/getUsers",
      updateUserStatus: "/user/updateStatus"
    },
    areacoverage: {
      fetchAreaCoverage: "/areacoverage/getall",
    },
    inquiry: {
      createInquiry: "/inquiry/add",
      updateInquiry: "/inquiry/update",
      createInvoice: "/invoice/add",
      fetchInquiry: "/inquiry/getall",
      fetchUserInquiry: "/inquiry/userinquiries",
      fetchSupplierInquiry: "/inquiry/get",
    },
    quotation: {
      createQuotation: "/quotation/add",
      updateQuotation: "/quotation/update",
      approveQuotation: "/quotation/approve",
      shortListQuotation: "/quotation/shortlist",
      fetchQuotationByInquiry: "/quotation/getByInquiry",
      fetchQuotations: "/quotation/getall",
      // fetchSupplierQuotations: '/quotation/supplierQuotations',
      fetchSupplierQuotation: "/quotation/supplierQuotations",
    },
    order: {
      createOrder: "/purchaseorder/add",
      fulfillOrder: "/purchaseorder/update",
      fetchPurchaseOrderByParams: "/purchaseorder/getbyparams",
    },
    invoice: {
      createInvoice: "/invoice/add",
      createFeedback: "/feedback/add",
    },
    file: {
      uploadFile: "/file/upload",
    },
  },
};

export default config;
