import { Carousel, Empty } from "antd";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../../../../config";
import {
  useAppDispatch,
  useAppSelector,
  useScrollToTop,
} from "../../../../hooks";
import * as SupplierService from "../../../../store/supplier/supplier.action";
import prodlogo from "../../../assets/supp-logo.png";
import * as routes from "../../../../constants/routes";

export const ProductsListPage = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const { selectedSupplier } = useAppSelector((state) => state.supplier);

  useEffect(() => {
    dispatch(SupplierService.fetchSupplierProducts(id!));
  }, [dispatch, id]);

  useScrollToTop();

  return (
    <>
      <section className="supOverall">
        <div className="row">
          <div className="col-lg-12">
            <div className="tabContent">
              <div id="Overview" className="tcontent">
                <h2>Supplier Overview</h2>
                <br />
                <p>
                  <b>
                    {selectedSupplier && selectedSupplier?.company?.companyName}
                  </b>
                </p>
                <p>{selectedSupplier?.company?.companyDetails}</p>
                <p>{selectedSupplier?.company?.companyOverview}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="supProducts" className="profilingSection centraSpacing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h3>Products</h3>
              <br />
            </div>
          </div>
          {(selectedSupplier?.products?.filter(
            (service) => service.category.categoryType === 1
          ).length || 0) === 0 && (
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
              </div>
            </div>
          )}
          <div className="row">
            {selectedSupplier &&
              selectedSupplier?.products &&
              selectedSupplier?.products
                ?.filter((product) => product.category.categoryType === 1)
                .map((product, index) => (
                  <div className="col-lg-3 col-md-6 col-sm-6" key={product.id}>
                    <div className="profileCard">
                      <div className="imgDisplay">
                        {product.images && product.images.length > 0 ? (
                          <img
                            src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${product.images[0].fileName}&type=media`}
                            alt="Product Logo"
                          />
                        ) : (
                          <img src={prodlogo} alt="Product Logo" />
                        )}
                      </div>
                      <div className="textDesc">
                        <p>
                          <b>{product.title}</b>
                        </p>
                      </div>
                      <div className="controlArea">
                        <Link
                          to={routes.SUPPLIER_STORE}
                          state={{ product: product }}
                          className="button-block-outlined"
                        >
                          View Product
                        </Link>
                        <Link
                          className="button-block"
                          to={`/inquiry/product`}
                          state={{ product: product }}
                        >
                          Send Inquiry
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </section>

      <section id="supProducts" className="profilingSection centraSpacing">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h3>Services</h3>
              <br />
            </div>
          </div>
          {(selectedSupplier?.products?.filter(
            (service) => service.category.categoryType === 2
          ).length || 0) === 0 && (
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
              </div>
            </div>
          )}
          <div className="row">
            {selectedSupplier &&
              selectedSupplier?.products &&
              selectedSupplier?.products
                ?.filter((service) => service.category.categoryType === 2)
                .map((service, index) => (
                  <div className="col-lg-3 col-md-6 col-sm-6" key={service.id}>
                    <div className="profileCard">
                      <div className="imgDisplay">
                        {service.images && service.images.length > 0 ? (
                          <img
                            src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${service.images[0].fileName}&type=media`}
                            alt="Product Logo"
                          />
                        ) : (
                          <img src={prodlogo} alt="Product Logo" />
                        )}
                      </div>
                      <div className="textDesc">
                        <p>
                          <b>{service.description}</b>
                        </p>
                      </div>
                      <div className="controlArea">
                        <Link
                          to={routes.SUPPLIER_STORE}
                          state={{ product: service }}
                          className="button-block-outlined"
                        >
                          View Product
                        </Link>
                        <Link
                          className="button-block"
                          to={`/inquiry/product`}
                          state={{ product: service }}
                        >
                          Send Inquiry
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </section>

      <section id="supMedia" className="supplierMedia">
        <div className="container-fluid centraSpacing">
          <div className="row">
            <div className="col-lg-12">
              <h3>Media</h3>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-12">
              <h4>Photo Gallery</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              {(selectedSupplier?.company?.companyMedia?.filter(
                (m) => m.mediaType === "IMAGE"
              ).length || 0) === 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
              )}
            </div>
            <div className="col-lg-9">
              {(selectedSupplier?.company?.companyMedia?.filter(
                (m) => m.mediaType === "IMAGE"
              ).length || 0) > 0 && (
                <div className="supplierPhotogallery">
                  <Carousel autoplay>
                    {selectedSupplier?.company?.companyMedia
                      ?.filter((m) => m.mediaType === "IMAGE")
                      .map((m, index) => (
                        <div className="supplierPhotogallery">
                          <img
                            className="markImg"
                            src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}${m.media}&type=media`}
                            alt={`Product ${index}`}
                          />
                        </div>
                      ))}
                  </Carousel>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <section id="supMedia" className="supplierMedia">
        <div className="container-fluid centraSpacing">
          <div className="row">
            <div className="col-lg-12">
              <h4>Video Montage</h4>
            </div>
          </div>
          <br />
          <div className="row">
            {(selectedSupplier?.company?.companyMedia?.filter(
              (m) => m.mediaType === "VIDEO"
            ).length || 0) === 0 && (
              <div className="col-lg-4">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
              </div>
            )}

            {selectedSupplier?.company?.companyMedia
              ?.filter((m) => m.mediaType === "VIDEO")
              .map((m, index) => (
                <div className="col-lg-4">
                  <a href={m.media} target="_blank" rel="noreferrer">
                    <video className="thumbnail" controls>
                      <source src={m.media} />
                      Your browser does not support the video tag.
                    </video>
                  </a>
                  {/* <a
                      href={m.media}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="markImg"
                        src={`${config.baseURI}${routes.DOWNLOAD_IMAGE}vid1.jpg&type=media`}
                        alt="Video 1"
                      />
                    </a> */}
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};
