import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Layout,
  Select,
  DatePicker,
  InputNumber,
  notification,
  Spin,
} from "antd";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import TextEditor from "../../Components/textEditor";
import { CategoryWidget, PreLoader, UploadDragDrop } from "../../Components";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import * as ListService from "../../../store/lists/list.action";
import { AreaCoverageDropDown } from "../../Components/areaCoverageList";
import * as InquiryService from "../../../store/inquiry/inquiry.actions";
import { useLocation, useParams } from "react-router-dom";
import {
  clearInquiryError,
  clearInquiryStatus,
} from "../../../store/inquiry/inquiry.slice";
import moment from "moment";

const { Content } = Layout;
const { Item } = Form;
const { Option } = Select;

interface SendInquiryPageProps {
  mode?: string;
}

export const SendInquiryPage = (props: SendInquiryPageProps) => {
  const location = useLocation();

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const { status: inquiryStatus, error: inquiryError } = useAppSelector(
    (state) => state.inquiry
  );

  const { product } = (location.state as any) || {};

  const { supplier } = (location.state as any) || {};

  const { inquiry } = (location.state as any) || {};

  const { id } = useParams();

  const {
    status: listStatus,
    error: listError,
    entities,
  } = useAppSelector((state) => state.list);

  const onFormSubmit = (values: any) => {
    if (location.pathname.includes("/inquiry/edit/")) {
      dispatch(InquiryService.updateInquiry(values));
    } else {
      dispatch(InquiryService.createInquiry(values));
    }
  };

  useEffect(() => {
    if (inquiryError) {
      displayErrorMessage(inquiryError);
      dispatch(clearInquiryError());
    }
  }, [inquiryError]);

  useEffect(() => {
    if (inquiryStatus === "createInquiryResolved") {
      displaySuccessNotification("Inquiry has been submitted");

      form.resetFields();

      dispatch(clearInquiryStatus());
    }

    if (inquiryStatus.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [inquiryStatus]);

  const getInquiryAreaCoveragesArray = (): number[] => {
    return Array.from(
      inquiry.inquiryAreaCoverage.map((i: any) => i.areaCoverageId)
    );
  };

  useEffect(() => {
    if (supplier) {
      form.setFieldsValue({
        SupplierId: supplier.company.id!,
      });
    }

    if (product) {
      form.setFieldsValue({
        ProductId: product.id!,
      });
    }

    if (inquiry) {
      form.setFieldsValue({
        Id: id,
        Title: inquiry.title,
        ScopeOfWork: inquiry.scopeOfWork,
        InquiryAreaCoverages: getInquiryAreaCoveragesArray(),
        LastDate: moment(inquiry.lastDate),
        ShortListQuotations: inquiry.shortListQuotations,
        MinimumBudget: inquiry.minimumBudget,
        MaximumBudget: inquiry.maximumBudget,
        SupplierId: inquiry.supplierId,
        SroductId: inquiry.productId,
      });
    }
  }, [location, product, supplier, inquiry]);

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="USD" selected>
          $
        </Option>
        <Option value="CNY">¥</Option>
      </Select>
    </Form.Item>
  );

  const onCategoryChange = (categoryId: Array<any>) => {
    dispatch(
      ListService.fetchCategoryProducts(categoryId[categoryId.length - 1])
    );
  };

  useScrollToTop();

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormSubmit}
        onFinishFailed={onFinishFailed}
        size="large"
      >
        <div className="row">
          <div className="col-lg-9">
            <h3>Send Inquiry</h3>
            <br />
            <p>
              Please Fill all the field to complete information about the
              Inquiry for the suppliers you are looking for.
            </p>
          </div>
        </div>

        <div className="row formRow">
          <div className="col-lg-12">
            <Item name="Id" hidden={true}>
              <input />
            </Item>
            <Item
              name="Title"
              label="Project Title"
              rules={[
                {
                  required: true,
                  message: "Please enter what defines your Project Title!",
                },
              ]}
            >
              <input
                maxLength={50}
                className="local-form input-reg"
                placeholder="Project Title"
              />
            </Item>
          </div>
        </div>

        <div className="row formRow">
          <div className="col-lg-12">
            <Item
              name="ScopeOfWork"
              label="Scope of work"
              rules={[
                {
                  required: true,
                  message: "Please enter what defines your Project Scope!",
                },
                ({ getFieldValue }) => ({
                  validator(_rule, value) {
                    if (!value || !value.includes("<script>")) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Invalid input found!");
                  },
                }),
              ]}
            >
              {/* @ts-ignore */}
              <TextEditor />
            </Item>
          </div>
        </div>

        <div className="row formRow">
          <div className="col-lg-6">
            {/* <GoogleLocation formRef={form} /> */}
            <AreaCoverageDropDown
              itemName="InquiryAreaCoverages"
              isRequired={false}
              itemLabel="Location"
            />
          </div>

          <div className="col-lg-3">
            <Item
              name="LastDate"
              label="Date of service"
              rules={[
                {
                  required: true,
                  message: "Please enter what defines your Date of service!",
                },
              ]}
            >
              {/* @ts-ignore */}
              <DatePicker format="YYYY-MM-DD" />
            </Item>
          </div>
          <div className="col-lg-3">
            <Item
              name="ShortListQuotations"
              label="Number of ShortList Quotations"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter how many Quotations are allowed to ShortList!",
                },
              ]}
            >
              <Input />
            </Item>
          </div>
        </div>

        <div className="row formRow">
          <div className="col-lg-6">
            <Item
              name="MinimumBudget"
              label="MinimumBudget"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter what defines your Project Minimum Budget!",
                },
              ]}
            >
              <InputNumber
                // addonAfter={suffixSelector}
                style={{ width: "100%" }}
              />
            </Item>
          </div>
          <div className="col-lg-6">
            <Item
              name="MaximumBudget"
              label="MaximumBudget"
              rules={[
                {
                  required: true,
                  message: " enter what defines your Project Maximum Budget!",
                },
              ]}
            >
              <InputNumber
                // addonAfter={suffixSelector}
                style={{ width: "100%" }}
              />
            </Item>
          </div>
        </div>

        {!product && (
          <div className="row formRow">
            <div className="col-lg-6">
              <CategoryWidget
                name="ServiceInquiryCategories"
                categoryIndex={1}
                placeHolder="Please select service categories"
                onCategoryChange={onCategoryChange}
                allowMultiple={true}
                allowChangeOnSelect={true}
              ></CategoryWidget>
            </div>
            <div className="col-lg-6">
              <CategoryWidget
                name="ProductInquiryCategories"
                categoryIndex={0}
                placeHolder="Please select product categories"
                allowMultiple={true}
                allowChangeOnSelect={true}
              ></CategoryWidget>
            </div>
          </div>
        )}

        <div className="row formRow">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <UploadDragDrop ItemName="ImageFiles" type="inquiry" />
          </div>
          <div className="col-lg-3"></div>
        </div>

        <div className="row formRow">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <button
              type="submit"
              className="butn butn-block"
              disabled={inquiryStatus === "createInquiryPending"}
            >
              <span id="button-text">
                {inquiryStatus === "createInquiryPending" ? (
                  <Spin size="small" />
                ) : (
                  "Submit Inquiry"
                )}
              </span>
            </button>
          </div>
          <div className="col-lg-3"></div>
        </div>

        <Item name="SupplierId" hidden={true}>
          <input />
        </Item>
        <Item name="ProductId" hidden={true}>
          <input />
        </Item>
      </Form>
    </>
  );
};
