import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_live_51M9uhFFIm1gLgCP9aoiPlp3jD75zXwcGYBehrLR5v8X5lLtUAC1lM1901lD6nMDF7MALuoiJtas7ILxDRg99kRsO00Fy5MuEzG"
    );
  }
  return stripePromise;
};

export default getStripe;
