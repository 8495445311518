import React, { ReactNode } from "react";
import { Fragment, ReactChild } from "react";
import {
  SupplierHomePage,
  BreadCrumb,
  MenuNavigator,
  PageBanner,
  PageFooter,
  ChatApp,
  SupplierPageBanner,
} from "../../../view";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  ProductsListPage,
  SuppliersListPage,
  ProductViewPage,
  SupplierProductsPage,
  SupplierServicesPage
} from "../../../view";
import { CustomerHomePage } from "../../Customer";
import buysingColorlogo from "../../assets/BuySing_Color_Logo.png";
import { AdminDashBoardPage } from "../../Admin";
import { CategoryProductPage } from "../../Home/Categories";
import { SubscriptionPage } from "../../Account/Subscription";

const ContentLayout = (props: {
  children: ReactChild | ReactNode | null | undefined;
}) => {
  return (
    <>
      <MenuNavigator buysing_logo={buysingColorlogo} fonts_color="black" />
      <PageBanner />
      <BreadCrumb />
      <section className="profilingSection card">
        <div className="container-fluid">
          <main>{props.children}</main>
        </div>
      </section>
      <PageFooter />
    </>
  );
};

const SupplierContentLayout = (props: {
  children: ReactChild | ReactNode | null | undefined;
}) => {
  return (
    <>
      <MenuNavigator buysing_logo={buysingColorlogo} fonts_color="black" />
      <SupplierPageBanner />
      <BreadCrumb />
      <section className="profilingSection card">
        <div className="container-fluid">
          <main>{props.children}</main>
        </div>
      </section>
      <PageFooter />
    </>
  );
};

export const ContentNavigation = () => {

  return (
    <>
      <ContentLayout>
        <Routes>
          <Route path="/supplier/list" element={<SuppliersListPage />} />
          {/* <Route path="/supplier/list/:id" element={<ProductsListPage />} />
          <Route path="/supplier/store" element={<ProductViewPage />} />
          <Route path="/supplier/products" element={<SupplierProductsPage />} />
          <Route path="/supplier/services" element={<SupplierServicesPage />} /> */}
          <Route path="/category/:id" element={<CategoryProductPage />}></Route>
          <Route path="*" element={<Navigate to="/404" replace />} />
          {/* <Route path="/supplier/product/add" element={<AddProductServicePage categoryIndex={0} />} /> */}
        </Routes>
      </ContentLayout>
    </>
  );
};

export const SupplierContentNavigation = () => {

  return (
    <>
      <SupplierContentLayout>
        <Routes>
          <Route path="/list/:id" element={<ProductsListPage />} />
          <Route path="/store" element={<ProductViewPage />} />
          <Route path="/products" element={<SupplierProductsPage />} />
          <Route path="/services" element={<SupplierServicesPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
          {/* <Route path="/supplier/product/add" element={<AddProductServicePage categoryIndex={0} />} /> */}
        </Routes>
      </SupplierContentLayout>
    </>
  );
};

export const ProtectedContentNavigation = () => {
  const location = useLocation();

  return (
    <>
      <ContentLayout>
        <Routes>
          <Route path="/customer/home" element={<CustomerHomePage />} />
          <Route path="/supplier/home" element={<SupplierHomePage />} />
          {/* <Route path="/project/timeline/:id" element={<TimeLinePage />} /> */}
          <Route path="/chat/app" element={<ChatApp />}></Route>
          {/* <Route path="/supplier/chat" element={<UserChatRoom />}></Route> */}

          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </ContentLayout>
    </>
  );
};

export const ProtectedAdminContentNavigation = () => {
  const location = useLocation();

  return (
    <>
      <ContentLayout>
        <Routes>
          <Route path="/home" element={<AdminDashBoardPage />} />
          <Route path="/products" element={<AdminDashBoardPage />} />
          <Route path="/country" element={<AdminDashBoardPage />} />
          <Route path="/city" element={<AdminDashBoardPage />} />
          <Route path="/area" element={<AdminDashBoardPage />} />
          <Route path="/category" element={<AdminDashBoardPage />} />
          <Route path="/users" element={<AdminDashBoardPage />} />
          <Route path="/subscription" element={<AdminDashBoardPage />} />
          <Route path="/area/edit/:id" element={<AdminDashBoardPage />} />
          <Route path="/country/edit/:id" element={<AdminDashBoardPage />} />
          <Route path="/city/edit/:id" element={<AdminDashBoardPage />} />
          <Route path="/category/edit/:id" element={<AdminDashBoardPage />} />
          <Route path="/subscription/edit/:id" element={<AdminDashBoardPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </ContentLayout>
    </>
  );
};
