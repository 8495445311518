import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import { Form, notification } from "antd";
import { CreateProfileData } from "../../../types";
import * as AuthService from "../../../store/auth/auth.actions";
import { ProfilePage } from "../Common";
import {
  clearAuthError,
  clearAuthStatus,
} from "../../../store/auth/auth.slice";
import { PreLoader } from "../../Components";

export const CustomerProfilePage = () => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const { status, error } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (status === "userProfileRejected" && error) {
      displayErrorMessage(error);
      dispatch(clearAuthError());
    }

    if (status === "userProfileUpdated" && !error) {
      displaySuccessNotification("Profile has been updated");

      dispatch(AuthService.loadCurrentUser());

      dispatch(clearAuthStatus());
    }

    if (status.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [status, error]);

  const onFormSubmit = (values: CreateProfileData) => {
    dispatch(AuthService.createProfile(values));
  };

  const onFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFormSubmit}
        onFinishFailed={onFinishFailed}
        size="large"
      >
        <div className="row">
          <div className="col-lg-12">
            <h3>Customer/ Hotel Information</h3>
            <br />
            <p>
              Please Fill all the field to complete your registration profile.
            </p>
          </div>
        </div>
        <ProfilePage isNested={false} formRef={form} />
      </Form>
    </>
  );
};
