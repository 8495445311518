import React from "react";
import { useEffect, useState } from "react";
import { PageFooter } from "../../view";
import bk from "../assets/hm-bk-2.webp";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../hooks";
import * as SupplierService from "../../store/supplier/supplier.action";
import { SupplierList } from "../Supplier/Components/SupplierList";
import { Form, Input, Select } from "antd";

import { SiteNavigator } from "../Layout";
import userEvent from "@testing-library/user-event";
import { UserType } from "../../enums";

const { Option } = Select;

export const HomePage = () => {
  const dispatch = useAppDispatch();

  const initSearch = (searchText: string) => {
    let paramString = `?searchText=${searchText}`;

    if (areaCoverage) {
      paramString += `&areaCoveraage=${areaCoverage}`;
    }

    dispatch(SupplierService.fetchSupplier(paramString));
  };

  const { user } = useAppSelector((state) => state.auth);

  const {
    status: listStatus,
    error: listError,
    entities: listEntities,
  } = useAppSelector((state) => state.list);

  const [areaCoverage, setAreaCoverage] = useState<number>();

  useEffect(() => {
    dispatch(SupplierService.fetchSupplier(""));
  }, [dispatch]);

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select
        style={{ width: "150px" }}
        placeholder="City"
        onChange={setAreaCoverage}
      >
        {listEntities.areaCoverages?.map((area, index) => (
          <Option value={area.id} key={index}>
            {area.city}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  useScrollToTop();

  return (
    <>
      <SiteNavigator />

      <header className="mainHead">
        <div
          id="carousel-example-generic"
          className="carousel slide"
          data-ride="carousel"
        >
          <ol className="carousel-indicators">
            <li
              data-target="#carousel-example-generic"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carousel-example-generic" data-slide-to="1"></li>
            <li data-target="#carousel-example-generic" data-slide-to="2"></li>
            <li data-target="#carousel-example-generic" data-slide-to="3"></li>
            <li data-target="#carousel-example-generic" data-slide-to="4"></li>
          </ol>

          <div className="carousel-inner" role="listbox">
            <div className="item active">
              <img src="./libs/style/images/cour1.jpg" alt="bannar-01" />
            </div>
            <div className="item">
              <img src="./libs/style/images/cour2.jpg" alt="bannar-02" />
            </div>
            <div className="item">
              <img src="./libs/style/images/cour3.jpg" alt="bannar-03" />
            </div>
            <div className="item">
              <img src="./libs/style/images/cour4.jpg" alt="bannar-04" />
            </div>
            <div className="item">
              <img src="./libs/style/images/cour5.jpg" alt="bannar-05" />
            </div>
          </div>

          <a
            className="left carousel-control"
            href="#carousel-example-generic"
            role="button"
            data-slide="prev"
          >
            <span
              className="glyphicon glyphicon-chevron-left"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="right carousel-control"
            href="#carousel-example-generic"
            role="button"
            data-slide="next"
          >
            <span
              className="glyphicon glyphicon-chevron-right"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </header>

      {/* {user && user.userType === UserType.CUSTOMER && ( */}
      <SupplierList
        showFilterBar={false}
        showFavorites={false}
        showControls={false}
      ></SupplierList>
      {/* )} */}

      <PageFooter />
    </>
  );
};
