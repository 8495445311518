import { Form, notification, Spin } from "antd";
import { PreLoader, UploadPictureWall } from "../../Components";
import { useAppDispatch, useAppSelector, useScrollToTop } from "../../../hooks";
import { useEffect, useState } from "react";
import {
  displayErrorMessage,
  displayLoadingMessage,
  displaySuccessNotification,
} from "../../../utils/notifications";
import { CreateSupplierProductData, Product } from "../../../types";
import * as SupplierService from "../../../store/supplier/supplier.action";
import { CategoryWidget } from "../../Components";
import {
  clearSupplierStatus,
  clearSupplierError,
} from "../../../store/supplier/supplier.slice";
import TextEditor from "../../Components/textEditor";
import { useLocation } from "react-router-dom";

const { Item } = Form;

interface AddProductPageProps {
  categoryIndex: number;
}

export const AddProductPage = (props: AddProductPageProps) => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const [form] = Form.useForm();

  const { status, error } = useAppSelector((state) => state.supplier);

  const [clearImageState, setClearImageState] = useState<boolean>(false);

  const onProductFormSubmit = (values: CreateSupplierProductData) => {
    if (location.pathname.endsWith("/product/edit")) {
      dispatch(SupplierService.updateProduct(values));
    } else {
      dispatch(SupplierService.createProduct(values));
    }
  };

  const { product } = (location.state as any) || {};

  useEffect(() => {
    if (error) {
      displayErrorMessage(error);
      dispatch(clearSupplierError());
    }
    if (status === "createProductSuccess") {
      displaySuccessNotification("New product has been added");

      form.resetFields();

      setClearImageState(true);

      dispatch(clearSupplierStatus());
    }

    if (status === "updateProductSuccess") {
      displaySuccessNotification("Product has been updated");

      form.resetFields();

      setClearImageState(true);

      dispatch(clearSupplierStatus());
    }

    if (status.includes("Pending")) {
      displayLoadingMessage(<PreLoader />);
    } else {
      notification.close("loading");
    }
  }, [dispatch, status, error]);

  const onProductFinishFailed = () => {
    displayErrorMessage("Please complete all required form fields!");
    return;
  };

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        Id: product.id,
        EditCategoryId: product.category.categoryId,
        Title: product.title,
        SubTitle: product.subTitle,
        Description: product.description,
        Specification: product.specification,
        Features: product.features,
      });
    }
  }, [location, product]);

  useScrollToTop();

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onProductFormSubmit}
        onFinishFailed={onProductFinishFailed}
        size="large"
      >
        <div className="row">
          <div className="col-lg-12">
            <h3>Your Products</h3>
            <br />
            <p>
              Please Fill all the field to complete information about the
              products you offer.
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            {/* CATEGORY INPUT */}
            {location.pathname.endsWith("add") && (
              <div className="row">
                <div className="col-lg-3"></div>
                <div className="col-lg-6" style={{ textAlign: "center" }}>
                  <CategoryWidget
                    name={"CategoryId"}
                    categoryIndex={0}
                    placeHolder="Select the product category"
                    allowMultiple={false}
                    allowChangeOnSelect={false}
                  ></CategoryWidget>
                </div>
                <div className="col-lg-3"></div>
              </div>
            )}

            {/* TITLE SUB TITLE INPUT */}
            <div className="row">
              <div className="col-lg-6">
                <Item name="Id" hidden={true}>
                  <input />
                </Item>
                <Item name="EditCategoryId" hidden={true}>
                  <input />
                </Item>
                <Item
                  noStyle
                  name="Title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter what defines your prdouct title!",
                    },
                  ]}
                >
                  <input
                    maxLength={30}
                    className="local-form input-reg"
                    placeholder="Please enter what defines your product title!"
                  />
                </Item>
              </div>

              <div className="col-lg-6">
                <Item
                  noStyle
                  name="SubTitle"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your product subTitle!",
                    },
                  ]}
                >
                  <input
                    maxLength={50}
                    className="local-form input-reg"
                    placeholder="Please enter what defines your product subTitle!"
                  />
                </Item>
              </div>
            </div>

            <div className="row">
              <br />
            </div>

            {/* DESCRIPTION SPECS INPUT */}
            <div className="row">
              <div className="col-lg-12">
                <Item
                  noStyle
                  name="Description"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your product description!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_rule, value) {
                        if (!value || !value.includes("<script>")) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Invalid input found!");
                      },
                    }),
                  ]}
                >
                  {/* @ts-ignore */}
                  <TextEditor placeholder="Product Description" />
                </Item>
              </div>
            </div>

            <div className="row">
              <br />
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Item
                  noStyle
                  name="Specification"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your product specifications!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_rule, value) {
                        if (!value || !value.includes("<script>")) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Invalid input found!");
                      },
                    }),
                  ]}
                >
                  {/* @ts-ignore */}
                  <TextEditor placeholder="Product Specifications" />
                </Item>
              </div>
            </div>

            <div className="row">
              <br />
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Item
                  noStyle
                  name="Features"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter what defines your product features!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_rule, value) {
                        if (!value || !value.includes("<script>")) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Invalid input found!");
                      },
                    }),
                  ]}
                >
                  {/* @ts-ignore */}
                  <TextEditor placeholder="Product Features" />
                </Item>
              </div>
            </div>

            <div className="row">
              <br />
            </div>

            {/* DESCRIPTION SPECS INPUT */}
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6" style={{ textAlign: "center" }}>
                <UploadPictureWall
                  ItemName="ImageFiles"
                  buttonText="Product Images"
                  type="media"
                  clearState={clearImageState}
                ></UploadPictureWall>
              </div>
              <div className="col-lg-3"></div>
            </div>

            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <button
                  type="submit"
                  className="butn butn-block"
                  disabled={status === "createProductPending"}
                >
                  <span id="button-text">
                    {status === "createProductPending" ? (
                      <Spin size="small" />
                    ) : (
                      "Save"
                    )}
                  </span>
                </button>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};
