import React, { ReactChild, ReactNode } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AccountActivatePage, LoginPage, SignupPage } from "../../Login";
import { MenuNavigator } from "../Sections";
import buysingWhitelogo from "../../assets/BuySing_white_Logo.png";
import { ForgetPasswordPage } from "../../Account/ForgetPassword";
import { ResetPasswordPage } from "../../Account/ResetPassword";

const SignupLayout = (props: {
  children: ReactChild | ReactNode | null | undefined;
}) => {
  const location = useLocation();
  let bgcover = "signupCover";

  if (location.pathname.endsWith("/login")) {
    bgcover = "loginCover";
  } else {
    bgcover = "signupCover";
  }

  return (
    <>
      <header className="mainHead">
        <MenuNavigator buysing_logo={buysingWhitelogo} fonts_color="white" />
        <div className={`coverBgImg ${bgcover}`}>
          <div className="centralForm-sm">
            <div className="container-fluid">
              <main>{props.children}</main>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export const SignupNavigation = () => {
  // const { setToken } = useToken();

  return (
    <>
      <SignupLayout>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/forgetpwd" element={<ForgetPasswordPage />} />
          <Route path="/resetpwd" element={<ResetPasswordPage />} />
          <Route path="/activate" element={<AccountActivatePage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </SignupLayout>
    </>
  );
};
