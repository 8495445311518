import { useScrollToTop } from "../../../../hooks";
import { SupplierList } from "../../Components/SupplierList";

export const SuppliersListPage = () => {
  useScrollToTop();

  return (
    <>
      {/* {entities.suppliers && entities.suppliers.length > 0 && ( */}
        <SupplierList showFilterBar={true} showFavorites={false}></SupplierList>
      {/* )} */}
    </>
  );
};
