import { createSlice } from "@reduxjs/toolkit";
import { QuotationState } from "../../types";
import {
  createFeedback,
  createInvoice,
  createOrder,
} from "../quotation/quotation.actions";
import * as QuotationService from "./quotation.actions";
import * as Util from "../../utils/helper";

export const initialState: QuotationState = Object.freeze({
  status: "idle",
  quotations: [],
  error: "",
});

const quotationSlice = createSlice({
  name: "quotation",
  initialState,
  reducers: {
    clearQuoteError: (state) => {
      state.error = "";
    },
    clearQuotationStatus: (state) => {
      state.status = "idle";
    },
    resetQuoteState: (state) => {
      state = {
        status: "idle",
        quotations: [],
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      QuotationService.fetchQuotationByInquiry.pending,
      (state) => {
        state.status = "fetchQuotationPending";
      }
    );

    builder.addCase(
      QuotationService.fetchQuotationByInquiry.fulfilled,
      (state, { payload }) => {
        state.status = "fetchQuotationResolved";

        state.quotations = [];

        for (let index = 0; index < payload.length; index++) {
          state.quotations.push(payload[index]);
        }

        state.error = "";
      }
    );

    // builder.addCase(fetchPurchaseOrderByParams.fulfilled, (state, { payload }) => {
    //   state.status = "fetchPurchaseOrderByParamsResolved";

    //   state.quotations = [];

    //   for (let index = 0; index < payload.length; index++) {
    //     state.quotations.push(payload[index]);
    //   }

    //   state.error = "";
    // });

    builder.addCase(
      QuotationService.fetchQuotationByInquiry.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);
        state.status = "fetchQuotationRejected";
      }
    );

    builder.addCase(QuotationService.createQuotation.pending, (state) => {
      state.status = "createQuotationPending";
    });
    builder.addCase(
      QuotationService.createQuotation.fulfilled,
      (state, { payload }) => {
        // state.quotation = payload;
        state.status = "createQuotationResolved";
        state.error = "";
      }
    );
    builder.addCase(
      QuotationService.createQuotation.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createQuotationRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(QuotationService.updateQuotation.pending, (state) => {
      state.status = "createQuotationPending";
    });
    builder.addCase(
      QuotationService.updateQuotation.fulfilled,
      (state, { payload }) => {
        // state.quotation = payload;
        state.status = "createQuotationResolved";
        state.error = "";
      }
    );
    builder.addCase(
      QuotationService.updateQuotation.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createQuotationRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(
      QuotationService.fetchSupplierQuotations.fulfilled,
      (state, { payload }) => {
        state.status = "fetchSupplierQuotationsResolved";

        state.quotations = [];

        for (let index = 0; index < payload.length; index++) {
          state.quotations.push(payload[index]);
        }
      }
    );

    builder.addCase(
      QuotationService.fetchSupplierQuotations.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);
        state.status = "fetchSupplierQuotationsRejected";
      }
    );

    builder.addCase(QuotationService.approveQuotation.pending, (state) => {
      state.status = "approveQuotationPending";
    });

    builder.addCase(
      QuotationService.approveQuotation.fulfilled,
      (state, { payload }) => {
        state.status = "approveQuotationResolved";

        state.error = "";
      }
    );

    builder.addCase(
      QuotationService.approveQuotation.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);
        state.status = "approveQuotationRejected";
      }
    );

    builder.addCase(QuotationService.shortListQuotation.pending, (state) => {
      state.status = "shortListQuotationPending";
    });

    builder.addCase(
      QuotationService.shortListQuotation.fulfilled,
      (state, { payload }) => {
        state.status = "shortListQuotationResolved";

        state.error = "";
      }
    );

    builder.addCase(
      QuotationService.shortListQuotation.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);
        state.status = "shortListQuotationRejected";
      }
    );

    builder.addCase(QuotationService.purchaseOrderFulfill.pending, (state) => {
      state.status = "purchaseOrderFulfillPending";
    });

    builder.addCase(
      QuotationService.purchaseOrderFulfill.fulfilled,
      (state, { payload }) => {
        state.status = "purchaseOrderFulfillResolved";

        state.error = "";
      }
    );

    builder.addCase(
      QuotationService.purchaseOrderFulfill.rejected,
      (state, { payload }: { payload: any }) => {
        state.error = Util.parseErrorMessage(payload);
        state.status = "purchaseOrderFulfillRejected";
      }
    );

    builder.addCase(createOrder.pending, (state) => {
      state.status = "createOrderPending";
    });

    builder.addCase(createOrder.fulfilled, (state, { payload }) => {
      // state.inquiry = payload;
      state.status = "createOrderResolved";
      state.error = "";
    });

    builder.addCase(
      createOrder.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createOrderRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(createInvoice.pending, (state) => {
      state.status = "createInvoicePending";
    });

    builder.addCase(createInvoice.fulfilled, (state, { payload }) => {
      // state.inquiry = payload;
      state.status = "createInvoiceResolved";
      state.error = "";
    });

    builder.addCase(
      createInvoice.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createInvoiceRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );

    builder.addCase(createFeedback.pending, (state) => {
      state.status = "createFeedbackPending";
    });

    builder.addCase(createFeedback.fulfilled, (state, { payload }) => {
      // state.inquiry = payload;
      state.status = "createFeedbackResolved";
      state.error = "";
    });

    builder.addCase(
      createFeedback.rejected,
      (state, { payload }: { payload: any }) => {
        state.status = "createFeedbackRejected";
        state.error = Util.parseErrorMessage(payload);
      }
    );
  },
});

export const { clearQuoteError, resetQuoteState, clearQuotationStatus } =
  quotationSlice.actions;

export default quotationSlice.reducer;
