import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { UserType } from "../../../../enums/usertype.enum";
import { store } from "../../../../store";
// import { useAppSelector } from "../../../../hooks";
// import { SearchPopup, NavigatorPopup } from "../../../../view";
import { MenuItems } from "../../../../view";
import * as routes from "../../../../constants/routes";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import * as SetupService from "../../../../store/setups/setups.actions";
import { CategoryResponse } from "../../../../types";

interface MenuNavigatorProps {
  buysing_logo: string;
  fonts_color: string;
}

export const MenuNavigator = (props: MenuNavigatorProps) => {
  const [redirectTo, setRedirecTo] = useState(routes.HOME);

  useEffect(() => {
    if (store.getState().auth.isAuthenticated) {
      if (store.getState().auth.user.userType === UserType.CUSTOMER) {
        setRedirecTo(routes.COMP_DASHBOARD);
      } else if (store.getState().auth.user.userType === UserType.SUPPLIER) {
        setRedirecTo(routes.SUPP_DASHBOARD);
      } else {
        setRedirecTo(routes.ADMIN_DASHBOARD);
      }
    }
  }, []);

  return (
    <>
      <div className="navigator">
        <div className="navSegments">
          <div className="logoCo">
            <Link to={redirectTo}>
              <img src={props.buysing_logo} alt="" />
            </Link>
          </div>
          {store.getState().auth.user.userType === UserType.CUSTOMER ? (
            <CustomerNavigator
              buysing_logo={props.buysing_logo}
              fonts_color={props.fonts_color}
            />
          ) : store.getState().auth.user.userType === UserType.SUPPLIER ? (
            <SupplierNavigator
              buysing_logo={props.buysing_logo}
              fonts_color={props.fonts_color}
            />
          ) : (
            <></>
          )}
        </div>
        <MenuItems />
      </div>
    </>
  );
};

export const SiteNavigator = () => {
  const dispatch = useAppDispatch();

  const { status: setupStatus, setups } = useAppSelector(
    (state) => state.setup
  );

  const [productCategories, setProductCategories] = useState<
    CategoryResponse[]
  >([]);

  useEffect(() => {
    dispatch(SetupService.fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (setupStatus === "fetchCategoriesResolved") {
      setProductCategories(setups.categories[0].categories!);

      // const productCategories = ;
      // const serviceCategories = setups.categories[1].categories!;
    }
  }, [setupStatus]);

  return (
    <>
      <div className="companyHead">
        <div className="brand">
          <img
            src="./libs/style/images/BuySing_Color_Logo.png"
            alt="Buysing Exclusive Outsourcing"
          />
          <div className="searchCtrl">
            <input
              type="text"
              placeholder="Search Buysing"
              className="formControl"
            />
            <button type="button" className="btn-emrald setPosition">
              <i className="mdi mdi-18px mdi-magnify"></i>
            </button>
          </div>
          <MenuItems />
        </div>
      </div>
      <section className="site-header">
        <nav className="main-nav nav-d">
          <ul className="flex-grid container flex-justify-center flex-wrap-wrap ul-reset">
            <li className="flex-col">
              <a href="#">NEW!</a>
              <section className="mega-menu">
                <div className="container"></div>
              </section>
            </li>
            {productCategories
              .filter((cat) => cat.parentId === 0)
              .map((cat, index) => (
                <li
                  className="flex-col droppable nav-chevron-down"
                  key={index.toString()}
                >
                  <a href="#">{cat.categoryName}</a>
                  <section className="mega-menu">
                    <div className="container">
                      <ul className="flex-grid ul-reset m-padding-bottom-d m-padding-top-d">
                        {cat.children
                          .filter((subcat) => subcat.parentId === cat.id)
                          .map((subcat, index) => (
                            <li
                              className="flex-col d-col-2 t-col-3 mega-menu-item"
                              key={index.toString()}
                            >
                              <Link to={`/view/category/${cat.id}`}>
                                {subcat.categoryName}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </section>
                </li>
              ))}
          </ul>
        </nav>
      </section>
    </>
  );
};

export const CustomerNavigator = (props: MenuNavigatorProps) => {
  return (
    <>
      <ul className="quickAccess">
        <li>
          <Link to="#" style={{ color: props.fonts_color }}>
            FIND A SUPPLIER
          </Link>
          <div className="dropbox">
            <Link to={routes.SUPPLIER_LIST_ALL}>
              <span className="icoBg mdi mdi-account-group"></span> &emsp; ALL
              SUPPLIERS
            </Link>
            <Link to="#">
              <span className="icoBg mdi mdi-format-list-checks"></span> &emsp;
              SUPPLIER CATEGORIES
            </Link>
          </div>
        </li>
        <li>
          <Link to="#" style={{ color: props.fonts_color }}>
            FIND A PRODUCT
          </Link>
          <div className="dropbox">
            <Link to="#">
              <span className="icoBg mdi mdi-shape"></span> &emsp; ALL PRODUCTS
            </Link>
            <Link to="#">
              <span className="icoBg mdi mdi-format-list-bulleted-type"></span>{" "}
              &emsp; PRODUCT CATEGORIES
            </Link>
          </div>
        </li>
      </ul>
    </>
  );
};

export const SupplierNavigator = (props: MenuNavigatorProps) => {
  return (
    <>
      <ul className="quickAccess">
        <li>
          <Link to="#" style={{ color: props.fonts_color }}>
            FIND A CUSTOMER
          </Link>
          <div className="dropbox">
            <Link to={routes.SUPPLIER_LIST_ALL}>
              <span className="icoBg mdi mdi-account-group"></span> &emsp; ALL
              CUSTOMERS
            </Link>
            <Link to="#">
              <span className="icoBg mdi mdi-format-list-checks"></span> &emsp;
              CUSTOMER CATEGORIES
            </Link>
          </div>
        </li>
        <li>
          <Link to="#" style={{ color: props.fonts_color }}>
            FIND A INQUIRY
          </Link>
          <div className="dropbox">
            <Link to="#">
              <span className="icoBg mdi mdi-shape"></span> &emsp; ALL INQUIRES
            </Link>
            <Link to="#">
              <span className="icoBg mdi mdi-format-list-bulleted-type"></span>{" "}
              &emsp; INQUIRY CATEGORIES
            </Link>
          </div>
        </li>
      </ul>
    </>
  );
};
